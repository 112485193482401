import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useSelector } from "react-redux";

const MyAdvisorChat = () => {
  const [messageContent, setMessageContent] = useState("");
  const worldId = useSelector((state) => state.allow.worldId)
  console.log('worldId from myAdvisorChat ==========------------------------------------------------------->>>> ',worldId)
  const [file, setFile] = useState(null); // To handle file uploads
  const [messages, setMessages] = useState([]);

  // Fetch messages on component mount



   useEffect(() => {
     const fetchMessages = async () => {
       try {
        const response = await axios.get(
          "https://sell-skill.com/api/endpoints/AGet",
          { params: { worldId } }
         );
         setMessages(response.data);
         console.log('response.data from AGets =======================-------------------------------->> > > >  ',response.data)
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
     };

     fetchMessages();

  }, []);


  
  const handleSendMessage = async () => {
    const messageId = uuidv4();

    try {
      let fileUrl = null;

      // Upload file to Firebase if a file is selected
      if (file) {
        const storage = getStorage();
        const fileRef = ref(storage, `chat_files/${file.name}_${uuidv4()}`);
        await uploadBytes(fileRef, file);
        fileUrl = await getDownloadURL(fileRef);
      }

      // Send the message to the backend
      const response = await axios.post(
        "https://sell-skill.com/api/endpoints/ASendSA",
        {
          _id: messageId,
          worldId,
          content: messageContent,
          document: fileUrl, // Send the file URL if uploaded
        }
      );

      if (response.data.success) {
        // Optimistically update the UI
        setMessages([
          ...messages,
          {
            _id: messageId,
            content: messageContent,
            response: true,
            document: fileUrl,
          },
        ]);
        setMessageContent("");
        setFile(null);
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const renderFilePreview = (fileUrl) => {
    const fileExtension = fileUrl.split(".").pop().toLowerCase();
    if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
      return <img src={fileUrl} alt="attachment" style={{ maxWidth: "100%" }} />;
    } else if (["mp4", "webm", "ogg"].includes(fileExtension)) {
      return <video src={fileUrl} controls style={{ maxWidth: "100%" }} />;
    } else if (["mp3", "wav", "ogg"].includes(fileExtension)) {
      return <audio src={fileUrl} controls />;
    } else if (["pdf"].includes(fileExtension)) {
      return (
        <iframe
          src={fileUrl}
          title="PDF Preview"
          style={{ width: "100%", height: "500px" }}
        />
      );
    } else {
      return (
        <a href={fileUrl} target="_blank" rel="noopener noreferrer">
          View Attachment
        </a>
      );
    }
  };

  return (
    <div>
      <h2>Chat with Advisor</h2>
      {messages.length > 0 ? (
        messages.map((message) => (
          <div key={message._id} style={{ margin: "10px 0" }}>
            <div style={{ color: message.response ? "blue" : "black" }}>
              {message.content}
            </div>
            {message.document && renderFilePreview(message.document)}
          </div>
        ))
      ) : (
        <p>No messages received.</p>
      )}

      <textarea
        placeholder="Type your message here..."
        value={messageContent}
        onChange={(e) => setMessageContent(e.target.value)}
        style={{ width: "100%", marginBottom: "10px" }}
      />
      <input
        type="file"
        onChange={(e) => setFile(e.target.files[0])}
        style={{ marginBottom: "10px" }}
      />
      <button onClick={handleSendMessage}>Send Message</button>
    </div>
  );
};

export default MyAdvisorChat;
