import axios from 'axios';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

function SendNotification() {
  const [skillerId, setSkillerId] = useState(''); // Add skillerId input
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const _id = uuidv4();
  const handleSendNotification = async () => {
    if (!skillerId || !title || !content) {
      alert('All fields are required');
      return;
    }

    try {
      const response = await axios.post('https://sell-skill.com/api/endpoints/sendNotification', {
        _id,
        title,
        content,
      });

      alert('Notification sent successfully!');
      console.log(response.data);
    } catch (error) {
      console.error(error);
      alert('Failed to send notification');
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Skiller ID"
        value={skillerId}
        onChange={(e) => setSkillerId(e.target.value)}
      />
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <input
        type="text"
        placeholder="Content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <button onClick={handleSendNotification}>Send Notification</button>
    </div>
  );
}

export default SendNotification;
