//To show inside lessons thumbnails and level description



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLessonId, setLessonNumber, setLevelId, setLevelNumber, setWorldName } from '../../reducers/reducers';
import { useNavigate } from 'react-router-dom';

function MyLessons() { // Pass skillerId as a prop
  const [lessons, setLessons] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //check if the world name is selected
  const worldName = useSelector((state) => state.allow.worldName)
  const levelNumber = useSelector((state) => state.allow.levelNumber)
  const worldId = useSelector((state) => state.allow.worldId);
  const levelId = useSelector((state) => state.allow.levelId)

  useEffect(() => {
    const fetchLevels = async () => {
      try {
        //to get lessons from the selected level
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getLessons`, {
          params: {worldId, levelId}
        });
        setLessons(response.data);
      } catch (error) {
        console.error("Error fetching worlds:", error);
      }
    };


      fetchLevels();
    
  },[]);
  const handleShowLesson=  (id) => {
    dispatch(setLessonId(id))
    navigate ('/lesson-resources')
  }
  const handleEditLesson = (id) => {
    dispatch(setLessonId(id))
    // use useSelector to get this name in the edit world route => send the name to back, then send info to front
    navigate('/edit-lesson')
    
  }

  const handleAddLesson = () => {
    dispatch(setLevelId(levelId))
    navigate('/add-lesson')
  }

  return (
    <div>
      <h1>My Lessons</h1>
      <button onClick={handleAddLesson}>add lesson</button>
      <div className="worlds-container">
        {lessons.map((lesson, index) => (
          <div key={index} className="world-card">
            <h2>{lesson.name}</h2>
            <h2>{lesson.title}</h2>
            <p>{lesson.description}</p>

            <button onClick={() => handleShowLesson(lesson._id)}>show resources inside this lesson</button>
            <button onClick={() => handleEditLesson(lesson.name)}>edit lesson</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyLessons;
