import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Container, Form } from "react-bootstrap";
import axios from "axios";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`;

const MainCard = styled(Card)`
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: bold;
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin: 10px 0;
  background-color: #27ae60;
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;

  &:hover {
    background-color: #1e8449;
    color: white;
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  display: block;
  color: #2c3e50;
  cursor: pointer;
  margin-top: 10px;
`;

const ImagePreview = styled.img`
  display: block;
  margin: 20px auto;
  width: 140px;
  height: 140px;
  border-radius: 10px;
  object-fit: cover;
  border: 2px solid #27ae60;
`;

function CoverPhoto() {
  const [coverPhoto, setCoverPhoto] = useState(null);
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate("/letter"); // Adjust the route based on your flow
  };

  const handleUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("coverPhoto", file);

      await axios.post("https://sell-skill.com/api/endpoints/uploadCoverPhoto", formData);
      setCoverPhoto(file);
    } catch (error) {
      console.error("Error uploading cover photo:", error);
    }
  };

  return (
    <StyledContainer>
      <MainCard>
        <Title>Upload a Cover Photo</Title>
        <Form>
          <StyledButton as="label" htmlFor="cover-upload">
            Choose a Cover Photo
            <Input
              type="file"
              id="cover-upload"
              accept="image/*"
              onChange={(e) => handleUpload(e.target.files[0])}
            />
          </StyledButton>
          {coverPhoto && <ImagePreview src={URL.createObjectURL(coverPhoto)} alt="Preview" />}
          <StyledButton onClick={handleNextClick}>Next</StyledButton>
        </Form>
      </MainCard>
    </StyledContainer>
  );
}

export default CoverPhoto;






































// import axios from "axios";
// import React, { useState } from "react";
// import styled from "styled-components";

// const Container = styled.div`
//   background-color: #f8f9fa;
//   padding: 20px;
//   border-radius: 8px;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//   max-width: 600px;
//   margin: 20px auto;
// `;

// const Title = styled.h2`
//   color: #2c3e50;
//   text-align: center;
//   margin-bottom: 20px;
// `;

// const UploadArea = styled.div`
//   background-color: #dcdde1;
//   border: 2px dashed #2c3e50;
//   border-radius: 8px;
//   padding: 20px;
//   text-align: center;
//   cursor: pointer;
// `;

// const Button = styled.button`
//   background-color: #27ae60;
//   color: #fff;
//   border: none;
//   padding: 10px 20px;
//   border-radius: 5px;
//   cursor: pointer;
//   font-size: 16px;
//   margin-top: 20px;
//   display: block;
//   margin-left: auto;
//   margin-right: auto;

//   &:hover {
//     background-color: #219150;
//   }
// `;

// const ImagePreview = styled.img`
//   max-width: 100%;
//   border-radius: 8px;
//   margin-top: 20px;
// `;

// const CoverPhoto = () => {
//   const [image, setImage] = useState(null);

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onload = () => {
//         setImage(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleUpload = async() => {
//     // Handle upload logic here
//     await axios.post('https://sell-skill.com/api/endpoints/uploadCoverPhoto')
//     console.log("Upload initiated");
//   };

//   return (
//     <Container>
//       <Title>Upload Cover Photo</Title>
//       <label>
//         <UploadArea>
//           {image ? "Change Cover Photo" : "Click to Upload Cover Photo"}
//         </UploadArea>
//         <input
//           type="file"
//           style={{ display: "none" }}
//           accept="image/*"
//           onChange={handleImageChange}
//         />
//       </label>
//       {image && <ImagePreview src={image} alt="Cover Preview" />}
//       {image && <Button onClick={handleUpload}>Upload</Button>}
//     </Container>
//   );
// };

// export default CoverPhoto;
