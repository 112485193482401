import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setCertificationId } from '../reducers/reducers'
import { useNavigate } from 'react-router-dom'

function Certifications1() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [certificationId, setCertificationIdd] = useState('')
  const handleViewCertification = () => {
    dispatch(setCertificationId(certificationId))
    navigate('/certification')
  }
  return (
    <div>
      <input 
      placeholder='enter certification id'
      onChange={(e) => setCertificationIdd (e.target.value)}
      />
      <button onClick={handleViewCertification}>view certification</button>
    </div>
  )
}

export default Certifications1
