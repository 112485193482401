import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function ChooseURAdvisor() {
  const worldId = useSelector((state) => state.allow.worldId);
  const navigate = useNavigate();
  const worldName = useSelector((state) => state.allow.worldName);
  const [advisors, setAdvisors] = useState([]);

  const handleChooseAdvisor = async (advisorId) => {
    try {
      await axios.patch('https://sell-skill.com/api/endpoints/chooseAdvisor', {worldId, advisorId });
      alert('Advisor chosen successfully!');
      console.log('advisorId ===================================------------------------> > > > > >  ',advisorId)
    } catch (error) {
      console.error('Error choosing advisor:', error);
    }
  };

  const handleShowAdvisorProfile = (advisorId) => {
    navigate(`/profile/${advisorId}`); // Assuming dynamic routing
  };

  useEffect(() => {
    const fetchAdvisors = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getAdvisors', {
          params: { worldId },
        });
        setAdvisors(response.data || []);
      } catch (error) {
        console.error('Error fetching advisors:', error);
      }
    };

    fetchAdvisors();
  }, [worldId]);

  return (
    <div>
      {advisors.length > 0 ? (
        <>
          <h1>Available Advisors</h1>
          <div className="advisors-container">
            {advisors.map((advisor, index) => (
              <div key={index} className="advisor-card">
                <h2>{advisor.advisorName}</h2>
                <p>Level: {advisor.advisorLevel}</p>
                <p>{advisor.description}</p>
                <button onClick={() => handleChooseAdvisor(advisor.advisorId)}>Choose</button>
                <img
                  src={advisor.profile || '/default-profile.png'} // Use default image if none
                  alt={`Profile of ${advisor.advisorName}`}
                  onClick={() => handleShowAdvisorProfile(advisor.advisorId)}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="no-advisors">
          <h2>No advisors available</h2>
          <p>Please check back later or contact the course publisher.</p>
        </div>
      )}
    </div>
  );
}

export default ChooseURAdvisor;
