import React, { useEffect, useState } from 'react';
import axios from 'axios';

function GExams() {
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Replace with your backend API endpoint
    const fetchExams = async () => {
      try {

        const response = await axios.get('https://sell-skill.com/api/endpoints/Gexams');
        setExams(response.data);
      } catch (err) {
        setError(err.response ? err.response.data.message : err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchExams();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h1>Exams</h1>
      {exams.length === 0 ? (
        <p>No exams available.</p>
      ) : (
        <div>
          {exams.map((exam) => (
            <div key={exam._id} style={{ border: '1px solid #ccc', margin: '10px', padding: '10px' }}>
              <h2>{exam.examTitle}</h2>
              <p><strong>World Name:</strong> {exam.worldName}</p>
              <p><strong>Level:</strong> {exam.levelNumber}</p>
              <p><strong>Instructor:</strong> {exam.instructorId}</p>
              <p><strong>Exam Time:</strong> {new Date(exam.examTime).toLocaleString()}</p>
              <p><strong>Grade:</strong> {exam.grade}</p>
              <p><strong>Status:</strong> {exam.isPassed ? 'Passed' : 'Not Passed'}</p>
              <a href={exam.examLink} target="_blank" rel="noopener noreferrer">Exam Link</a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default GExams;
