import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { setExamId } from '../reducers/reducers';
import { useDispatch } from 'react-redux';

function GExams4Students() {
  const [exams, setExams] = useState([]);
  const [worldId, setWorldId] = useState('')
  const [levelId, setLevelId] = useState('')
  const [selectedExam, setSelectedExam] = useState(null);
  const [rate, setRate] = useState('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchExams = async () => {
      try {
        const { data } = await axios.get('https://sell-skill.com/api/endpoints/getExams4Student');
        console.log('exams from frontend 4 student ===========--------------------->>>>>>> ',data)
        setExams(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchExams();
  }, []);

const handleRateAdvisor = async(studentId) => {
  await axios.post('https://sell-skill.com/api/endpoints/rateAdvisor', {studentId, worldId, levelId,  rate})
} 

const handleViewCertification = async (examId) => {
  dispatch(setExamId(examId))
  navigate ('/certification')
}

const handleYes = async(examId) => {
//available
await axios.patch('https://sell-skill.com/api/endpoints/setAdvisorAsAvailable')
}
const handleNo = async(examId) => {
//nothing
}
  return (
    <div>
      <h1>Exams for Advisor</h1>
      {exams.map((exam) => (
        <div key={exam._id} style={{ border: '1px solid black', padding: '10px', margin: '10px' }}>
          <h3>{exam.title}</h3>
          <p>instructor name: {exam.instructorName}</p>
          <p>World: {exam.worldName}</p>
          <p>Level: {exam.levelNumber}</p>
          <p>Time: {exam.examTime || 'Not Set'}</p>
          <p>Link: {exam.examLink || 'Not Set'}</p>


















      
  
          <>
          <input
          disabled = {!exam.isPassed}   

          placeholder='rate advisor...'
          onChange={(e) => setRate (e.target.value)}
          />
          <button onClick={() => handleRateAdvisor(exam.studentId)}>send rate</button>
          
          </>
       
          {
             exam.isPassed && exam.isWorldAllowingAdvisors && 
             <> <p>want to be an advisor? </p>
             <button onClick={handleYes(exam._id)}>yes</button>
             <button onClick={handleNo(exam._id)}>no</button> </>
            
          }


















          {
            exam.grade && <p>My Grade: {exam.grade}</p>
          }
          {
            exam.isEnded && <p>{exam.isPassed}</p>
          }
          {
            exam.comment && <p>{exam.comment}</p>
          }
          {
            exam.isPassed && exam.grade && <button onClick={() => handleViewCertification(exam._id)}>view certification</button>
          }
  
        </div>
      ))}
      <input 
      placeholder='enter worldId'
      onChange = {(e) => setWorldId (e.target.value)}
      />
      <input 
      placeholder='enter levelId'
      onChange={(e) => setLevelId (e.target.value)}
      />
    </div>
  );
}

export default GExams4Students;
