import axios from 'axios'
import { set } from 'mongoose';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
function StartExam() {
   // advisor id, level id, world id, examId as uuid
   const [advisorId, setAdvisorId] = useState('');

  //  const worldId = useSelector((state) => state.allow.worldId)
  const [worldId, setWorldId ]= useState('')
  //  const levelId = useSelector((state) => state.allow.levelId)
  const [levelId, setLevelId] = useState('')
   const examId = uuidv4();
   

  //  useEffect(() => {
  //   const getAdvisorId = async () => {
  //    const response =  await axios.get('https://sell-skill.com/api/endpoints/getAdvisorId', {
  //       params: {worldId}
  //     })
  //     setAdvisorId(response.data)
  //   }
  //   getAdvisorId();
  //  }, []);

    const handleStartExam = async() => {
        await axios.post('https://sell-skill.com/api/endpoints/startExam', {
            advisorId, //set and get advisor id to backend
            worldId,//useSelector
            levelId, //useSelector
            examId //uuidv4
        })
    }
  return (  
    <div>
      <input 
      onChange={(e) => setWorldId(e.target.value)}
      placeholder='world id'
      />  
      <input 
      placeholder='level id'
      onChange={(e) => setLevelId(e.target.value)}
      />
      <input 
      placeholder='advisor id'
      onChange={(e) => setAdvisorId(e.target.value)}
      />

<button onClick={handleStartExam}>start exam</button>
    </div>
  )
}

export default StartExam
