import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

const WorldsDisplay = () => {
  const [worlds, setWorlds] = useState([]);

  useEffect(() => {
    // Fetch all worlds from the backend
    const fetchWorlds = async () => {
      try {
        const response = await axios.get("https://sell-skill.com/api/endpoints/getAllWorlds"); // Adjust the API endpoint
        setWorlds(response.data);
      } catch (error) {
        console.error("Error fetching worlds:", error);
      }
    };

    fetchWorlds();
  }, []);

  return (
    <Container className="mt-4">
      <h2 className="text-center mb-4">Explore Worlds</h2>
      <Row>
        {worlds.map((world) => (
          <Col md={4} sm={6} xs={12} className="mb-4" key={world._id}>
            <Card className="shadow-sm">
              {world.worldThumbnail ? (
                <Card.Img
                  variant="top"
                  src={world.worldThumbnail}
                  alt={world.worldName}
                />
              ) : (
                <div className="bg-light d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                  <span>No Thumbnail</span>
                </div>
              )}
              <Card.Body>
                <Card.Title>{world.worldName}</Card.Title>
                <Card.Text>
                  {world.worldDescription?.substring(0, 100)}...
                </Card.Text>
                <Card.Text>
                  <strong>Price:</strong> ${world.price}
                </Card.Text>
                <Card.Text>
                  <strong>Students:</strong> {world.numberOfStudents} | <strong>Advisors:</strong> {world.numberOfAdvisors}
                </Card.Text>
                <Button
                  variant="success"
                  className="w-100"
                  disabled={!world.allowedCard}
                >
                  {world.allowedCard ? "Add to Cart" : "Already Added"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default WorldsDisplay;

