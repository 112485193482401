import React, { useState } from 'react';
import axios from 'axios';

function MyEarnings() {
  const [recipientEmail, setRecipientEmail] = useState('');
  const [message, setMessage] = useState('');

  const sendPayment = async () => {
    try {
      const response = await axios.post(
        'https://sell-skill.com/api/endpoints/send-payment',
        {
          recipientEmail,
          amount: '10.00', // Fixed $10 payment
        }
      );

      if (response.data.success) {
        setMessage('Payment sent successfully!');
      } else {
        setMessage('Payment failed. Please try again.');
      }
    } catch (error) {
      console.error('Error sending payment:', error);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div>
      <h1>Send Payment</h1>
      <input
        type="email"
        placeholder="Recipient Email"
        value={recipientEmail}
        onChange={(e) => setRecipientEmail(e.target.value)}
      />
      <button onClick={sendPayment}>Send $10</button>
      {message && <p>{message}</p>}
    </div>
  );
}

export default MyEarnings;
