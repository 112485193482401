import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  FaEdit,
  FaTrash,
  FaSearch,
  FaChevronLeft,
  FaChevronRight,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "react-bootstrap";

const ElementSelector = ({ onSelect }) => {
  const elements = [
    { type: "video", label: "Video" },
    { type: "photo", label: "Photo" },
    { type: "text", label: "Text" },
    { type: "pdf", label: "PDF" },
    { type: "quiz", label: "Quiz" },
  ];

  return (
    <SidebarContainer>
      <h3>Elements</h3>
      {elements.map((element) => (
        <Element
          key={element.type}
          onClick={() => onSelect(element.type)}
          onMouseOver={(e) => {
            e.target.style.color = "#2980b9";
            e.target.style.transform = "scale(1.1)";
          }}
          onMouseOut={(e) => {
            e.target.style.color = "#2c3e50";
            e.target.style.transform = "scale(1)";
          }}
        >
          {element.label} <span style={{ marginLeft: "0.5rem" }}>+</span>
        </Element>
      ))}
    </SidebarContainer>
  );
};

const ManageLesson = () => {
  const [originalResources, setOriginalResources] = useState([]);
  const [newResources, setNewResources] = useState([]);
  const [lessonTitle, setLessonTitle] = useState("");
  const [lessonDescription, setLessonDescription] = useState("");
  const worldId = useSelector((state) => state.allow.worldId);
  const levelId = useSelector((state) => state.allow.levelId);
  const lessonId = useSelector((state) => state.allow.lessonId);
  const navigate = useNavigate();

  const addResource = (type) => {
    setNewResources((prev) => [
      ...prev,
      {
        id: uuidv4(),
        type,
        content: "",
        choices: [],
        correctAnswer: "",
        order: prev.length + 1,
      },
    ]);
  };

  const removeResource = (index, isOriginal) => {
    if (isOriginal) {
      setOriginalResources((prev) => prev.filter((_, i) => i !== index));
    } else {
      setNewResources((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleFileUpload = async (index, file, isOriginal) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `lesson_files/${file.name}_${uuidv4()}`);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);

      if (isOriginal) {
        setOriginalResources((prev) => {
          const updated = [...prev];
          updated[index].content = fileUrl;
          return updated;
        });
      } else {
        setNewResources((prev) => {
          const updated = [...prev];
          updated[index].content = fileUrl;
          return updated;
        });
      }
    } catch (error) {
      console.error("File upload failed:", error);
    }
  };

  const saveLesson = async () => {
    try {
      const allResources = [...originalResources, ...newResources];
  
      const payload = {
        _id: lessonId,
        lessonTitle,
        lessonDescription,
        resources: allResources.map((resource) => ({
          type: resource.type,
          content: resource.content,
          ...(resource.type === "quiz" && {
            choices: resource.choices,
            correctAnswer: resource.correctAnswer,
          }),
        })),
        worldId,
        levelId,
      };
  
      await axios.patch("https://sell-skill.com/api/endpoints/editLesson", payload);
  
      console.log("Lesson saved successfully!");
    } catch (error) {
      console.error("Failed to save lesson:", error);
    }
  };
  
  useEffect(() => {
    const fetchLessonResources = async () => {
      try {
        const response = await axios.get(
          "https://sell-skill.com/api/endpoints/getLesson",
          { params: { worldId, levelId, lessonId } }
        );

        const lessonData = response.data;
        setOriginalResources([
          ...(lessonData.photos || []).map((photo) => ({
            type: "photo",
            content: photo.contentUrl,
          })),
          ...(lessonData.videos || []).map((video) => ({
            type: "video",
            content: video.contentUrl,
          })),
          ...(lessonData.texts || []).map((text) => ({
            type: "text",
            content: text.text,
          })),
          ...(lessonData.pdfs || []).map((pdf) => ({
            type: "pdf",
            content: pdf.contentUrl,
          })),
          ...(lessonData.quizs || []).map((quiz) => ({
            type: "quiz",
            question: quiz.question,
            choices: quiz.choices,
            correctAnswer: quiz.correctAnswer,
          })),
        ]);
        setLessonTitle(lessonData.lessonTitle || "");
        setLessonDescription(lessonData.lessonDescription || "");
      } catch (error) {
        console.error("Failed to fetch lesson resources:", error);
      }
    };

    fetchLessonResources();
  }, [worldId, levelId, lessonId]);

  const renderResource = (resource, index, isOriginal) => (
    <Resource key={index}>
      <div>
        <strong>{resource.type}</strong>
        {/* Display previews for photo, video, or PDF */}
        {resource.type === "photo" && resource.content && (
          <>
            <img
              src={resource.content}
              alt={`Uploaded ${resource.type}`}
              style={{ maxWidth: "100%", maxHeight: "200px", margin: "10px 0" }}
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileUpload(index, e.target.files[0], isOriginal)}
            />
          </>
        )}
        {resource.type === "video" && resource.content && (
          <>
            <video
              src={resource.content}
              controls
              style={{ maxWidth: "100%", maxHeight: "200px", margin: "10px 0" }}
            />
            <input
              type="file"
              accept="video/*"
              onChange={(e) => handleFileUpload(index, e.target.files[0], isOriginal)}
            />
          </>
        )}
        {resource.type === "pdf" && resource.content && (
          <>
            <embed
              src={resource.content}
              type="application/pdf"
              style={{ width: "100%", height: "200px", margin: "10px 0" }}
            />
            <input
              type="file"
              accept="application/pdf"
              onChange={(e) => handleFileUpload(index, e.target.files[0], isOriginal)}
            />
          </>
        )}
        {resource.type === "text" && (
          <TextArea
            value={resource.content}
            onChange={(e) => {
              if (isOriginal) {
                setOriginalResources((prev) => {
                  const updated = [...prev];
                  updated[index].content = e.target.value;
                  return updated;
                });
              } else {
                setNewResources((prev) => {
                  const updated = [...prev];
                  updated[index].content = e.target.value;
                  return updated;
                });
              }
            }}
          />
        )}
        {/* Allow file input for all other types */}
        {resource.type !== "text" && !resource.content && (
          <input
            type="file"
            onChange={(e) => handleFileUpload(index, e.target.files[0], isOriginal)}
          />
        )}
      </div>
      <Actions>
        <FaEdit title="Edit" />
        <FaTrash title="Remove" onClick={() => removeResource(index, isOriginal)} />
      </Actions>
    </Resource>
  );
  
  return (
    <PageContainer>
      <Header>
        <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>
      </Header>
      <ContentContainer>
        <Sidebar>
          <ElementSelector onSelect={addResource} />
        </Sidebar>
        <MainContent>
  <WorkspaceHeader>
    <h3>Lesson Workspace</h3>
    <SaveButton onClick={saveLesson}>Save Lesson</SaveButton>
  </WorkspaceHeader>
  <InputGroup>
    <Input
      type="text"
      placeholder="Lesson Title"
      value={lessonTitle}
      onChange={(e) => setLessonTitle(e.target.value)}
    />
    <TextArea
      placeholder="Lesson Description"
      value={lessonDescription}
      onChange={(e) => setLessonDescription(e.target.value)}
    />
  </InputGroup>
  <ResourceSection>
    <SectionHeader>Original Resources</SectionHeader>
    {originalResources.length > 0 ? (
      originalResources.map((resource, index) =>
        renderResource(resource, index, true)
      )
    ) : (
      <EmptyMessage>No Original Resources</EmptyMessage>
    )}
  </ResourceSection>
  <ResourceSection>
    <SectionHeader>New Resources</SectionHeader>
    {newResources.length > 0 ? (
      newResources.map((resource, index) =>
        renderResource(resource, index, false)
      )
    ) : (
      <EmptyMessage>No New Resources Added</EmptyMessage>
    )}
  </ResourceSection>
</MainContent>

      </ContentContainer>
    </PageContainer>
  );
};

export default ManageLesson;



const SidebarContainer = styled.div`
  width: 300px;
  background: #ecf0f1;
  padding: 20px;
`;

const Element = styled.div`
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  color: #2c3e50;
  transition: transform 0.2s, color 0.2s;
`;



const Resource = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;




















const WorkspaceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding: 0.5rem 0;
  border-bottom: 2px solid #ecf0f1;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  background-color: #fdfdfd;
  transition: box-shadow 0.3s;

  &:focus {
    outline: none;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.8);
  }
`;

const TextArea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  min-height: 100px;
  background-color: #fdfdfd;
  transition: box-shadow 0.3s;

  &:focus {
    outline: none;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.8);
  }
`;

const ResourceSection = styled.div`
  margin-bottom: 2rem;
`;


const EmptyMessage = styled.div`
  color: #7f8c8d;
  font-size: 0.9rem;
  text-align: center;
  padding: 1rem;
  background: #f9f9f9;
  border: 1px dashed #ddd;
  border-radius: 5px;
`;

const SaveButton = styled.button`
  padding: 10px 20px;
  background: linear-gradient(to right, #3498db, #2980b9);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;



































const PageContainer = styled.div`
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 60px 20px 20px; /* Add top padding to account for the navbar height */
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ContentContainer = styled.div`
    display: flex;
    position: relative; /* Allows absolute positioning of the toggle button */
    width: 100%;
    min-height: calc(100vh - 60px); /* Adjust to ensure it spans the full viewport height minus the navbar */
`;

const Sidebar = styled.div`
    width: 300px;
    position: fixed; /* Fixes it to the left side */
    top: 60px; /* Matches the height of the navbar */
    left: 0;
    bottom: 0; /* Ensures it spans the full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Ensures content is scrollable */
`;


const Navbar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2c3e50;
    color: white;
    position: fixed; /* Keep the navbar fixed at the top */
    top: 0;
    left: 0;
    width: 100%; /* Make it span the entire width */
    z-index: 1000; /* Ensure it stays above other elements */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
`;


const LeftNav = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.div`
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
`;

const NavLinks = styled.div`
    margin-left: 20px;
    display: flex;
    gap: 15px;

    a {
        color: white;
        text-decoration: none;
    }
`;

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
    }
`;

const NavIcons = styled.div`
    display: flex;
    gap: 15px;
`;

const ProfileWrapper = styled.div`
    position: relative;
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;

const SectionHeader = styled.h2`
    font-size: 18px;
    color: #2c3e50;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
        font-size: 20px;
        color: #2980b9;
    }
`;

const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
`;

const WorldsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;

const WorldCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-10px);
    }
`;

const Publisher = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    p {
        font-size: 14px;
        color: #2c3e50;
        margin: 0;
    }
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
`;

const Info = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;

const Rating = styled.div`
    display: flex;
`;
const Actions = styled.div`
    display: flex;
    flex-direction: column; /* Stack ActionRow elements vertically */
    gap: 10px; /* Add spacing between rows */
`;




const MainContent = styled.div`
    margin-left: 300px; /* Ensures content starts after the fixed sidebar */
    flex-grow: 1; /* Ensures MainContent takes remaining space */
    padding: 20px;
`;



const GreenButton = styled.button`
    background-color: #27ae60 !important; /* Green color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #219150 !important; /* Darker green on hover */
    }
`;

const NavColorButton = styled.button`
    background-color: #2c3e50 !important; /* Navbar color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #1a252f !important; /* Darker navbar color on hover */
    }
`;

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between rows */
`;
const ToggleSidebarButton = styled.div`
    position: fixed; /* Ensures it's positioned relative to the viewport */
    top: 100px; /* Adjust to set vertical placement */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement based on sidebar visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    background-color: #ddd;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: left 0.3s ease; /* Smooth transition when toggling */
`;






// import React, { useEffect, useState } from "react";
// import { v4 as uuidv4 } from "uuid";
// import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
// import { useSelector } from "react-redux";
// import styled from "styled-components";
// import {
//   FaEdit,
//   FaTrash,
//   FaSearch,
//   FaChevronLeft,
//   FaChevronRight,
// } from "react-icons/fa";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const ElementSelector = ({ onSelect }) => {
//   const elements = [
//     { type: "video", label: "Video" },
//     { type: "photo", label: "Photo" },
//     { type: "text", label: "Text" },
//     { type: "pdf", label: "PDF" },
//     { type: "quiz", label: "Quiz" },
//   ];

//   return (
//     <div>
//       <h3>Elements</h3>
//       {elements.map((element) => (
//         <div
//           key={element.type}
//           style={{
//             cursor: "pointer",
//             padding: "0.5rem",
//             display: "flex",
//             alignItems: "center",
//           }}
//           onClick={() => onSelect(element.type)}
//         >
//           {element.label} <span style={{ marginLeft: "0.5rem", fontSize: "1.2em" }}>+</span>
//         </div>
//       ))}
//     </div>
//   );
// };

// const ManageLesson = () => {
//   const [originalResources, setOriginalResources] = useState([]);
//   const [newResources, setNewResources] = useState([]);
//   const [lessonTitle, setLessonTitle] = useState("");
//   const [lessonDescription, setLessonDescription] = useState("");
//   const worldId = useSelector((state) => state.allow.worldId);
//   const levelId = useSelector((state) => state.allow.levelId);
//   const lessonId = useSelector((state) => state.allow.lessonId);
//   const navigate = useNavigate();

//   const addResource = (type) => {
//     setNewResources((prev) => [
//       ...prev,
//       {
//         id: uuidv4(),
//         type,
//         content: "",
//         choices: [],
//         correctAnswer: "",
//         order: prev.length + 1,
//       },
//     ]);
//   };

//   const removeResource = (index, isOriginal) => {
//     if (isOriginal) {
//       setOriginalResources((prev) => prev.filter((_, i) => i !== index));
//     } else {
//       setNewResources((prev) => prev.filter((_, i) => i !== index));
//     }
//   };

//   const handleFileUpload = async (index, file, isOriginal) => {
//     try {
//       const storage = getStorage();
//       const fileRef = ref(storage, `lesson_files/${file.name}_${uuidv4()}`);
//       await uploadBytes(fileRef, file);
//       const fileUrl = await getDownloadURL(fileRef);

//       if (isOriginal) {
//         setOriginalResources((prev) => {
//           const updated = [...prev];
//           updated[index].content = fileUrl;
//           return updated;
//         });
//       } else {
//         setNewResources((prev) => {
//           const updated = [...prev];
//           updated[index].content = fileUrl;
//           return updated;
//         });
//       }
//     } catch (error) {
//       console.error("File upload failed:", error);
//     }
//   };

//   const saveLesson = async () => {
//     try {
//       const allResources = [...originalResources, ...newResources];
//       const payload = {
//         _id: uuidv4(),
//         lessonTitle,
//         lessonDescription,
//         resources: allResources,
//         worldId,
//         levelId,
//         lessonId,
//       };

//       await axios.post("https://sell-skill.com/api/endpoints/saveLesson", payload);
//       console.log("Lesson saved successfully!");
//     } catch (error) {
//       console.error("Failed to save lesson:", error);
//     }
//   };

//   useEffect(() => {
//     const fetchLessonResources = async () => {
//       try {
//         const response = await axios.get(
//           'https://sell-skill.com/api/endpoints/getLesson', {params: {worldId, levelId, lessonId}}
//         );

//         const lessonData = response.data;
//         setOriginalResources([
//           ...(lessonData.photos || []).map((photo) => ({ type: "photo", content: photo.contentUrl })),
//           ...(lessonData.videos || []).map((video) => ({ type: "video", content: video.contentUrl })),
//           ...(lessonData.texts || []).map((text) => ({ type: "text", content: text.text })),
//           ...(lessonData.pdfs || []).map((pdf) => ({ type: "pdf", content: pdf.contentUrl })),
//           ...(lessonData.quizs || []).map((quiz) => ({
//             type: "quiz",
//             question: quiz.question,
//             choices: quiz.choices,
//             correctAnswer: quiz.correctAnswer,
//           })),
//         ]);
//         setLessonTitle(lessonData.lessonTitle || "");
//         setLessonDescription(lessonData.lessonDescription || "");
//       } catch (error) {
//         console.error("Failed to fetch lesson resources:", error);
//       }
//     };

//     fetchLessonResources();
//   }, [worldId, levelId, lessonId]);

//   const renderResource = (resource, index, isOriginal) => (
//     <Resource key={index}>
//       <div>
//         <strong>{resource.type}</strong>
//         {resource.type !== "text" && (
//           <input
//             type="file"
//             onChange={(e) => handleFileUpload(index, e.target.files[0], isOriginal)}
//           />
//         )}
//         {resource.type === "text" && (
//           <textarea
//             value={resource.content}
//             onChange={(e) => {
//               if (isOriginal) {
//                 setOriginalResources((prev) => {
//                   const updated = [...prev];
//                   updated[index].content = e.target.value;
//                   return updated;
//                 });
//               } else {
//                 setNewResources((prev) => {
//                   const updated = [...prev];
//                   updated[index].content = e.target.value;
//                   return updated;
//                 });
//               }
//             }}
//           />
//         )}
//       </div>
//       <Actions>
//         <FaEdit title="Edit" />
//         <FaTrash title="Remove" onClick={() => removeResource(index, isOriginal)} />
//       </Actions>
//     </Resource>
//   );

//   return (
//     <PageContainer>
//       <Header>
//         <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>
//       </Header>
//       <ContentContainer>
//         <Sidebar>
//           <ElementSelector onSelect={addResource} />
//         </Sidebar>
//         <MainContent>
//           <h3>Lesson Workspace</h3>
//           <input
//             type="text"
//             placeholder="Lesson Title"
//             value={lessonTitle}
//             onChange={(e) => setLessonTitle(e.target.value)}
//           />
//           <textarea
//             placeholder="Lesson Description"
//             value={lessonDescription}
//             onChange={(e) => setLessonDescription(e.target.value)}
//           />
//           <h4>Original Resources</h4>
//           {originalResources.map((resource, index) =>
//             renderResource(resource, index, true)
//           )}
//           <h4>New Resources</h4>
//           {newResources.map((resource, index) =>
//             renderResource(resource, index, false)
//           )}
//           <SaveButton onClick={saveLesson}>Save Lesson</SaveButton>
//         </MainContent>
//       </ContentContainer>
//     </PageContainer>
//   );
// };

// export default ManageLesson;

// // Styled Components
// const PageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   min-height: 100vh;
//   background: #f4f4f4;
// `;

// const Header = styled.div`
//   background: #2c3e50;
//   color: white;
//   padding: 1rem;
// `;

// const Logo = styled.h1`
//   margin: 0;
// `;

// const ContentContainer = styled.div`
//   display: flex;
//   flex: 1;
// `;

// const Sidebar = styled.div`
//   width: 300px;
//   padding: 20px;
//   background: #ecf0f1;
// `;

// const MainContent = styled.div`
//   flex: 1;
//   padding: 20px;
// `;

// const Resource = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   background: white;
//   padding: 10px;
//   margin: 10px 0;
//   border-radius: 5px;
//   box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
// `;

// const Actions = styled.div`
//   display: flex;
//   gap: 10px;
// `;

// const SaveButton = styled.button`
//   padding: 10px 20px;
//   background: #3498db;
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;

//   &:hover {
//     background: #2980b9;
//   }
// `;
