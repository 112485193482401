import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { setAcheivementId } from '../../reducers/reducers';

const ProfileContainer = styled.div`
  font-family: 'Arial', sans-serif;
  color: #2c3e50;
  max-width: 1200px;
  margin: auto;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
`;

const CoverPhoto = styled.div`
  background-image: url(${props => props.coverImage});
  background-size: cover;
  background-position: center;
  height: 300px;
  position: relative;
  border-radius: 10px;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background: #fff;
  position: relative;
  border-radius: 10px;
`;

const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 5px solid #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -75px;
  left: 20px;
`;

const ProfileDetails = styled.div`
  margin-left: 200px;
  flex: 1;
`;

const Stats = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
  color: #7f8c8d;
`;

const ActionButtons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }

  .add-mate-btn {
    background: #27ae60;
    color: white;
  }

  .message-btn {
    background: #2c3e50;
    color: white;
  }
`;

const Section = styled.div`
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h3`
  border-bottom: 2px solid #27ae60;
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;

const Card = styled.div`
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s;
  overflow: hidden;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
`;

function GProfile() {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getProfile');
        setProfile(response.data);
      } catch (err) {
        setError(err.message);  
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }


  const {
    name,
    surname,
    letter,
    picture,
    coverPicture,
    cash,
    rate,
    followers,
    worlds,
    certifications,
    instructorDescription,
    joinedAt,
    realWorldResults,
    definingVideo,
  } = profile;
console.log('profile from GProfile ========================================> >> > > >  > > >>  > > > ',profile)
  const handleGoToAcheivement = (acheivementId) => {
    dispatch(setAcheivementId(acheivementId))
    navigate('/acheivment')
    
  }
  return (
    <ProfileContainer>
      <CoverPhoto coverImage={profile.coverPicture} />

      <ProfileHeader>
        <ProfilePicture
          src={profile.picture}
          alt={`${name}'s profile`}
        />
        <ProfileDetails>
          <h1>{name} {surname}</h1>
          <h2>{letter}</h2>
          <p>Rate: {rate} / 5</p>
          <Stats>
            <p>Mates: {followers?.length || 0}</p>
            <p>Cash: ${cash}</p>
          </Stats>
          <ActionButtons>
            <button className="add-mate-btn">Add Mate</button>
            <button className="message-btn">Message</button>
          </ActionButtons>
        </ProfileDetails>
      </ProfileHeader>

      {definingVideo && (
        <Section>
          <SectionTitle>Defining Video</SectionTitle>
          <video controls style={{ width: '100%', borderRadius: '10px' }}>
            <source src={definingVideo} type="video/mp4" />
          </video>
        </Section>
      )}

      <Section>
        <SectionTitle>Worlds</SectionTitle>
        <Grid>
          {worlds?.map((world) => (
            <Card key={world._id}>
              <Thumbnail src={world.worldThumbnail} alt={world.worldName} />
              <h4>{world.worldName}</h4>
              <p>{world.worldDescription}</p>
            </Card>
          ))}
        </Grid>
      </Section>

      <Section>
        <SectionTitle>Certifications</SectionTitle>
        <Grid>
          {certifications?.map((cert) => (
            <Card key={cert._id}>
              <Thumbnail src={`data:${cert.thumbnail?.contentType};base64,${cert.thumbnail?.data}`} alt={cert.name} />
              {cert.name}
            </Card>
          ))}
        </Grid>
      </Section>
      <Section>
  <SectionTitle>Real-World Achievements</SectionTitle>
  <Grid>
    {realWorldResults?.map((result, index) => (
      <Card key={index}>
        {result.thumbnail && (
          <Thumbnail src={result.thumbnail} alt={result.title || 'Achievement Thumbnail'} />
        )}
        {result.title && <h4>{result.title}</h4>}
        {result.texts && <p>{result.texts.map((text) => text.text).join(' ')}</p>}
        <Button
          onClick={() => handleGoToAcheivement(result._id)}
          style={{ marginTop: '10px', background: '#27ae60', color: 'white' }}
        >
          View Achievement
        </Button>
      </Card>
    ))}
  </Grid>
</Section>


      <div style={{ textAlign: 'center', padding: '20px 0' }}>
        <p>Joined on: {new Date(joinedAt).toLocaleDateString()}</p>
      </div>
    </ProfileContainer>
  );
}

export default GProfile;
