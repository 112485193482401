import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setWorldId } from '../../../reducers/reducers';

function SetAdvisorManually() {

  const navigate = useNavigate();
const dispatch = useDispatch()
  const [advisors, setAdvisors] = useState([]);
  const [worldId, setWorldIdd] = useState('');
  const [levelId, setLevelId] = useState('')
  const [advisorId, setAdvisorIdd] = useState('')
  const handleChooseAdvisor = async () => {
    try {
      await axios.patch('https://sell-skill.com/api/endpoints/chooseAdvisor', {worldId,levelId,  advisorId });
      alert('Advisor chosen successfully!');
      console.log('advisorId ===================================------------------------> > > > > >  ',advisorId)
    } catch (error) {
      console.error('Error choosing advisor:', error);
    }
  };

  const handleShowAdvisorProfile = (advisorId) => {
    navigate(`/profile/${advisorId}`); // Assuming dynamic routing
  };

  useEffect(() => {
    const fetchAdvisors = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getAdvisors', {
          params: { worldId },
        });
        setAdvisors(response.data || []);
      } catch (error) {
        console.error('Error fetching advisors:', error);
      }
    };

    fetchAdvisors();
  }, [worldId]);

  const handleDispatchWorldIdAndNavigateToWorld = () => {
    dispatch(setWorldId(worldId))
    navigate('/set-subscirbed-in-to-true')
  }
  return (
    <div>

        <>
       <input 
       placeholder='enter advisorId'
       onChange={(e) => setAdvisorIdd(e.target.value)}
       />
         <input
            placeholder = 'enter WorldId'
            onChange={(e) => setWorldIdd(e.target.value)}
            />

<input
            placeholder = 'enter levelId'
            onChange={(e) => setLevelId(e.target.value)}
            />

            <button onClick={() => console.log('worldId ==========-----------> ',worldId)}>log worldId</button>
    <button onClick = {handleDispatchWorldIdAndNavigateToWorld}>dispatch worldId and navigate to subscribe</button>
 
        </>
      <button onClick={handleChooseAdvisor}>insert advisor</button>
    </div>
  );
}

export default SetAdvisorManually;
