import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheckCircle, FaTrash, FaBell, FaComments, FaSearch, FaCog } from 'react-icons/fa';
import { setLessonId } from '../../reducers/reducers';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

function AddLesson() {
  let _id = uuidv4();
  const navigate = useNavigate();
  const [lessons, setLessons] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedLesson, setSelectedLesson] = useState(null);
const handleGoToResources = (_id) => {
  console.log('_id from handleGoToResources =================>> > > > > ',_id)
  dispatch(setLessonId(_id))
  navigate('/lesson-resources')
}
const handleSaveLessonChanges = async ( updatedLesson) => {
  try {
    console.log('updated lesson from handleSaveLessonChanges =================-=-=-=--=-=-=-=-=-=-= > > > > > > >  ',updatedLesson)
    await axios.patch('https://sell-skill.com/api/endpoints/editLesson', {worldId, levelId, lessonId: updatedLesson._id, updatedLesson});

    // Update local state
    setLessons((prevLessons) =>
      prevLessons.map((ls) => (ls._id === updatedLesson._id ? updatedLesson : ls))
    );

    setIsModalOpen(false);
    alert('Level updated successfully!');
  } catch (error) {
    console.error('Error updating level:', error);
    alert('Failed to update level.');
  }
};




  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  
  const [completedSteps, setCompletedSteps] = useState({

    lessonTitle: false,
    lessonDescription: false,

  });


  const worldId = useSelector((state) => state.allow.worldId);
  const levelId = useSelector((state) => state.allow.levelId)
  const dispatch = useDispatch();


  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getLessons`, {
          params: { worldId, levelId },
        });
        setLessons(response.data);
        console.log('lessons ===========> > > >  > ', response.data);
      } catch (error) {
        console.error('Error fetching lessons:', error);
      }
    };

    fetchLessons();
  }, [worldId, levelId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const lessonData = {
      _id,
      worldId,
      levelId,
      title,
      description,

    };
    console.log('lessonData from front ============================================> > > > > > > > > >>  ',lessonData)
    try {
      await axios.post('https://sell-skill.com/api/endpoints/uploadCourse',  {
        _id,
        worldId,
        levelId,
        title,

        description,
      }
      );
      // Update Redux store

      dispatch(setLessonId(_id));

      // Add new level to state
      setLessons((prevLessons) => [...prevLessons, lessonData]);

      // Reset form
   
      setTitle('');
      setDescription('');

      setCompletedSteps({
 
        lessonTitle: false,
        lessonDescription: false,
   
      });

      alert('Level added successfully!');
    } catch (error) {
      console.error(error);
      alert('Error adding level.');
    }
  };
  
  const handleViewResources = (_id) => {
    dispatch(setLessonId(_id))
    navigate('/lesson-resources')
  }
  const handleRemoveLesson = async (_id) => {
    try {
      console.log('_id from handleRemoveLesson =========================-=-=-=-=-=-=-=-=-> > >  > > > ',_id)
      setLessons((prevLessons) => prevLessons.filter((lesson) => lesson._id !== _id));
      await axios.delete('https://sell-skill.com/api/endpoints/removeLesson', {params: {_id, worldId , levelId}});
      // Remove level from state

    } catch (error) {
      console.error('Error removing level:', error);
    }
  };

  const LessonEditModal = ({ isOpen, lesson, onClose, onSave }) => {
    const [updatedLesson, setUpdatedLesson] = useState(lesson);
    if (!isOpen) return null;
  
    
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setUpdatedLesson((prev) => ({ ...prev, [name]: value }));
    };
  
    const handleSave = () => {
      onSave(updatedLesson);
    };
  
    return (
      <ModalOverlay>
        <ModalContent>
          <h2>Edit lesson</h2>
          <FormGroup>
            <label>lesson Title:</label>
            <input
              type="text"
              name="title"
              value={updatedLesson.title}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <label>lesson Description:</label>
            <textarea
              name="description"
              value={updatedLesson.description}
              onChange={handleInputChange}
            ></textarea>
          </FormGroup>


          <Button onClick={handleSave}>Save Changes</Button>
          <Button onClick={()=> handleViewResources(updatedLesson._id)}>view resources</Button>
          <Button onClick={onClose} style={{ marginLeft: '10px' }}>
            Cancel
          </Button>
        </ModalContent>
      </ModalOverlay>
    );
  };
  

  return (
    <PageContainer>
      <Header>
        <Navbar>
          <LeftNav>
            <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
            <NavLinks>
              <a href="/my-learning">My Profile</a>
              <a href="/my-cart">My Cart</a>
              <a href="/certifications">Certifications</a>
              <a href="/exams">Exams</a>
            </NavLinks>
          </LeftNav>
          <SearchBar>
            <FaSearch />
            <input
              type="text"
              placeholder="Search Levels"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </SearchBar>
          <NavIcons>
            <FaBell onClick={() => navigate('/notifications')} />
            <FaComments onClick={() => navigate('/chats')} />
          </NavIcons>
        </Navbar>
      </Header>
      <ContentContainer>
      <LessonEditModal
  isOpen={isModalOpen}
  lesson={selectedLesson}
  onClose={() => setIsModalOpen(false)}
  onSave={handleSaveLessonChanges}
/>

        <Sidebar>
          <h3>Steps</h3>
          {[{ name: 'Lesson Title', key: 'title' },
            { name: 'Lesson Description', key: 'description' },
  ].map((step) => (
            <SidebarItem key={step.key}>
              <span>{step.name}</span>
              {completedSteps[step.key] && <CompletedMark />}
            </SidebarItem>
          ))}
        </Sidebar>
        <MainContent>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <label>lesson Title:</label>
              <input
                type="text"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setCompletedSteps((prev) => ({
                    ...prev,
                    lessonTitle: e.target.value.trim() !== '',
                  }));
                }}
                required
              />
            </FormGroup>
            <FormGroup>
              <label>lesson Description:</label>
              <textarea
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setCompletedSteps((prev) => ({
                    ...prev,
                    lessonDescription: e.target.value.trim() !== '',
                  }));
                }}
                required
              ></textarea>
            </FormGroup>

            <SubmitButton type="submit">Add lesson</SubmitButton>
          </form>

          <LevelsList>
            {loading ? (
              <p>Loading lessons...</p>
            ) : lessons.length ? (
              lessons.map((lesson) => (

                <>
                
                <Button onClick={() => handleGoToResources(lesson._id)}>
                  go to resources
                </Button>

                <LevelCard key={lesson._id}>
                  <LevelDetails>
                    <h3>{lesson.title}</h3>
                    <p>{lesson.description}</p>
                  </LevelDetails>
                  <RemoveButton onClick={() => handleRemoveLesson(lesson._id)}>
                    <FaTrash /> Remove
                  </RemoveButton>
                  <FaCog
  onClick={() => {
    setSelectedLesson(lesson);
    setIsModalOpen(true);
  }}
/>

                </LevelCard>
    
                </>


              ))
            ) : (
              <p>No lessons added yet.</p>
            )}
          </LevelsList>
        </MainContent>
      </ContentContainer>
    </PageContainer>
  );
}

export default AddLesson;

// Styled Components


const FormGroup = styled.div`
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
`;

const Button = styled.button`
  background-color: ${(props) => (props.selected ? '#27ae60' : '#7f8c8d')};
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #1e8449;
  }
`;

const LevelsList = styled.div`
  margin-top: 20px;
`;

const LevelCard = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const LevelDetails = styled.div`
  flex: 1;

  h3 {
    margin: 0;
    color: #2c3e50;
  }

  p {
    margin: 5px 0;
    color: #7f8c8d;
  }
`;

const RemoveButton = styled.button`
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #c0392b;
  }
`;



const Step = styled.div
 ` display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;`
;

const StepText = styled.div
`  font-size: 16px;
  color: #2c3e50;`
;





const PageContainer = styled.div`
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 60px 20px 20px; /* Add top padding to account for the navbar height */
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ContentContainer = styled.div`
    display: flex;
    position: relative; /* Allows absolute positioning of the toggle button */
    width: 100%;
    min-height: calc(100vh - 60px); /* Adjust to ensure it spans the full viewport height minus the navbar */
`;

const Sidebar = styled.div`
    width: 300px;
    position: fixed; /* Fixes it to the left side */
    top: 60px; /* Matches the height of the navbar */
    left: 0;
    bottom: 0; /* Ensures it spans the full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Ensures content is scrollable */
`;


const Navbar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2c3e50;
    color: white;
    position: fixed; /* Keep the navbar fixed at the top */
    top: 0;
    left: 0;
    width: 100%; /* Make it span the entire width */
    z-index: 1000; /* Ensure it stays above other elements */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
`;


const LeftNav = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.div`
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
`;

const NavLinks = styled.div`
    margin-left: 20px;
    display: flex;
    gap: 15px;

    a {
        color: white;
        text-decoration: none;
    }
`;

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
    }
`;

const NavIcons = styled.div`
    display: flex;
    gap: 15px;
`;

const ProfileWrapper = styled.div`
    position: relative;
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;

const SectionHeader = styled.h2`
    font-size: 18px;
    color: #2c3e50;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
        font-size: 20px;
        color: #2980b9;
    }
`;

const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
`;

const WorldsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;

const WorldCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-10px);
    }
`;

const Publisher = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    p {
        font-size: 14px;
        color: #2c3e50;
        margin: 0;
    }
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
`;

const Info = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;

const Rating = styled.div`
    display: flex;
`;
const Actions = styled.div`
    display: flex;
    flex-direction: column; /* Stack ActionRow elements vertically */
    gap: 10px; /* Add spacing between rows */
`;




const MainContent = styled.div`
    margin-left: 300px; /* Ensures content starts after the fixed sidebar */
    flex-grow: 1; /* Ensures MainContent takes remaining space */
    padding: 20px;
`;



const GreenButton = styled.button`
    background-color: #27ae60 !important; /* Green color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #219150 !important; /* Darker green on hover */
    }
`;

const NavColorButton = styled.button`
    background-color: #2c3e50 !important; /* Navbar color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #1a252f !important; /* Darker navbar color on hover */
    }
`;

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between rows */
`;




const ToggleSidebarButton = styled.div`
    position: fixed; /* Ensures it's positioned relative to the viewport */
    top: 100px; /* Adjust to set vertical placement */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement based on sidebar visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    background-color: #ddd;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: left 0.3s ease; /* Smooth transition when toggling */
`;










const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }
`;

const PostsContainer = styled.div`
    text-align: center;
    color: #2c3e50;
    margin-top: 20px;
`;


const TabContainer = styled.div`
    position: fixed;
    top: 60px; /* Set below the navbar */
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #f8f9fa; /* Light gray for a clean look */
    border-bottom: 2px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 950; /* Below the navbar */
    padding: 10px 0;
`;









const PostsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;

const PostCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-10px);
    }
`;

const PostInfo = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;


const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 1.1em;
  cursor: default; /* No pointer effect */
  padding: 10px;
  border-radius: 5px;
  background-color: transparent; /* No color change */
  color: inherit; /* Maintain default color */
`;

const CompletedMark = styled(FaCheckCircle)`
  margin-left: 10px;
  font-size: 1.2em;
  color: #27ae60; /* Green for the check mark */
`;


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;
