import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  FaBell,
  FaComments,
  FaSearch,
  FaUserCircle,
  FaChevronLeft,
  FaChevronRight,
  FaArrowRight,
  FaEdit,
} from 'react-icons/fa';
import { Button, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setLevelId } from '../reducers/reducers';

function ManageWorld() {
  const [world, setWorld] = useState(null);
  const worldId = useSelector((state) => state.allow.worldId);
  const [profilePictureSrc, setProfilePictureSrc] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editField, setEditField] = useState({ field: '', value: '' });
  const [isBooleanField, setIsBooleanField] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
};
  useEffect(() => {
    // Fetch world data
    const fetchWorlds = async () => {
      try {
        const response = await axios.get(
          'https://sell-skill.com/api/endpoints/getWorld',
          { params: { worldId } }
        );
        setWorld(response.data);
      } catch (error) {
        console.error('Error fetching worlds:', error);
      }
    };

    // Fetch profile picture
    const fetchProfilePicture = async () => {
      try {
        const response = await axios.get(
          'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
          { responseType: 'blob' }
        );
        const url = URL.createObjectURL(response.data);
        setProfilePictureSrc(url);
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      }
    };

    fetchWorlds();
    fetchProfilePicture();
  }, [worldId]);

  const handleSettingsClick = (field, value, isBoolean = false) => {
    setEditField({ field, value });
    setIsBooleanField(isBoolean);
    setShowModal(true);
  };

  const handleSaveChanges = async() => {
    const { field, value } = editField;
    if(field === 'World Name'){
      const worldName = value
      await axios.patch('https://sell-skill.com/api/endpoints/changeWorldName', {worldId, worldName })
    }
    if(field ==='World Description'){
      const worldDescription = value
      await axios.patch('https://sell-skill.com/api/endpoints/changeWorldDescription', {worldId, worldDescription })
    }
    if(field === 'World Price'){
      const worldPrice = value
      await axios.patch('https://sell-skill.com/api/endpoints/changeWorldPrice', {worldId, worldPrice })
    }

    

    const formData = new FormData();
    formData.append('worldId', worldId);
  
    if (editField.field === 'World Thumbnail') {
      const file = document.querySelector('input[type="file"]').files[0];
      formData.append('thumbnail', file);
    }
  
    try {
      await axios.patch('https://sell-skill.com/api/endpoints/changeWorldThumbnail', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setShowModal(false);
    } catch (error) {
      console.error('Error updating thumbnail:', error);
      alert('Failed to update thumbnail');
    }





    if(field === 'Allow Advisors' ){
      const allowAdvisors = value
      await axios.patch('https://sell-skill.com/api/endpoints/changeAllowAdvisors', {worldId, allowAdvisors })
    }
    setShowModal(false);
  };

  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
  };

  const handleLevelClick = (levelId) => {
    if (!levelId) {
      console.error('Invalid level ID');
      return;
    }
    dispatch(setLevelId(levelId));
    navigate('/manage-level');
  };


  const handleProfileClick = () => {
    setShowDropdown((prev) => !prev);
};

const handleLogout = () => {
  
}
  return (
    <PageContainer>
            <Header>
                <Navbar>
                    <LeftNav>
                        <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
                        <NavLinks>
                            <a href="/my-learning">My profile</a>
                            <a href="/my-earning">My uploads</a>
                            <a>withdraw cash</a>
                            <a href="/my-cart">My Cart</a>
                            <a href="/certifications">Certifications</a>
                            <a href="/exams">Exams</a>
                        </NavLinks>
                    </LeftNav>
                    <SearchBar>
                        <FaSearch />
                        <input
                            type="text"
                            placeholder="Search Worlds"
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </SearchBar>
                    <NavIcons>
                        <FaBell onClick={() => navigate('/my-notifications')} title="Notifications" />
                        <FaComments onClick={() => navigate('/my-chats')} title="Messages" />
                        <div ref={dropdownRef}>
            <ProfileWrapper onClick={handleProfileClick}>
                <ProfilePicture
                    src={profilePictureSrc || '/images/default-profile.jpg'}
                    alt="Profile"
                />
            </ProfileWrapper>
            {showDropdown && (
                <DropdownMenu>
                    <a href="/my-profile">My Profile</a>
                    <a href="/manage-uploads">uploads management</a>
                    <a href="/manage-financial"> financial management</a>
                    <a href = "/skiller-signIn" onClick={()=>handleLogout}>Log Out</a>
                </DropdownMenu>
            )}
        </div>
                    </NavIcons>
                </Navbar>
            </Header>
      
      <ContentContainer>
        {sidebarVisible && (
          <Sidebar>
            {world?.levels?.map((level) => (
              <SectionHeader onClick={() => handleLevelClick(level._id)} key={level._id}>
                <FaArrowRight /> {level.levelTitle}
              </SectionHeader>
            ))}
          </Sidebar>
        )}
        <ToggleSidebarButton onClick={toggleSidebar}>
          {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
        </ToggleSidebarButton>

        <MainContent>
          <WorldCard>
            <h1>Edit World</h1>
            {world && (
              <>
                <FieldWrapper>
                  <h3>{world.worldName}</h3>
                  <EditIcon onClick={() => handleSettingsClick('World Name', world.worldName)}>
                    <FaEdit />
                  </EditIcon>
                </FieldWrapper>
                <FieldWrapper>
                  <p>{world.worldDescription}</p>
                  <EditIcon
                    onClick={() =>
                      handleSettingsClick('World Description', world.worldDescription)
                    }
                  >
                    <FaEdit />
                  </EditIcon>
                </FieldWrapper>
                <FieldWrapper>
                  <p>{world.price} $</p>
                  <EditIcon
                    onClick={() => handleSettingsClick('World Price', world.price)}
                  >
                    <FaEdit />
                  </EditIcon>
                </FieldWrapper>
                <FieldWrapper>
                  <Thumbnail src={world.worldThumbnail} alt="World Thumbnail" />
                  <EditIcon
                    onClick={() => handleSettingsClick('World Thumbnail', world.worldThumbnail)}
                  >
                    <FaEdit />
                  </EditIcon>
                </FieldWrapper>
                <FieldWrapper>
                  <p>
                    Advisors Allowed:{' '}
                    {world.isWorldAllowingAdvisors ? 'Yes' : 'No'}
                  </p>
                  <EditIcon
                    onClick={() =>
                      handleSettingsClick(
                        'Allow Advisors',
                        world.isWorldAllowingAdvisors,
                        true
                      )
                    }
                  >
                    <FaEdit />
                  </EditIcon>
                </FieldWrapper>
              </>
            )}
          </WorldCard>
        </MainContent>
      </ContentContainer>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>Edit {editField.field}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      {editField.field === 'World Thumbnail' ? (
        <Form.Group>
          <Form.Label>Upload New Thumbnail</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file) {
                const fileUrl = URL.createObjectURL(file);
                setEditField((prev) => ({ ...prev, value: fileUrl })); // Preview the image
              }
            }}
          />
          {editField.value && (
            <div style={{ marginTop: '10px' }}>
              <p>Preview:</p>
              <Thumbnail src={editField.value} alt="Thumbnail Preview" />
            </div>
          )}
        </Form.Group>
      ) : !isBooleanField ? (
        <Form.Group>
          <Form.Label>{editField.field}</Form.Label>
          <Form.Control
            type="text"
            value={editField.value}
            onChange={(e) =>
              setEditField((prev) => ({ ...prev, value: e.target.value }))
            }
          />
        </Form.Group>
      ) : (
        <>
          <Form.Label>Allow Advisors?</Form.Label>
          <Form.Check
            type="radio"
            label="Yes"
            name="allowAdvisors"
            checked={editField.value === true}
            onChange={() =>
              setEditField((prev) => ({ ...prev, value: true }))
            }
          />
          <Form.Check
            type="radio"
            label="No"
            name="allowAdvisors"
            checked={editField.value === false}
            onChange={() =>
              setEditField((prev) => ({ ...prev, value: false }))
            }
          />
        </>
      )}
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowModal(false)}>
      Cancel
    </Button>
    <Button variant="primary" onClick={handleSaveChanges}>
      Save Changes
    </Button>
  </Modal.Footer>
</Modal>

    </PageContainer>
  );
}

export default ManageWorld;

// Styled Components


const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditIcon = styled.div`
  cursor: pointer;
`;






















const PageContainer = styled.div`
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 60px 20px 20px; /* Add top padding to account for the navbar height */
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ContentContainer = styled.div`
    display: flex;
    position: relative; /* Allows absolute positioning of the toggle button */
    width: 100%;
    min-height: calc(100vh - 60px); /* Adjust to ensure it spans the full viewport height minus the navbar */
`;

const Sidebar = styled.div`
    width: 300px;
    position: fixed; /* Fixes it to the left side */
    top: 60px; /* Matches the height of the navbar */
    left: 0;
    bottom: 0; /* Ensures it spans the full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Ensures content is scrollable */
`;


const Navbar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2c3e50;
    color: white;
    position: fixed; /* Keep the navbar fixed at the top */
    top: 0;
    left: 0;
    width: 100%; /* Make it span the entire width */
    z-index: 1000; /* Ensure it stays above other elements */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
`;


const LeftNav = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.div`
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
`;

const NavLinks = styled.div`
    margin-left: 20px;
    display: flex;
    gap: 15px;

    a {
        color: white;
        text-decoration: none;
    }
`;

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
    }
`;

const NavIcons = styled.div`
    display: flex;
    gap: 15px;
`;

const ProfileWrapper = styled.div`
    position: relative;
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;

const SectionHeader = styled.h2`
    font-size: 18px;
    color: #2c3e50;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
        font-size: 20px;
        color: #2980b9;
    }
`;

const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
`;

const WorldsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;

const WorldCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-10px);
    }
`;

const Publisher = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    p {
        font-size: 14px;
        color: #2c3e50;
        margin: 0;
    }
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
`;

const Info = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;

const Rating = styled.div`
    display: flex;
`;
const Actions = styled.div`
    display: flex;
    flex-direction: column; /* Stack ActionRow elements vertically */
    gap: 10px; /* Add spacing between rows */
`;




const MainContent = styled.div`
    margin-left: 300px; /* Ensures content starts after the fixed sidebar */
    flex-grow: 1; /* Ensures MainContent takes remaining space */
    padding: 20px;
`;



const GreenButton = styled.button`
    background-color: #27ae60 !important; /* Green color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #219150 !important; /* Darker green on hover */
    }
`;

const NavColorButton = styled.button`
    background-color: #2c3e50 !important; /* Navbar color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #1a252f !important; /* Darker navbar color on hover */
    }
`;

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between rows */
`;
const ToggleSidebarButton = styled.div`
    position: fixed; /* Ensures it's positioned relative to the viewport */
    top: 100px; /* Adjust to set vertical placement */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement based on sidebar visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    background-color: #ddd;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: left 0.3s ease; /* Smooth transition when toggling */
`;




