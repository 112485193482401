import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight, FaCheckCircle, FaChalkboardTeacher, FaUserGraduate, FaStar, FaCog } from 'react-icons/fa';

import { useDispatch } from 'react-redux';
import { setWorldId } from '../reducers/reducers';
import { Button } from 'react-bootstrap';

function AllUploads() {
    const [worlds, setWorlds] = useState([]);
    const dispatch = useDispatch()
    const [filters, setFilters] = useState({
        numberOfStudents: '',
        allowAdvisors: '',
        cashGenerated: '',
        rate: '',
        dateOfPublish: '',
    });
    const [filteredWorlds, setFilteredWorlds] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [expandedSection, setExpandedSection] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchWorlds = async () => {
            try {
                const response = await axios.get('https://sell-skill.com/api/endpoints/getMyWorlds');
                setWorlds(response.data);
                setFilteredWorlds(response.data);
            } catch (error) {
                console.error('Error fetching worlds:', error);
            }
        };
        fetchWorlds();
    }, []);

    useEffect(() => {
        let results = worlds;

        if (filters.numberOfStudents) {
            results = results.filter(world => world.numberOdStudents >= parseInt(filters.numberOfStudents, 10));
        }
        if (filters.allowAdvisors !== '') {
            results = results.filter(world => world.isWorldAllowingAdvisors === (filters.allowAdvisors === 'yes'));
        }
        if (filters.cashGenerated) {
            results = results.filter(world => world.cashGenerated >= parseFloat(filters.cashGenerated));
        }
        if (filters.rate) {
            results = results.filter(world => {
                const totalRate = world.rate.reduce((sum, r) => sum + r.rate, 0);
                const averageRate = totalRate / world.rate.length || 0;
                return averageRate >= parseFloat(filters.rate);
            });
        }
        if (filters.dateOfPublish) {
            results = results.filter(
                world => new Date(world.dateOfPublish) >= new Date(filters.dateOfPublish)
            );
        }

        setFilteredWorlds(results);
    }, [filters, worlds]);

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };

    const handleFilterChange = (filter, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [filter]: value,
        }));
    };

    const toggleSection = section => {
        setExpandedSection(expandedSection === section ? null : section);
    };
    const handelClickWorld = (worldId) => {
        dispatch(setWorldId(worldId))
        navigate('/add-level')
    }

    return (
        <PageContainer>
            <ContentContainer>
                {sidebarVisible && (
                    <Sidebar>
                        <FilterSection>
                            <h3>Filters</h3>

                            <Filter>
                                <label>Number of Students</label>
                                <input
                                    type="number"
                                    value={filters.numberOfStudents}
                                    onChange={e => handleFilterChange('numberOfStudents', e.target.value)}
                                />
                            </Filter>

                            <Filter>
                                <label>Allow Advisors</label>
                                <select
                                    value={filters.allowAdvisors}
                                    onChange={e => handleFilterChange('allowAdvisors', e.target.value)}
                                >
                                    <option value="">Any</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </Filter>

                            <Filter>
                                <label>Cash Generated (≥)</label>
                                <input
                                    type="number"
                                    step="0.01"
                                    value={filters.cashGenerated}
                                    onChange={e => handleFilterChange('cashGenerated', e.target.value)}
                                />
                            </Filter>

                            <Filter>
                                <label>Rate (≥)</label>
                                <input
                                    type="number"
                                    step="0.1"
                                    value={filters.rate}
                                    onChange={e => handleFilterChange('rate', e.target.value)}
                                />
                            </Filter>

                            <Filter>
                                <label>Date of Publish (≥)</label>
                                <input
                                    type="date"
                                    value={filters.dateOfPublish}
                                    onChange={e => handleFilterChange('dateOfPublish', e.target.value)}
                                />
                            </Filter>
                        </FilterSection>
                    </Sidebar>
                )}

                <ToggleSidebarButton onClick={toggleSidebar}>
                    {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
                </ToggleSidebarButton>

                <MainContent>
                    <h1>Filtered Worlds</h1>
                    <WorldsGrid>
    {filteredWorlds.map((world, index) => {
        const totalRate = world?.rate?.reduce((sum, r) => sum + r.rate, 0);
        const averageRate = totalRate / world?.rate?.length || 0;

        return (
            <WorldCard key={index}>
                <SettingsIcon onClick={() => handelClickWorld(world._id)}>
                    <FaCog size={20} />
                </SettingsIcon>
                <Publisher>
                    <div>
                        <Rating>
                            {Array.from({ length: 5 }, (_, i) => (
                                <FaStar key={i} color={i < averageRate ? '#f39c12' : '#dcdcdc'} />
                            ))}
                        </Rating>
                    </div>
                </Publisher>
                <Thumbnail src={world.worldThumbnail || '/images/default-world-thumbnail.jpg'} />
                <Info>
                    <h3>{world.worldName}</h3>
                    <p>{world.worldDescription}</p>
                    <p>{world.price} $</p>
                    <p>{world.advisorAmount}</p>
                    <p>
                        <FaUserGraduate /> {world.numberOdStudents}
                    </p>
                    <p>
                        <FaChalkboardTeacher /> {world.numberOfAdvisors}
                    </p>
                    {world.isWorldAllowingAdvisors && (
                        <p>
                            <FaCheckCircle color="green" /> Allowing Advisors
                        </p>
                    )}
                    <p>Date of Publish: {new Date(world.dateOfPublish).toLocaleDateString()}</p>
                </Info>
            </WorldCard>
        );
    })}
</WorldsGrid>
                </MainContent>
            </ContentContainer>
        </PageContainer>
    );
}

export default AllUploads;

// Styled Components






const FilterSection = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        margin-bottom: 20px;
        font-size: 18px;
        color: #2c3e50;
    }
`;

const Filter = styled.div`
    margin-bottom: 20px;

    label {
        display: block;
        margin-bottom: 8px;
        font-weight: bold;
        color: #34495e;
    }

    input,
    select {
        width: 100%;
        padding: 10px;
        border: 1px solid #dcdcdc;
        border-radius: 6px;
        transition: border-color 0.3s ease;

        &:focus {
            border-color: #3498db;
            outline: none;
        }
    }
`;



// Responsive Design
const ResponsiveContainer = styled.div`
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;




const SettingsIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #2c3e50;
    transition: color 0.3s ease;

    &:hover {
        color: #3498db;
    }
`;
























const PageContainer = styled.div`
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 60px 20px 20px; /* Add top padding to account for the navbar height */
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ContentContainer = styled.div`
    display: flex;
    position: relative; /* Allows absolute positioning of the toggle button */
    width: 100%;
    min-height: calc(100vh - 60px); /* Adjust to ensure it spans the full viewport height minus the navbar */
`;

const Sidebar = styled.div`
    width: 300px;
    position: fixed; /* Fixes it to the left side */
    top: 60px; /* Matches the height of the navbar */
    left: 0;
    bottom: 0; /* Ensures it spans the full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Ensures content is scrollable */
`;


const Navbar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2c3e50;
    color: white;
    position: fixed; /* Keep the navbar fixed at the top */
    top: 0;
    left: 0;
    width: 100%; /* Make it span the entire width */
    z-index: 1000; /* Ensure it stays above other elements */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
`;


const LeftNav = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.div`
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
`;

const NavLinks = styled.div`
    margin-left: 20px;
    display: flex;
    gap: 15px;

    a {
        color: white;
        text-decoration: none;
    }
`;

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
    }
`;

const NavIcons = styled.div`
    display: flex;
    gap: 15px;
`;

const ProfileWrapper = styled.div`
    position: relative;
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;

const SectionHeader = styled.h2`
    font-size: 18px;
    color: #2c3e50;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
        font-size: 20px;
        color: #2980b9;
    }
`;

const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
`;

const WorldsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;

const WorldCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-10px);
    }
`;

const Publisher = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    p {
        font-size: 14px;
        color: #2c3e50;
        margin: 0;
    }
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
`;

const Info = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;

const Rating = styled.div`
    display: flex;
`;
const Actions = styled.div`
    display: flex;
    flex-direction: column; /* Stack ActionRow elements vertically */
    gap: 10px; /* Add spacing between rows */
`;




const MainContent = styled.div`
    margin-left: 300px; /* Ensures content starts after the fixed sidebar */
    flex-grow: 1; /* Ensures MainContent takes remaining space */
    padding: 20px;
`;



const GreenButton = styled.button`
    background-color: #27ae60 !important; /* Green color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #219150 !important; /* Darker green on hover */
    }
`;

const NavColorButton = styled.button`
    background-color: #2c3e50 !important; /* Navbar color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #1a252f !important; /* Darker navbar color on hover */
    }
`;

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between rows */
`;
const ToggleSidebarButton = styled.div`
    position: fixed; /* Ensures it's positioned relative to the viewport */
    top: 100px; /* Adjust to set vertical placement */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement based on sidebar visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    background-color: #ddd;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: left 0.3s ease; /* Smooth transition when toggling */
`;




