import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {  useNavigate } from "react-router-dom";
import { setPostId } from "../../../reducers/reducers";
import styled from 'styled-components';
import { Button, Card, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";

function UploadPostToAllLevels() {
  const worldId = useSelector((state) => state.allow.worldId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resources, setResources] = useState([]);
  const [postTitle, setPostTitle] = useState("");
  const [posts, setPosts] = useState([]);
  const [postDescription, setPostDescription] = useState("");
  const [postThumbnail, setPostThumbnail] = useState(null);
    
  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await axios.get(
          "https://sell-skill.com/api/endpoints/WgetPosts",
          { params: { worldId } }
        );
        setPosts(response.data);

        console.log('response.data from get posts ===========================-=--=-=-=-=-=-=--> > > > > >  > >> >  ',response.data)
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    getPosts();
  }, [worldId]);

  const handleEditPost = async (postId) => {
    dispatch(setPostId(postId));
    navigate("/edit-post-to-world");
  };

  const handleDeletePost = async (postId) => {
    dispatch(setPostId(postId));
    await axios.delete("https://sell-skill.com/api/endpoints/WdeletePost", 
  { worldId, postId },
    );
  };

  const handleFileUpload = async (file) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `posts/${uuidv4()}_${file.name}`);
      await uploadBytes(fileRef, file);
      return await getDownloadURL(fileRef);
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const handleAddResource = async (type) => {
    let content = null;

    if (type === "photo" || type === "video" || type === "pdf") {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept =
        type === "photo" ? "image/*" : type === "video" ? "video/*" : ".pdf";
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const url = await handleFileUpload(file);
          if (url) {
            setResources([...resources, { type, content: url }]);
          }
        }
      };
      fileInput.click();
    } else {
      content = prompt(`Enter ${type} content:`);
      if (content) {
        if (type === "quiz") {
          const choices = prompt("Enter quiz choices, separated by commas").split(
            ","
          );
          const correctAnswer = prompt("Enter the correct answer for the quiz");
          setResources([
            ...resources,
            { type, content, choices, correctAnswer },
          ]);
        } else {
          setResources([...resources, { type, content }]);
        }
      }
    }
  };

  const handleSubmit = async () => {
    const _id = uuidv4();
    let thumbnailUrl = null;

    if (postThumbnail) {
      thumbnailUrl = await handleFileUpload(postThumbnail);
    }

    try {
      await axios.post(
        "https://sell-skill.com/api/endpoints/WaddPost",
        {
          _id,
          worldId,
          postTitle,
          postDescription,
          postThumbnail: thumbnailUrl,
          resources,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      alert("Post uploaded successfully!");

      setPosts((prevPosts) => [
        ...prevPosts,
        {
          _id,
          title: postTitle,
          description: postDescription,
          thumbnail: thumbnailUrl,
          resources,
        },
      ]);
    } catch (error) {
      console.error("Error uploading post:", error);
      alert("Failed to upload post.");
    }
  };













  const [showModal, setShowModal] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    const getPosts = async () => {
      try {
        const response = await axios.get(
          "https://sell-skill.com/api/endpoints/WgetPosts",
          { params: { worldId } }
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    getPosts();
  }, [worldId]);


  const handleShowModal = (post) => {
    setSelectedPost(post);
    setResources([
      ...(post.photos || []).map((item) => ({ type: "photo", content: item.contentUrl })),
      ...(post.videos || []).map((item) => ({ type: "video", content: item.contentUrl })),
      ...(post.pdfs || []).map((item) => ({ type: "pdf", content: item.contentUrl })),
      ...(post.texts || []).map((item) => ({ type: "text", content: item })),
      ...(post.quizs || []).map((item) => ({
        type: "quiz",
        content: item.content,
        choices: item.choices,
        correctAnswer: item.correctAnswer,
      })),
    ]);

    console.log('resources =============================-=-=-=-=-=-=-=-=-> > > >  > > > >>  ',resources)
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);
  return (
    <div className="d-flex gap-3">
    {/* Sidebar */}
    <Sidebar>
      <h3>Sidebar</h3>
      <button
        className="btn btn-light"
        onClick={() => handleAddResource("photo")}
      >
        Add Photo
      </button>
      <button
        className="btn btn-light"
        onClick={() => handleAddResource("video")}
      >
        Add Video
      </button>
      <button
        className="btn btn-light"
        onClick={() => handleAddResource("pdf")}
      >
        Add PDF
      </button>
      <button
        className="btn btn-light"
        onClick={() => handleAddResource("text")}
      >
        Add Text
      </button>
      <button
        className="btn btn-light"
        onClick={() => handleAddResource("quiz")}
      >
        Add Quiz
      </button>
    </Sidebar>

<MainContainer>
    <Container>
      <h3 className="mt-4">Create Post</h3>
      <Form>
        <Form.Group className="mb-3" controlId="formPostTitle">
          <Form.Label>Post Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter post title"
            value={postTitle}
            onChange={(e) => setPostTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPostDescription">
          <Form.Label>Post Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter post description"
            value={postDescription}
            onChange={(e) => setPostDescription(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPostThumbnail">
          <Form.Label>Post Thumbnail</Form.Label>
          <Form.Control
            type="file"
            onChange={(e) => setPostThumbnail(e.target.files[0])}
          />
        </Form.Group>
        <Button variant="success" onClick={handleSubmit}>
          Submit Post
        </Button>
      </Form>

      <h3 className="mt-5">Posts</h3>
      <Row>
        {posts.map((post) => (
          <Col md={4} key={post._id} className="mb-4">
            <Card>
              {post.thumbnail && (
                <Card.Img variant="top" src={post.thumbnail} style={{ height: "150px", objectFit: "cover" }} />
              )}
              <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.description}</Card.Text>
                <Button variant="primary" onClick={() => handleEditPost(post._id)}>
                  Edit
                </Button>
                <Button
                  variant="danger"
                  className="ms-2"
                  onClick={() => handleDeletePost(post._id)}
                >
                  Delete
                </Button>
                <Button
                  variant="info"
                  className="ms-2"
                  onClick={() => handleShowModal(post)}
                >
                  View Resources
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Resources for {selectedPost?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>


                <ListGroup variant="flush">
                  {resources?.map((res, idx) => (
                    <ListGroup.Item key={idx}>
                      {res.type}:{" "}
                      {res.type === "photo" && <img src={res.content} alt="Photo" style={{ maxWidth: "100px" }} />}
                      {res.type === "video" && (
                        <video controls style={{ maxWidth: "200px" }}>
                          <source src={res.content} type="video/mp4" />
                        </video>
                      )}
                      {res.type === "pdf" && (
                        <a href={res.content} target="_blank" rel="noopener noreferrer">
                          View PDF
                        </a>
                      )}
                      {res.type === "text" && <p>{res.content}</p>}
                      {res.type === "quiz" && (
                        <>
                          <p>{res.content}</p>
                          <ul>
                            {res.choices?.map((choice, i) => (
                              <li key={i}>{choice}</li>
                            ))}
                          </ul>
                          <p>Correct Answer: {res.correctAnswer}</p>
                        </>
                      )}

                    </ListGroup.Item>
                  ))}
                </ListGroup>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
    </MainContainer>
  </div>
  );
}

export default UploadPostToAllLevels;


const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  background-color: #2c3e50;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
`;

const ThumbnailImage = styled.img`
  margin-top: 10px;
  width: 100%;
  max-height: 150px;
  object-fit: cover;
`;



const MainContainer = styled(Container)`
  margin-left: 220px; /* Adjust to match Sidebar width */
  margin-top: 20px;
  padding: 20px; /* Optional: Add padding for better spacing */
`;

























// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { v4 as uuidv4 } from "uuid";
// import axios from "axios";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import { useNavigate } from "react-router-dom";
// import { setPostId } from "../../../reducers/reducers";

// function UploadPostToAllLevels() {
// const worldId = useSelector((state) => state.allow.worldId)
// const navigate = useNavigate()
// const dispatch = useDispatch()
// useEffect(() => {
//   const getPosts = async () => {
//     try {
//       const response = await axios.get(
//         "https://sell-skill.com/api/endpoints/WgetPosts",
//         { params: { worldId } }
//       );
//       setPosts(response.data);
//     } catch (error) {
//       console.error("Error fetching posts:", error);
//     }
//   };
//   getPosts();
// }, [worldId]);
// const handleEditPost = async(postId) => {
//   console.log('postId from handleEditPost =========================================-===---=-=-====--=> > > > >>> >>> ',postId)
//   dispatch(setPostId(postId))
//   navigate('/edit-post-to-world')

// }

// const handleDeletePost = async(postId) => {
//   console.log('postId from handleDeletePost =========================================-===---=-=-====--=> > > > >>> >>> ',postId)
//   dispatch(setPostId(postId))
//   await axios.delete('https://sell-skill.com/api/endpoints/WdeletePost', {worldId, postId})
// }
//   const [resources, setResources] = useState([]);
//   const [postTitle, setPostTitle] = useState("");
//   const [posts, setPosts] = useState([])
//   const [postDescription, setPostDescription] = useState("");
//   const [postThumbnail, setPostThumbnail] = useState(null);


//   const handleFileUpload = async (file) => {
//     try {
//       const storage = getStorage();
//       const fileRef = ref(storage, `posts/${uuidv4()}_${file.name}`);
//       await uploadBytes(fileRef, file);
//       return await getDownloadURL(fileRef);
//     } catch (error) {
//       console.error("Error uploading file:", error);
//       return null;
//     }
//   };

//   const handleAddResource = async (type) => {
//     let content = null;

//     if (type === "photo" || type === "video" || type === "pdf") {
//       const fileInput = document.createElement("input");
//       fileInput.type = "file";
//       fileInput.accept = type === "photo" ? "image/*" : type === "video" ? "video/*" : ".pdf";
//       fileInput.onchange = async (event) => {
//         const file = event.target.files[0];
//         if (file) {
//           const url = await handleFileUpload(file);
//           if (url) {
//             setResources([...resources, { type, content: url }]);
//           }
//         }
//       };
//       fileInput.click();
//     } else {
//       content = prompt(`Enter ${type} content:`);
//       if (content) {
//         if (type === "quiz") {
//           const choices = prompt("Enter quiz choices, separated by commas").split(",");
//           const correctAnswer = prompt("Enter the correct answer for the quiz");
//           setResources([...resources, { type, content, choices, correctAnswer }]);
//         } else {
//           setResources([...resources, { type, content }]);
//         }
//       }
//     }
//   };

//   const handleSubmit = async () => {
//     const _id = uuidv4();
//     let thumbnailUrl = null;

//     if (postThumbnail) {
//       thumbnailUrl = await handleFileUpload(postThumbnail);
//     }

//     try {
//       await axios.post(
//         "https://sell-skill.com/api/endpoints/WaddPost",
//         {
//           _id,
//           worldId,
//           postTitle,
//           postDescription,
//           postThumbnail: thumbnailUrl,
//           resources,
//         },
//         { headers: { "Content-Type": "application/json" } }
//       );
//       alert("Post uploaded successfully!");
//     } catch (error) {
//       console.error("Error uploading post:", error);
//       alert("Failed to upload post.");
//     }
//   };



//   return (
//     <div style={{ display: "flex", gap: "20px" }}>
//       {/* Sidebar */}
//       <div style={{ flex: 1, border: "1px solid #ccc", padding: "10px" }}>
//         <h3>Sidebar</h3>
//         <button onClick={() => handleAddResource("photo")}>Add Photo</button>
//         <button onClick={() => handleAddResource("video")}>Add Video</button>
//         <button onClick={() => handleAddResource("pdf")}>Add PDF</button>
//         <button onClick={() => handleAddResource("text")}>Add Text</button>
//         <button onClick={() => handleAddResource("quiz")}>Add Quiz</button>
//       </div>

//       {/* Main Content */}
//       <div style={{ flex: 3, border: "1px solid #ccc", padding: "10px" }}>
//         <h3>Create Post</h3>
//         <input
//           type="text"
//           placeholder="Post Title"
//           value={postTitle}
//           onChange={(e) => setPostTitle(e.target.value)}
//         />
//         <textarea
//           placeholder="Post Description"
//           value={postDescription}
//           onChange={(e) => setPostDescription(e.target.value)}
//         />
//         <input
//           type="file"
//           onChange={(e) => setPostThumbnail(e.target.files[0])}
//         />
//         <h4>Resources:</h4>
//         <ul>
//           {resources.map((res, idx) => (
//             <li key={idx}>
//               {res.type}: {res.type === "quiz" ? res.content : <a href={res.content} target="_blank" rel="noopener noreferrer">View</a>}
//             </li>
//           ))}
//         </ul>
//         <button onClick={handleSubmit}>Submit Post</button>
//       </div>


//       <div>
//       <h1>Posts</h1>
//       {posts.length === 0 ? (
//         <p>No posts available</p>
//       ) : (
//         <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "20px" }}>
//           {posts.map((post) => (
//             <div
//               key={post._id}
//               style={{
//                 border: "1px solid #ccc",
//                 borderRadius: "5px",
//                 padding: "10px",
//                 boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//               }}
//             >
//               <h3>{post.title}</h3>
//               {post.thumbnail && (
//                 <img
//                   src={post.thumbnail}
//                   alt={post.title}
//                   style={{ width: "100%", height: "150px", objectFit: "cover" }}
//                 />
//               )}
//               <p>{post.description}</p>
//               <p>
//                 <strong>Posted by:</strong> {post.poster.name}
//               </p>
//               <ul>
//                 {post.photos?.map((photo, idx) => (
//                   <li key={idx}>
//                     <a href={photo.contentUrl} target="_blank" rel="noopener noreferrer">
//                       View Photo {idx + 1}
//                     </a>
//                   </li>
//                 ))}
//                 {post.videos?.map((video, idx) => (
//                   <li key={idx}>
//                     <a href={video.contentUrl} target="_blank" rel="noopener noreferrer">
//                       View Video {idx + 1}
//                     </a>
//                   </li>
//                 ))}
//                 {post.pdfs?.map((pdf, idx) => (
//                   <li key={idx}>
//                     <a href={pdf.contentUrl} target="_blank" rel="noopener noreferrer">
//                       View PDF {idx + 1}
//                     </a>
//                   </li>
//                 ))}
//               </ul>
//               <button onClick={() => handleEditPost(post._id)}>edit post</button>
//               <button onClick={() => handleDeletePost(post._id)}>deletePost</button>
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//     </div>
//   );
// }

// export default UploadPostToAllLevels;
