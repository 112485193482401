import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import styled from 'styled-components';

function InsideLesson() {
  const [resources, setResources] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedResourceType, setSelectedResourceType] = useState('')
  const [newResource, setNewResource] = useState({ type: '', content: '', choices: [], correctAnswer: '' });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSidebarSelection = (type) => {
    setSelectedResourceType(type);
    setNewResource({ type, content: '', choices: [], correctAnswer: '', file: null });
    setShowModal(true);
  };
  
  const worldName = useSelector((state) => state.allow.worldName);
  const levelNumber = useSelector((state) => state.allow.levelNumber);
  const lessonNumber = useSelector((state) => state.allow.lessonNumber);
  const worldId = useSelector((state) => state.allow.worldId);
  const levelId = useSelector((state) => state.allow.levelId);
  const lessonId = useSelector((state) => state.allow.lessonId);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getResources`, {
          params: { worldId, levelId, lessonId }
        });
        setResources(response.data);
      } catch (error) {
        console.error("Error fetching resources:", error);
      }
    };

    fetchResources();
  }, [worldId, levelId, lessonId]);

  const handleFileUpload = async (file) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `lesson-resources/${uuidv4()}_${file.name}`);
      await uploadBytes(fileRef, file);
      return await getDownloadURL(fileRef);
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };   

  const addResource = async () => {
    if (newResource.type !== 'text' && newResource.type !== 'quiz' && newResource.file) {
      const url = await handleFileUpload(newResource.file);
      setResources([...resources, { ...newResource, content: url }]);
    } else {
      setResources([...resources, { ...newResource }]);
    }
    setNewResource({ type: '', content: '', choices: [], correctAnswer: '', file: null });
    setShowModal(false);
  };

  const deleteResource = (index) => {
    setResources(resources.filter((_, i) => i !== index));
  };

  const saveResources = async () => {
    try {
      console.log('req.body going through edit resources ==========================---=-=---=-=-=-> > > > > > > >  ',worldId, levelId, lessonId, 'resources ==================-=-=-=-> > > > ',resources)
      await axios.patch('https://sell-skill.com/api/endpoints/editResources', {
        worldId,
        levelId,
        lessonId,
        elements: resources,
      });
      alert('Resources saved successfully!');
    } catch (error) {
      console.error("Error saving resources:", error);
    }
  };

  const renderResource = (resource, index) => {
    switch (resource.type) {
      case 'video':
        return (
          <div key={index} className="resource-card">
            <h3>Video</h3>
            <video controls src={resource.content} width="100%" />
            <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
          </div>
        );
      case 'image':
        return (
          <div key={index} className="resource-card">
            <h3>Image</h3>
            <button onClick={() => console.log('resource ====================-=-=-=-=-=-=-=> > >  > > > > > > >  ',resource)}>log resource</button>
            <img src={resource.url} alt="Resource" style={{ width: '100%' }} />
            <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
          </div>
        );
      case 'pdf':
        return (
          <div key={index} className="resource-card">
            <h3>PDF</h3>
            <a href={resource.content} target="_blank" rel="noopener noreferrer">View PDF</a>
            <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
          </div>
        );
      case 'text':
        return (
          <div key={index} className="resource-card">
            <h3>Text</h3>
            <p>{resource.content}</p>
            <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
          </div>
        );
      case 'quiz':
        return (
          <div key={index} className="resource-card">
            <h3>Quiz</h3>
            <p>{resource.content}</p>
            <ul>
              {resource.choices.map((choice, i) => (
                <li key={i}>{choice}</li>
              ))}
            </ul>
            <p>Correct Answer: {resource.correctAnswer}</p>
            <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <LayoutContainer>
    {/* Sidebar */}
    <Sidebar>
      <SidebarItem onClick={() => handleSidebarSelection('video')}>Video</SidebarItem>
      <SidebarItem onClick={() => handleSidebarSelection('image')}>Photo</SidebarItem>
      <SidebarItem onClick={() => handleSidebarSelection('pdf')}>PDF</SidebarItem>
      <SidebarItem onClick={() => handleSidebarSelection('text')}>Text</SidebarItem>
      <SidebarItem onClick={() => handleSidebarSelection('quiz')}>Quiz</SidebarItem>
    </Sidebar>

    {/* Main Content */}
    <MainContent>
      <h1>Lesson Resources</h1>
      <div className="resources-container">
        {resources.map((resource, index) => renderResource(resource, index))}
      </div>
      <Button variant="success" onClick={saveResources}>Save</Button>
    </MainContent>

    {/* Modal */}
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <ModalHeader>
        <Modal.Title>Add New Resource</Modal.Title>
      </ModalHeader>
      <ModalBody>
        <Form>
          {['video', 'image', 'pdf'].includes(selectedResourceType) && (
            <Form.Group>
              <Form.Label>File</Form.Label>
              <Form.Control type="file" onChange={(e) => setNewResource({ ...newResource, file: e.target.files[0] })} />
            </Form.Group>
          )}
          {selectedResourceType === 'text' && (
            <Form.Group>
              <Form.Label>Content</Form.Label>
              <Form.Control type="text" value={newResource.content} onChange={(e) => setNewResource({ ...newResource, content: e.target.value })} />
            </Form.Group>
          )}
          {selectedResourceType === 'quiz' && (
            <>
              <Form.Group>
                <Form.Label>Question</Form.Label>
                <Form.Control type="text" value={newResource.content} onChange={(e) => setNewResource({ ...newResource, content: e.target.value })} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Choices</Form.Label>
                <Form.Control type="text" placeholder="Separate choices with commas" onChange={(e) => setNewResource({ ...newResource, choices: e.target.value.split(',') })} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Correct Answer</Form.Label>
                <Form.Control type="text" value={newResource.correctAnswer} onChange={(e) => setNewResource({ ...newResource, correctAnswer: e.target.value })} />
              </Form.Group>
            </>
          )}
        </Form>
      </ModalBody>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
        <Button variant="primary" onClick={addResource}>Add</Button>
      </Modal.Footer>
    </Modal>
  </LayoutContainer>
  );
}

export default InsideLesson;


  

const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
`;


const SidebarItem = styled.div`
  padding: 10px;
  margin: 5px 0;
  background-color: #007bff;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  background-color: #007bff;
  color: white;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const ModalBody = styled.div`
  padding: 20px;
`;

const Sidebar = styled.div`
  position: fixed; /* Fix the sidebar to the viewport */
  top: 0; /* Align with the top of the viewport */
  left: 0; /* Align with the leftmost edge of the viewport */
  bottom: 0; /* Stretch to the bottom of the viewport */
  width: 300px; /* Set the width of the sidebar */
  background-color: #f4f4f4; /* Sidebar background */
  padding: 20px; /* Internal padding */
  border-right: 1px solid #ddd; /* Right border for separation */
  overflow-y: auto; /* Enable vertical scrolling if content overflows */
  z-index: 1000; /* Keep it above other elements */
  margin: 0; /* Ensure no extra margin is applied */
`;
























// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { Button, Modal, Form } from 'react-bootstrap';
// import { v4 as uuidv4 } from 'uuid';
// import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';

// function InsideLesson() {
//   const [resources, setResources] = useState([]);
//   const [showModal, setShowModal] = useState(false);
//   const [newResource, setNewResource] = useState({ type: '', content: '', choices: [], correctAnswer: '' });
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const worldName = useSelector((state) => state.allow.worldName);
//   const levelNumber = useSelector((state) => state.allow.levelNumber);
//   const lessonNumber = useSelector((state) => state.allow.lessonNumber);
//   const worldId = useSelector((state) => state.allow.worldId);
//   const levelId = useSelector((state) => state.allow.levelId);
//   const lessonId = useSelector((state) => state.allow.lessonId);

//   useEffect(() => {
//     const fetchResources = async () => {
//       try {
//         const response = await axios.get(`https://sell-skill.com/api/endpoints/getResources`, {
//           params: { worldId, levelId, lessonId }
//         });
//         setResources(response.data);
//       } catch (error) {
//         console.error("Error fetching resources:", error);
//       }
//     };

//     fetchResources();
//   }, [worldId, levelId, lessonId]);

//   const handleFileUpload = async (file) => {
//     try {
//       const storage = getStorage();
//       const fileRef = ref(storage, `lesson-resources/${uuidv4()}_${file.name}`);
//       await uploadBytes(fileRef, file);
//       return await getDownloadURL(fileRef);
//     } catch (error) {
//       console.error('Error uploading file:', error);
//       return null;
//     }
//   };

//   const addResource = async () => {
//     if (newResource.type !== 'text' && newResource.type !== 'quiz' && newResource.file) {
//       const url = await handleFileUpload(newResource.file);
//       setResources([...resources, { ...newResource, content: url }]);
//     } else {
//       setResources([...resources, { ...newResource }]);
//     }
//     setNewResource({ type: '', content: '', choices: [], correctAnswer: '', file: null });
//     setShowModal(false);
//   };

//   const deleteResource = (index) => {
//     setResources(resources.filter((_, i) => i !== index));
//   };

//   const saveResources = async () => {
//     try {
//       console.log('req.body going through edit resources ==========================---=-=---=-=-=-> > > > > > > >  ',worldId, levelId, lessonId, 'resources ==================-=-=-=-> > > > ',resources)
//       await axios.patch('https://sell-skill.com/api/endpoints/editResources', {
//         worldId,
//         levelId,
//         lessonId,
//         elements: resources,
//       });
//       alert('Resources saved successfully!');
//     } catch (error) {
//       console.error("Error saving resources:", error);
//     }
//   };

//   const renderResource = (resource, index) => {
//     switch (resource.type) {
//       case 'video':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Video</h3>
//             <video controls src={resource.content} width="100%" />
//             <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
//           </div>
//         );
//       case 'photo':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Image</h3>
//             <img src={resource.content} alt="Resource" style={{ width: '100%' }} />
//             <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
//           </div>
//         );
//       case 'pdf':
//         return (
//           <div key={index} className="resource-card">
//             <h3>PDF</h3>
//             <a href={resource.content} target="_blank" rel="noopener noreferrer">View PDF</a>
//             <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
//           </div>
//         );
//       case 'text':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Text</h3>
//             <p>{resource.content}</p>
//             <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
//           </div>
//         );
//       case 'quiz':
//         return (
//           <div key={index} className="resource-card">
//             <h3>Quiz</h3>
//             <p>{resource.content}</p>
//             <ul>
//               {resource.choices.map((choice, i) => (
//                 <li key={i}>{choice}</li>
//               ))}
//             </ul>
//             <p>Correct Answer: {resource.correctAnswer}</p>
//             <Button variant="danger" onClick={() => deleteResource(index)}>Delete</Button>
//           </div>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div>
//       <h1>Lesson Resources</h1>
//       <Button variant="primary" onClick={() => setShowModal(true)}>Add Resource</Button>
//       <div className="resources-container">
//         {resources.map((resource, index) => renderResource(resource, index))}
//       </div>
//       <Button variant="success" onClick={saveResources}>Save</Button>

//       <Modal show={showModal} onHide={() => setShowModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Add New Resource</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group>
//               <Form.Label>Type</Form.Label>
//               <Form.Control as="select" value={newResource.type} onChange={(e) => setNewResource({ ...newResource, type: e.target.value })}>
//                 <option value="">Select Type</option>
//                 <option value="video">Video</option>
//                 <option value="photo">photo</option>
//                 <option value="pdf">PDF</option>
//                 <option value="text">Text</option>
//                 <option value="quiz">Quiz</option>
//               </Form.Control>
//             </Form.Group>

//             {['video', 'photo', 'pdf'].includes(newResource.type) && (
//               <Form.Group>
//                 <Form.Label>File</Form.Label>
//                 <Form.Control type="file" onChange={(e) => setNewResource({ ...newResource, file: e.target.files[0] })} />
//               </Form.Group>
//             )}

//             {newResource.type === 'text' && (
//               <Form.Group>
//                 <Form.Label>Content</Form.Label>
//                 <Form.Control type="text" value={newResource.content} onChange={(e) => setNewResource({ ...newResource, content: e.target.value })} />
//               </Form.Group>
//             )}

//             {newResource.type === 'quiz' && (
//               <>
//                 <Form.Group>
//                   <Form.Label>Question</Form.Label>
//                   <Form.Control type="text" value={newResource.content} onChange={(e) => setNewResource({ ...newResource, content: e.target.value })} />
//                 </Form.Group>
//                 <Form.Group>
//                   <Form.Label>Choices</Form.Label>
//                   <Form.Control type="text" placeholder="Separate choices with commas" onChange={(e) => setNewResource({ ...newResource, choices: e.target.value.split(',') })} />
//                 </Form.Group>
//                 <Form.Group>
//                   <Form.Label>Correct Answer</Form.Label>
//                   <Form.Control type="text" value={newResource.correctAnswer} onChange={(e) => setNewResource({ ...newResource, correctAnswer: e.target.value })} />
//                 </Form.Group>
//               </> 
//             )}
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
//           <Button variant="primary" onClick={addResource}>Add</Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }

// export default InsideLesson;
