import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { FaBell, FaChevronLeft, FaChevronRight, FaComments } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { v4 as uuidv4 } from 'uuid';

function ManageFinancial() {
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const withdrawId = uuidv4();
    const [error, setError] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [selectedSection, setSelectedSection] = useState("overview"); // Default section
    const [profilePictureSrc, setProfilePictureSrc] = useState("");
    const dropdownRef = useRef(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [moneyInHistory, setMoneyInHistory] = useState([])
    const [moneyOutHistory, setMoneyOutHistory] = useState([])
    const [recipientEmail, setRecipientEmail] = useState('')
    const [withdrawMoneyHistroy, setWithdrawMoneyHistory] = useState([])
    const [totalCash, setTotalCash] = useState('')
    const sendPayment = async () => {
      try {
        const response = await axios.post(
          'https://sell-skill.com/api/endpoints/send-payment',
          {
            recipientEmail,
            withdrawId,
            amount: '10.00', // Fixed $10 payment
          }
        );

      } catch (error) {
        console.error('Error sending payment:', error);

      }
    };
  
    const navigate = useNavigate();

    useEffect(() => {
      const getTotalCash = async() => {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getTotalCash')
        setTotalCash(response.data)
      }
      getTotalCash()
    }, [])

    useEffect(() => {
      const getMoneyInHistory = async() => {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getMoneyInHistory')
        setMoneyInHistory(response.data)
      }
      getMoneyInHistory()
    }, [])

    useEffect(() => {
      const getWithdrawMoneyHistory = async() => {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getWithdrawMoneyHistory')
        setWithdrawMoneyHistory(response.data)
      }
      getWithdrawMoneyHistory()
    }, [])

    useEffect(() => {
      const getMoneyOutHistory = async() => {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getMoneyOutHistory')
        setMoneyOutHistory(response.data)

      }
      getMoneyOutHistory()
    }, [])
  
    useEffect(() => {
      const fetchProfile = async () => {
        try {
          const response = await axios.get(
            "https://sell-skill.com/api/endpoints/getProfile"
          );
          setProfile(response.data);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      const fetchProfilePicture = async () => {
        try {
          const response = await axios.get(
            "https://sell-skill.com/api/endpoints/getSkillerProfilePicture",
            { responseType: "blob" }
          );
          const url = URL.createObjectURL(response.data);
          setProfilePictureSrc(url);
        } catch (error) {
          console.error("Error fetching profile picture:", error);
        }
      };
  
      fetchProfile();
      fetchProfilePicture();
    }, []);
  
    const handleProfileClick = () => {
      setShowDropdown((prev) => !prev);
    };
  
    const handleSectionClick = (section) => {
      setSelectedSection(section); // Update the selected section
    };
  
    const renderSectionContent = () => {
      switch (selectedSection) {
        case "withdrawCash":
          return (
            <div>
              <h1>Send Payment</h1>
              <input
                type="email"
                placeholder="Recipient Email"
                value={recipientEmail}
                onChange={(e) => setRecipientEmail(e.target.value)}
              />
              <button onClick={sendPayment}>Send $10</button>

            </div>
          );
        case "moneyOut":
          return (
            <div>
              <h2>money out history</h2>
              <ul>
                {moneyOutHistory.map((moneyOut) => (
                  <li key={moneyOut._id}> you have made a payment of {moneyOut.amount} $ and subscirbed in the {moneyOut.world.name} which is upload by {moneyOut.receiver.name}, at {moneyOut.date}</li>
                ))}
              </ul>
            </div>
          );
        case "moneyIn":
          return (
            <div>
              <h2>Money In history</h2>
              <ul>
                {moneyInHistory.map((moneyIn) => (
                  <li key={moneyIn._id}> you have received {moneyIn.amount} $ as a payment done by {moneyIn.sender.name} at your world {moneyIn.world.name} at {moneyIn.date}</li>
                ))}
              </ul>
            </div>
          );
          case "withdrawHistory":
            return (
              <div>
                <h2>Withdraw cash history</h2>
                <ul>
                  {withdrawMoneyHistroy.map((withdrawMoney) => (
                    <li key={withdrawMoney._id}> you have withdraw {withdrawMoney.amount} at {withdrawMoney.date}</li>
                  ))}
                </ul>
              </div>
            );
        default:
          return (
            <div>
              <h2>Finacial Management</h2>
              <p>Your account has total of {totalCash} $</p>
            </div>
          );
      }
    };
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!profile) return <p>No profile data found.</p>;
  
    return (
      <PageContainer>
        {/* Top Navbar */}
        <Navbar>
          <LeftNav>
            <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>
            <NavLinks>
              <a href="/my-learning">My Profile</a>
              <a href="/my-earning">My Uploads</a>
              <a>Withdraw Cash</a>
              <a href="/my-cart">My Cart</a>
              <a href="/certifications">Certifications</a>
              <a href="/exams">Exams</a>
            </NavLinks>
          </LeftNav>

          <NavIcons>
            <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
            <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
            <div ref={dropdownRef}>
              <ProfileWrapper onClick={handleProfileClick}>
                <ProfilePicture
                  src={profilePictureSrc || "/images/default-profile.jpg"}
                  alt="Profile"
                />
              </ProfileWrapper>
              {showDropdown && (
                <DropdownMenu>
                  <a href="/my-profile">My Profile</a>
                  <a href="/logout">Log Out</a>
                </DropdownMenu>
              )}
            </div>
          </NavIcons>
        </Navbar>
  
        {/* Sidebar and Main Content */}
        <ContentContainer>
          {sidebarVisible && (
            <Sidebar>
              <SectionHeader onClick={() => handleSectionClick("withdrawCash")}>
                withdraw cash
              </SectionHeader>
              <SectionHeader onClick={() => handleSectionClick("moneyOut")}>
                Money Out
              </SectionHeader>
              <SectionHeader onClick={() => handleSectionClick("moneyIn")}>
                Money In
              </SectionHeader>
              <SectionHeader onClick={() => handleSectionClick("withdrawHistory")}>
                Withdraw money history
              </SectionHeader>

              {/* Add more SectionHeaders here */}
            </Sidebar>
          )}
          <ToggleSidebarButton onClick={() => setSidebarVisible(!sidebarVisible)}>
            {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
          </ToggleSidebarButton>
  
          <MainContent>{renderSectionContent()}</MainContent>
        </ContentContainer>
      </PageContainer>
    );
  }
  
  export default ManageFinancial;
  const PageContainer = styled.div`
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 60px 20px 20px;
`;

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

const LeftNav = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  margin-left: 20px;
  display: flex;
  gap: 15px;

  a {
    color: white;
    text-decoration: none;
  }
`;

const NavIcons = styled.div`
  display: flex;
  gap: 15px;
`;

const ProfileWrapper = styled.div`
  position: relative;
`;

const ProfilePicture = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const DefaultProfile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #bdc3c7;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  display: flex;
  flex-direction: column;

  a {
    padding: 10px 15px;
    color: black;
    text-decoration: none;

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;

const ContentContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

const Sidebar = styled.div`
  width: 300px;
  background-color: #f4f4f4;
  padding: 20px;
  border-right: 1px solid #ddd;
`;

const SectionHeader = styled.h2`
  font-size: 18px;
  color: #2c3e50;
  cursor: pointer;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 20px;
`;

const Header = styled.div`
  margin-bottom: 20px;

  h1 {
    font-size: 24px;
  }

  p {
    color: #7f8c8d;
  }
`;

const ProfileDetails = styled.div`
  h3 {
    margin-top: 20px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 5px 0;
    }
  }
`;
  


const ToggleSidebarButton = styled.div`
    position: absolute; /* Ensure it's positioned relative to the nearest positioned ancestor */
    top: 0; /* Fix it at the top */
    left: 0; /* Optional: Adjust if you want it at the left edge */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 5px;
    background-color: #ddd;
`;

