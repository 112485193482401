import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setSenderId, setWithId } from '../reducers/reducers';
import { useNavigate } from 'react-router-dom';

function Chats1() {
  const [messages, setMessages] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    const getSendersInfo = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/chats1')
      setMessages(response.data)
      console.log('senders ==========>> > > > > > > > > >>>>   >>>>  >> ',response.data)
    }
    getSendersInfo();
  }, [])
  const handleNameClick = (withId) => {
    dispatch(setWithId(withId))
    navigate('/chat')

  }
  return (


    <div>
                    {messages.length > 0 ? (
                messages.map((message) => (
                  <div key={message._id}>
                    {message.senderName && (
                      <Button onClick={() => handleNameClick(message.withId)} variant="light" style={{ width: '100%' }}>
                        {message.senderName}
                      </Button>
                    )}
                  </div>
                ))
              ) : (
                <p>No messages received.</p>
              )}
      chats 1 :
        map through all the senders, get their name and id, dispatch the sender id
    </div>
  )
}

export default Chats1
