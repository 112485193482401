////////////////very very very gooooooooooooooooooooooooooooooooooooood
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setExamId, setLevelId, setLevelNumber, setWithId } from '../../../reducers/reducers';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { FaStar, FaLock, FaBell, FaComments, FaGraduationCap, FaCheck, FaChevronDown, FaChevronUp, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

function Levels() {
    const [levels, setLevels] = useState([]);
    const [activeTab, setActiveTab] = useState('levels');

    const [mates, setMates] = useState([])
    const [rate, setRate] = useState('');
    const [comment, setComment] = useState('');
    const [expandedLevel, setExpandedLevel] = useState(null);
    const [messages, setMessages] =useState('')
    const [isLevelCompeleted, setIsLevelCompleted] = useState(false); // get it from the backend
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const dispatch = useDispatch();   
    const navigate = useNavigate();
    const worldId = useSelector((state) => state.allow.worldId);
    const [loading, setLoading] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [profilePictureSrc, setProfilePictureSrc] = useState('');
    const [posts, setPosts] = useState([])
    const examId = uuidv4();

    const handleChooseAdvisor = () => navigate('/choose-advisor');
    const handleMessageAdvisor = () => navigate('/message-advisor');

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
    };


         useEffect(() => {
       const setLevelCompleted = async () => {
             const response = await axios.patch('https://sell-skill.com/api/endpoints/setLevelCompleted', {params:  {worldId }});
             setIsLevelCompleted(response.data);
             console.log('isLevelCompleted:', response.data);
         };

         setLevelCompleted();
     }, [worldId]);

    useEffect(() => {
        const fetchLevels = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://sell-skill.com/api/endpoints/getLevels', {
                    params: { worldId },
                });
                setLevels(response.data);
            } catch (error) {
                console.error('Error fetching levels:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLevels();
    }, [worldId]);

    const handleShowLevel = (levelNumber, id) => {
        dispatch(setLevelId(id));
        dispatch(setLevelNumber(levelNumber));
        navigate('/lessons');
    };

    const handleRateWorld = async () => {
        try {
            await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
        } catch (error) {
            console.error('Error rating world:', error);
        }
    };

    const addCommentToTheWorld = async () => {
        try {
            await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
                commentId: uuidv4(),
                comment,
                worldId,
            });
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };

         const handleStartExam = async (levelId) => {
        dispatch(setExamId(examId));
        await axios.post('https://sell-skill.com/api/endpoints/startExam', {
            levelId,
             worldId,
            examId,
        });
     };



     const [expandedSection, setExpandedSection] = useState(null);

     const toggleSection = (section) => {
       setExpandedSection(expandedSection === section ? null : section);
     };
   
     const toggleLevel = (levelId) => {
       setExpandedLevel(expandedLevel === levelId ? null : levelId);
     };

     useEffect(() => {
        const getMates = async() => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/getMates', {params: {worldId}})
            setMates(response.data)
            console.log('response.data from getMates ==============--------------------------==========::: --->>>>>> > > > ',response.data)
        }
        getMates();
     }, [worldId])


     useEffect(() => {
        const getSendersInfo = async() => {
          const response = await axios.get('https://sell-skill.com/api/endpoints/chats1')
          setMessages(response.data)
          console.log('senders ==========>> > > > > > > > > >>>>   >>>>  >> ',response.data)
        }
        getSendersInfo();
      }, [])

      const handleNameClick = (withId) => {
        dispatch(setWithId(withId))
        navigate('/chat')
    
      }


      useEffect(() => {
        const getPosts = async() => {
           const response =  await axios.get('https://sell-skill.com/api/endpoints/WgetPosts', {params: {worldId}})
           setPosts(response.data)
        }
        getPosts()

      }, [])



    return (
        <PageContainer>
            <Header>
                <BackButton onClick={() => navigate('/worlds')}>←</BackButton>


                <Navbar>
                <LeftNav>
                    <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
                    <NavLinks>
                        <a href="/my-learning">My Learning</a>
                        <a href="/my-earning">My Earning</a>
                        <a href="/my-cart">My Cart</a>
                        <a href="/certifications">Certifications</a>
                        <a href="/exams">Exams</a>
                    </NavLinks>
                </LeftNav>

                <NavIcons>
                    <FaBell onClick={() => navigate('/my-notifications')} title="Notifications" />
                    <FaComments onClick={() => navigate('/my-chats')} title="Messages" />
                    <ProfileWrapper
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                    >
                        <ProfilePicture
                            src={profilePictureSrc || '/images/default-profile.jpg'}
                            alt="Profile"
                        />
                        {showDropdown && (
                            <DropdownMenu>
                                <a href="/my-profile">My Profile</a>
                                <a href="/my-learning">My Learning</a>
                                <a href="/my-earning">My Earning</a>
                                <a href="/my-cart">My Cart</a>
                                <a href="/certifications">Certifications</a>
                                <a href="/exams">Exams</a>
                            </DropdownMenu>
                        )}
                    </ProfileWrapper>
                </NavIcons>
            </Navbar>


            </Header>
            <ContentContainer>
                <Sidebar>

                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div>
                        {/* My Progress Section */}





                        <div>
                        <SectionHeader onClick={() => navigate('/my-advisor-chat')}>

        message advisor
    </SectionHeader>

                        </div>

 


                        <div>
                        <SectionHeader onClick={() => toggleSection('progress')}>
        {expandedSection === 'progress' ? <FaChevronUp /> : <FaChevronDown />}
        My Progress
    </SectionHeader>
                          {expandedSection === 'progress' && (
                            <ProgressList>
                              {levels.map((level) => (
                                <ProgressItem key={level._id}>
                                  <LevelHeader onClick={() => toggleLevel(level._id)}>
                                    <LevelTitle>
                                      {level.isLevelCompleted ? <FaCheck color="green" /> : <FaLock color="red" />}
                                      {level.levelTitle}
                                    </LevelTitle>
                                    {expandedLevel === level._id ? <FaChevronUp /> : <FaChevronDown />}
                                  </LevelHeader>
                                  {expandedLevel === level._id && (
                                    <LessonList>
                                      {level.lessons.map((lesson) => (
                                        <LessonItem key={lesson._id}>
                                          {lesson.isLessonCompleted ? <FaCheck color="green" /> : <FaLock color="red" />}
                                          {lesson.lessonTitle}
                                          <button onClick={() => console.log('lesson from inside ==============================-------------------------------->>> > > > > > >  ',lesson)}>log lesson</button>
                                        </LessonItem>
                                      ))}
                                    </LessonList>
                                  )}
                                </ProgressItem>
                              ))}
                            </ProgressList>
                          )}
                        </div>
                  
                        {/* My Chats Section */}
                        <div>
                        <SectionHeader onClick={() => toggleSection('chats')}>
        {expandedSection === 'chats' ? <FaChevronUp /> : <FaChevronDown />}
        My Chats
    </SectionHeader>  
                          {expandedSection === 'chats' &&                     
                          messages.length > 0 && 
                messages.map((message) => (
                  <div key={message._id}>
                    {message.senderName && (
                      <Button onClick={() => handleNameClick(message.withId)} variant="light" style={{ width: '100%' }}>
                        {message.senderName}
                      </Button>
                    )}
                  </div>
                ))
               }
                        </div>



                        <div>
                        <SectionHeader onClick={() => navigate('/posts')}>

        explore posts
    </SectionHeader>

                        </div>

                        <div>
                        <SectionHeader onClick={() => toggleSection('savedPosts')}>
        {expandedSection === 'savedPosts' ? <FaChevronUp /> : <FaChevronDown />}
        saved posts
    </SectionHeader>  
                          {expandedSection === 'savedPosts' &&                     
                          messages.length > 0 && 
                messages.map((message) => (
                  <div key={message._id}>
                    {message.senderName && (
                      <Button onClick={() => handleNameClick(message.withId)} variant="light" style={{ width: '100%' }}>
                        {message.senderName}
                      </Button>
                    )}
                  </div>
                ))
               }
                        </div>

                        <div>
                        <SectionHeader onClick={() => toggleSection('myPosts')}>
        {expandedSection === 'myPosts' ? <FaChevronUp /> : <FaChevronDown />}
        My Posts
    </SectionHeader>  
                          {expandedSection === 'myPosts' &&                     
                          messages.length > 0 && 
                messages.map((message) => (
                  <div key={message._id}>
                    {message.senderName && (
                      <Button onClick={() => handleNameClick(message.withId)} variant="light" style={{ width: '100%' }}>
                        {message.senderName}
                      </Button>
                    )}
                  </div>
                ))
               }
                        </div>

                        <div>
                        <SectionHeader onClick={() => toggleSection('mates')}>
        {expandedSection === 'mates' ? <FaChevronUp /> : <FaChevronDown />}
        My mates
    </SectionHeader>
                          {expandedSection === 'mates' && (
                            mates.map(
                                (mate) => (
                                    <div> 
                                        <Button>{mate.studentName}</Button>
                                        
                                    </div>
                                )
                            )
                          )}
                        </div>





                      </div>
                    )}
                </Sidebar>

                <ToggleSidebarButton onClick={toggleSidebar}>
         {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
     </ToggleSidebarButton>
                <MainContent>










                <Header>
    <TabContainer>
        <Tab
            active={activeTab === 'levels'}
            onClick={() => setActiveTab('levels')}
        >
            Explore Worlds
        </Tab>
        <Tab
            active={activeTab === 'posts'}
            onClick={() => setActiveTab('posts')}
        >
            Explore Mates
        </Tab>
    </TabContainer>

</Header>

{activeTab === 'levels' ? (
    <LevelsGrid>
        {levels.map((level) => (
            <LevelCard key={level._id} isOpen={level.isOpen}>
                <h2>{level.levelTitle}</h2>
                <p>{level.description}</p>
                {level.toBeAnAdvisorLevel && (
                    <FaGraduationCap size={50} color="#2c3e50" style={{ marginBottom: '10px' }} />
                )}
                <Actions>
                    <Button
                        onClick={() => handleShowLevel(level.levelNumber, level._id)}
                        disabled={!level.isOpen}
                    >
                        Get In
                    </Button>
                    <Button
                        onClick={() => navigate('/level-description')}
                        disabled={!level.isOpen}
                    >
                        View Description
                    </Button>
                    <Button onClick={() => handleChooseAdvisor()} disabled={!level.isOpen}>
                        Add Advisor
                    </Button>
                    <Button onClick={() => handleStartExam(level._id)} disabled={!level.isLevelCompleted}>
                        Take Exam
                    </Button>
                </Actions>
            </LevelCard>
        ))}
    </LevelsGrid>
) : (
    <PostsContainer>
        <div>
            <h2>Explore Your Mates' Posts</h2>
            <UploadButton onClick={() => navigate('/upload-post-for-world')}>
                Upload a Post
            </UploadButton>
        </div>
        <PostsGrid>
            {posts.map((post) => (
                <PostCard key={post._id}>
                    <Thumbnail src={post.thumbnail || '/images/default-thumbnail.jpg'} alt="Post Thumbnail" />
                    <PostInfo>
                        <h3>{post.title}</h3>
                        <Publisher>
                            <img
                                src={post.poster?.picture?.picture?.contentType
                                    ? `data:${post.poster.picture.contentType};base64,${Buffer.from(
                                        post.poster.picture.data
                                    ).toString('base64')}`
                                    : '/images/default-profile.jpg'}
                                alt="Poster"
                            />
                            <p>{post.poster?.name || 'Unknown'}</p>
                        </Publisher>
                        <Actions>
                            <GreenButton onClick={() => console.log('View Post', post._id)}>View Post</GreenButton>
                            <NavColorButton onClick={() => console.log('Like Post', post._id)}>Like</NavColorButton>
                        </Actions>
                    </PostInfo>
                </PostCard>
            ))}
        </PostsGrid>
    </PostsContainer>
)}

                    <FeedbackSection>
                        <h2>Rate and Comment on the World</h2>
                        <RatingContainer>
                            {[1, 2, 3, 4, 5].map((star) => (
                                <FaStar
                                    key={star}
                                    size={24}
                                    color={star <= rate ? "#f1c40f" : "#bdc3c7"}
                                    onClick={() => setRate(star)}
                                    style={{ cursor: "pointer" }}
                                />
                            ))}
                        </RatingContainer>
                        <button onClick={handleRateWorld}>Submit Rating</button>
                        <textarea
                            placeholder="Enter your comment"
                            onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                        <button onClick={addCommentToTheWorld}>Add Comment</button>
                    </FeedbackSection>
                </MainContent>
            </ContentContainer>
        </PageContainer>
    );
}


const ProgressList = ({ children }) => <div>{children}</div>;

const LevelHeader = ({ children, onClick }) => (
  <div onClick={onClick} style={{ cursor: 'pointer' }}>
    {children}
  </div>
);
const LevelTitle = ({ children }) => <div>{children}</div>;
const LessonList = ({ children }) => <div>{children}</div>;
const LessonItem = ({ children }) => <div>{children}</div>;

const ChatList = ({ children }) => <div>{children}</div>;
const ChatItem = ({ children }) => <div>{children}</div>;



export default Levels;









const RatingContainer = styled.div`
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
`;

























const LevelsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;



const BackButton = styled.button`
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #2c3e50;
    padding: 5px 10px; /* Adds space around the icon */
`;

const ProgressItem = styled.div`
    padding: 10px 15px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        font-size: 1.2em;
        color: #2980b9;
        background-color: #f0f8ff;
    }
`;

const LevelCard = styled.div`
    background-color: ${(props) => (props.isOpen ? 'white' : '#f0f0f0')};
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease; /* Adds hover effect */

    &:hover {
        transform: translateY(-5px); /* Slight lift on hover */
    }
`;

const FeedbackSection = styled.div`
    margin-top: 30px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    h2 {
        margin-bottom: 20px;
        font-size: 24px;
        color: #2c3e50;
    }

    textarea {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        resize: vertical;
    }

    button {
        background-color: #27ae60;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #1e8449;
        }
    }
`;




































const PageContainer = styled.div`
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 60px 20px 20px; /* Add top padding to account for the navbar height */
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ContentContainer = styled.div`
    display: flex;
    position: relative; /* Allows absolute positioning of the toggle button */
    width: 100%;
    min-height: calc(100vh - 60px); /* Adjust to ensure it spans the full viewport height minus the navbar */
`;

const Sidebar = styled.div`
    width: 300px;
    position: fixed; /* Fixes it to the left side */
    top: 60px; /* Matches the height of the navbar */
    left: 0;
    bottom: 0; /* Ensures it spans the full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Ensures content is scrollable */
`;


const Navbar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2c3e50;
    color: white;
    position: fixed; /* Keep the navbar fixed at the top */
    top: 0;
    left: 0;
    width: 100%; /* Make it span the entire width */
    z-index: 1000; /* Ensure it stays above other elements */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
`;


const LeftNav = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.div`
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
`;

const NavLinks = styled.div`
    margin-left: 20px;
    display: flex;
    gap: 15px;

    a {
        color: white;
        text-decoration: none;
    }
`;

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
    }
`;

const NavIcons = styled.div`
    display: flex;
    gap: 15px;
`;

const ProfileWrapper = styled.div`
    position: relative;
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;

const SectionHeader = styled.h2`
    font-size: 18px;
    color: #2c3e50;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
        font-size: 20px;
        color: #2980b9;
    }
`;

const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
`;

const WorldsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;

const WorldCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-10px);
    }
`;

const Publisher = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    p {
        font-size: 14px;
        color: #2c3e50;
        margin: 0;
    }
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
`;

const Info = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;

const Rating = styled.div`
    display: flex;
`;
const Actions = styled.div`
    display: flex;
    flex-direction: column; /* Stack ActionRow elements vertically */
    gap: 10px; /* Add spacing between rows */
`;




const MainContent = styled.div`
    margin-left: 300px; /* Ensures content starts after the fixed sidebar */
    flex-grow: 1; /* Ensures MainContent takes remaining space */
    padding: 20px;
`;



const GreenButton = styled.button`
    background-color: #27ae60 !important; /* Green color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #219150 !important; /* Darker green on hover */
    }
`;

const NavColorButton = styled.button`
    background-color: #2c3e50 !important; /* Navbar color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #1a252f !important; /* Darker navbar color on hover */
    }
`;

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between rows */
`;




const ToggleSidebarButton = styled.div`
    position: fixed; /* Ensures it's positioned relative to the viewport */
    top: 100px; /* Adjust to set vertical placement */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement based on sidebar visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    background-color: #ddd;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: left 0.3s ease; /* Smooth transition when toggling */
`;










const Tab = styled.div`
    flex: 1;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    color: ${({ active }) => (active ? '#2c3e50' : '#7f8c8d')};
    border-bottom: ${({ active }) => (active ? '3px solid #27ae60' : 'none')};
    transition: all 0.3s ease-in-out;

    &:hover {
        color: #27ae60;
    }
`;

const PostsContainer = styled.div`
    text-align: center;
    color: #2c3e50;
    margin-top: 20px;
`;


const TabContainer = styled.div`
    position: fixed;
    top: 60px; /* Set below the navbar */
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    background-color: #f8f9fa; /* Light gray for a clean look */
    border-bottom: 2px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 950; /* Below the navbar */
    padding: 10px 0;
`;









const PostsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;

const PostCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-10px);
    }
`;

const PostInfo = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;

// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import styled, { keyframes } from 'styled-components';
// import { useDispatch, useSelector } from 'react-redux';
// import { setExamId, setLevelId, setLevelNumber, setWorldName } from '../../../reducers/reducers';
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import { FaStar, FaLock, FaBell, FaComments, FaGraduationCap, FaCheck, FaChevronDown, FaChevronUp } from 'react-icons/fa';

// function Levels() {
//     const [levels, setLevels] = useState([]);
//     const [rate, setRate] = useState('');
//     const [comment, setComment] = useState('');
//     const [expandedLevel, setExpandedLevel] = useState(null);
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const worldId = useSelector((state) => state.allow.worldId);
//     const worldName = useSelector((state) => state.allow.worldName);
//     const [isLevelCompeleted, setIsLevelCompleted] = useState(false); // get it from the backend
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [profilePictureSrc, setProfilePictureSrc] = useState('');
//     const [loading, setLoading] = useState(false);

//     const examId = uuidv4();

//     const handleChooseAdvisor = () => {
//         navigate('/choose-advisor'); // Navigate to the advisor selection page
//     };

//     const handleMessageAdvisor = () => {
//         navigate('/message-advisor');
//     };

//     useEffect(() => {
//         const setLevelCompleted = async () => {
//             const response = await axios.patch('https://sell-skill.com/api/endpoints/setLevelCompleted', { worldId });
//             setIsLevelCompleted(response.data);
//             console.log('isLevelCompleted:', response.data);
//         };

//         setLevelCompleted();
//     }, [worldId]);

//     useEffect(() => {
//         const fetchLevels = async () => {
//             setLoading(true);
//             try {
//                 const response = await axios.get('https://sell-skill.com/api/endpoints/getLevels', {
//                     params: { worldId },
//                 });
//                 setLevels(response.data);
//             } catch (error) {
//                 console.error('Error fetching levels:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchLevels();
//     }, [worldId]);

//     const handleShowLevel = (levelNumber, id) => {
//         dispatch(setLevelId(id));
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/lessons');
//     };

//     const handleLevelShowDescription = (levelNumber) => {
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/level-description');
//     };

//     const handleRateWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
//         } catch (error) {
//             console.error('Error rating world:', error);
//         }
//     };

//     const addCommentToTheWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
//                 commentId: uuidv4(),
//                 comment,
//                 worldId,
//             });
//         } catch (error) {
//             console.error('Error adding comment:', error);
//         }
//     };

//     const handleStartExam = async (levelId) => {
//         dispatch(setExamId(examId));
//         await axios.post('https://sell-skill.com/api/endpoints/startExam', {
//             levelId,
//             worldId,
//             examId,
//         });
//     };

//     const toggleLevel = (levelId) => {
//         setExpandedLevel(expandedLevel === levelId ? null : levelId);
//     };

//     return (
//         <PageContainer>
//             <button onClick={() => navigate('/worlds')}>Go Back</button>
//             <Navbar>
//                 <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                 <NavLinks>
//                     <a href="/my-learning">Message Advisor</a>
//                     <a href="/my-earning">My Students</a>
//                     <a href="/my-cart">Posts</a>
//                     <a href="/certifications">Certifications</a>
//                     <a href="/exams">Exams</a>
//                 </NavLinks>

//                 <NavIcons>
//                     <FaBell onClick={() => navigate('/my-notifications')} title="Notifications" />
//                     <FaComments onClick={() => navigate('/my-chats')} title="Messages" />
//                     <ProfileWrapper
//                         onMouseEnter={() => setShowDropdown(true)}
//                         onMouseLeave={() => setShowDropdown(false)}
//                     >
//                         <ProfilePicture
//                             src={profilePictureSrc || '/images/default-profile.jpg'}
//                             alt="Profile"
//                         />
//                         {showDropdown && (
//                             <DropdownMenu>
//                                 <a href="/my-profile">My Profile</a>
//                                 <a href="/my-learning">My Learning</a>
//                                 <a href="/my-earning">My Earning</a>
//                                 <a href="/my-cart">My Cart</a>
//                                 <a href="/certifications">Certifications</a>
//                                 <a href="/exams">Exams</a>
//                             </DropdownMenu>
//                         )}
//                     </ProfileWrapper>
//                 </NavIcons>
//             </Navbar>

//             <MainContent>
//                 <Sidebar>
//                     <h2>My Progress</h2>
//                     {loading ? (
//                         <p>Loading...</p>
//                     ) : (
//                         <ProgressList>
//                             {levels.map((level) => (
//                                 <ProgressItem key={level._id}>
//                                     <LevelHeader onClick={() => toggleLevel(level._id)}>
//                                         <LevelTitle>
//                                             {level.isLevelCompleted ? <FaCheck color="green" /> : <FaLock color="red" />}
//                                             {level.levelTitle}
//                                         </LevelTitle>
//                                         {expandedLevel === level._id ? <FaChevronUp /> : <FaChevronDown />}
//                                     </LevelHeader>
//                                     {expandedLevel === level._id && (
//                                         <LessonList>
//                                             {level.lessons.map((lesson) => (
//                                                 <LessonItem key={lesson._id}>
//                                                     {lesson.isLessonCompleted ? (
//                                                         <FaCheck color="green" />
//                                                     ) : (
//                                                         <FaLock color="red" />
//                                                     )}
//                                                     {lesson.lessonTitle}
//                                                 </LessonItem>
//                                             ))}
//                                         </LessonList>
//                                     )}
//                                 </ProgressItem>
//                             ))}
//                         </ProgressList>
//                     )}
//                 </Sidebar>
//                 <LevelsGrid>
//                     {levels.map((level) => (
//                         <LevelCard key={level._id} isOpen={level.isOpen}>
//                             <h2>{level.levelTitle}</h2>
//                             <p>{level.description}</p>
//                             <Actions>
//                                 <button
//                                     onClick={() => handleShowLevel(level.levelNumber, level._id)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     Get In
//                                 </button>
//                                 <button
//                                     onClick={() => handleLevelShowDescription(level.levelNumber)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     View Description
//                                 </button>
//                                 <button
//                                     onClick={() => handleStartExam(level._id)}
//                                     disabled={!level.isLevelCompleted}
//                                 >
//                                     Take Exam
//                                 </button>
//                             </Actions>
//                         </LevelCard>
//                     ))}
//                 </LevelsGrid>
//             </MainContent>
//         </PageContainer>
//     );
// }

// export default Levels;

// // Styled Components
// const PageContainer = styled.div`
//     display: flex;
//     flex-direction: column;
// `;

// const Navbar = styled.nav`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px 20px;
//     background-color: #2c3e50;
//     color: white;
// `;

// const Logo = styled.div`
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
// `;

// const NavLinks = styled.div`
//     display: flex;
//     gap: 15px;

//     a {
//         color: white;
//         text-decoration: none;

//         &:hover {
//             text-decoration: underline;
//         }
//     }
// `;

// const NavIcons = styled.div`
//     display: flex;
//     gap: 15px;
// `;

// const ProfileWrapper = styled.div`
//     position: relative;
// `;

// const ProfilePicture = styled.img`
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
// `;

// const DropdownMenu = styled.div`
//     position: absolute;
//     top: 50px;
//     right: 0;
//     background-color: white;
//     border: 1px solid #ccc;
//     border-radius: 5px;
//     width: 200px;
// `;

// const Sidebar = styled.div`
//     width: 300px;
//     background-color: #f4f4f4;
//     padding: 20px;
//     border-right: 1px solid #ddd;
// `;

// const ProgressList = styled.ul`
//     list-style: none;
//     padding: 0;
// `;

// const ProgressItem = styled.li`
//     margin-bottom: 10px;
// `;

// const LevelHeader = styled.div`
//     display: flex;
//     justify-content: space-between;
//     cursor: pointer;
//     padding: 10px;
//     background-color: #ddd;
//     border-radius: 5px;
// `;

// const LevelTitle = styled.span`
//     display: flex;
//     align-items: center;
//     gap: 10px;
// `;

// const LessonList = styled.ul`
//     list-style: none;
//     padding-left: 20px;
// `;

// const LessonItem = styled.li`
//     display: flex;
//     align-items: center;
//     gap: 10px;
//     padding: 5px 0;
// `;

// const LevelsGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
// `;

// const LevelCard = styled.div`
//     background-color: ${(props) => (props.isOpen ? 'white' : '#f0f0f0')};
//     border-radius: 10px;
//     padding: 20px;
// `;

// const Actions = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 10px;

//     button {
//         background-color: #2980b9;
//         color: white;
//         border: none;
//         padding: 10px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1f618d;
//         }

//         &:disabled {
//             background-color: #bdc3c7;
//             cursor: not-allowed;
//         }
//     }
// `;

// const MainContent = styled.div`
//     flex: 1;
//     padding: 20px;
// `;

































// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import styled, { keyframes } from 'styled-components';
// import { useDispatch, useSelector } from 'react-redux';
// import { setExamId, setLevelId, setLevelNumber, setWorldName } from '../../../reducers/reducers';
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import { FaStar, FaLock, FaBell, FaComments, FaGraduationCap } from 'react-icons/fa';

// function Levels() {
//     const [levels, setLevels] = useState([]);
//     const [rate, setRate] = useState('');
//     const [comment, setComment] = useState('');
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const worldId = useSelector((state) => state.allow.worldId);
//     const worldName = useSelector((state) => state.allow.worldName);
//     const [isLevelCompeleted, setIsLevelCompleted] =useState(false)//get it from the back
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [profilePictureSrc, setProfilePictureSrc] = useState('');
//     const [loading, setLoading]  = useState(false)

//     const examId = uuidv4();
//     const handleChooseAdvisor = () => {
//         navigate('/choose-advisor') // he will get all the advisors and he can select to choose
//     }
//     const handleMessageAdvisor = () => {
//         navigate('/message-advisor')
//     }

//  useEffect(() => {

//      const setLevelCompleted = async () => { 

//          const response = await axios.patch('https://sell-skill.com/api/endpoints/setLevelCompleted', {
//            worldId
//          });

//            setIsLevelCompleted(response.data);
//            console.log('isLevelCompleted coming from back ============--------------=========--------->> >>  > ', response.data);
        

//      };
  
//      setLevelCompleted();
  

//    }, []);


//    useEffect(() => {
//     const fetchLevels = async () => {
//         setLoading(true); // Start loading
//         try {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/getLevels', {
//                 params: { worldId },
//             });
//             setLevels(response.data);
//         } catch (error) {
//             console.error('Error fetching levels:', error);
//         } finally {
//             setLoading(false); // Stop loading
//         }
//     };

//     fetchLevels();
// }, [worldId]);

//     const handleShowLevel = (levelNumber, id) => {
//         dispatch(setLevelId(id));
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/lessons');
//     };

//     const handleLevelShowDescription = (levelNumber) => {
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/level-description');
//     };

//     const handleRateWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
//         } catch (error) {
//             console.error('Error rating world:', error);
//         }
//     };

//     const addCommentToTheWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
//                 commentId: uuidv4(),
//                 comment,
//                 worldId,
//             });
//         } catch (error) {
//             console.error('Error adding comment:', error);
//         }
//     };


//       const handleStartExam = async(levelId) => {
//    dispatch(setExamId(examId))
//     await axios.post('https://sell-skill.com/api/endpoints/startExam', {
//          levelId,
//          worldId,//useSelector
//          examId //uuidv4
//      })
//  }
//     return (
//         <PageContainer>
//             <button onClick={() => navigate('/worlds')}> go back </button>
//             <Navbar>
//                 <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                 <NavLinks>
//                     <a>my mates</a>
//                     <a href="/my-learning">message advisor</a>
//                     <a href="/my-earning">My students</a>
//                     <a href="/my-cart">posts</a>
//                     <a href="/certifications">Certifications</a>
//                     <a href="/exams">Exams</a>
//                 </NavLinks>

//                 <NavIcons>
//                     <FaBell onClick={() => navigate('/my-notifications')} title="Notifications" />
//                     <FaComments onClick={() => navigate('/my-chats')} title="Messages" />
//                     <ProfileWrapper
//                         onMouseEnter={() => setShowDropdown(true)}
//                         onMouseLeave={() => setShowDropdown(false)}
//                     >
//                         <ProfilePicture
//                             src={profilePictureSrc || '/images/default-profile.jpg'}
//                             alt="Profile"
//                         />
//                         {showDropdown && (
//                             <DropdownMenu>
//                                 <a href="/my-profile">My Profile</a>
//                                 <a href="/my-learning">My Learning</a>
//                                 <a href="/my-earning">My Earning</a>
//                                 <a href="/my-cart">My Cart</a>
//                                 <a href="/certifications">Certifications</a>
//                                 <a href="/exams">Exams</a>
//                             </DropdownMenu>
//                         )}
//                     </ProfileWrapper>
//                 </NavIcons>
//             </Navbar>
// {loading ? ( <LoadingContainer>
//                     <LoadingSpinner />
//                 </LoadingContainer>):(            <Content>
//                 <LevelsGrid>
//                     {levels.map((level, index) => (
//                         <LevelCard key={index} isOpen={level.isOpen}>
//                             <h2>{level.levelTitle}</h2>
//                             <p>{level.description}</p>
//                             {level.toBeAnAdvisorLevel && (
//     <FaGraduationCap size={50} color="#2c3e50" style={{ marginBottom: '10px' }} />
// )}
//                             {!level.isOpen && (
//                                 <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//                                     <FaLock size={20} color="#e74c3c" />
//                                     <span>Locked</span>
//                                 </div>
//                             )}
//                             <Actions>
//                                 <button
//                                     onClick={() => handleShowLevel(level.levelNumber, level._id)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     Get In
//                                 </button>
//                                 <button
//                                     onClick={() => handleLevelShowDescription(level.levelNumber)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     View Description
//                                 </button>
//                                 {
//                                     level.myCurrentAdvisorId ?                                  
//                                     <button onClick={handleMessageAdvisor} disabled={level.isPassed || !level.isOpen}> 
//                                     message advisor
//                                    </button> :                          
//                                     <button onClick={handleChooseAdvisor} disabled={!level.isOpen}>
//                                   add advisor
//                                  </button> 
//                                 }


//                                 <button
//                                     onClick={() => handleStartExam( level._id)}
//                                     disabled={!level.isLevelCompleted }
//                                 >
//                                     Take Exam
//                                 </button>
//                             </Actions>
//                         </LevelCard>
//                     ))}
//                 </LevelsGrid>
//                 <FeedbackSection>
//                     <h2>Rate and Comment on the World</h2>
//                     <RatingContainer>
//     {[1, 2, 3, 4, 5].map((star) => (
//         <FaStar
//             key={star}
//             size={24}
//             color={star <= rate ? "#f1c40f" : "#bdc3c7"}
//             onClick={() => setRate(star)}
//             style={{ cursor: "pointer" }}
//         />
//     ))}
// </RatingContainer>
// <button onClick={handleRateWorld}>Submit Rating</button>

//                     <textarea
//                         placeholder="Enter your comment"
//                         onChange={(e) => setComment(e.target.value)}
//                     ></textarea>
//                     <button onClick={addCommentToTheWorld}>Add Comment</button>
//                 </FeedbackSection>
//             </Content>)}
//         </PageContainer>
//     );
// }

// export default Levels;

// // Styled Components
// const PageContainer = styled.div`
//     background-color: #f8f9fa;
//     min-height: 100vh;
//     padding: 20px;
// `;

// const Navbar = styled.nav`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px 20px;
//     background-color: #2c3e50;
//     color: white;
// `;

// const Logo = styled.div`
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
// `;

// const NavLinks = styled.div`
//     display: flex;
//     gap: 15px;

//     a {
//         color: white;
//         text-decoration: none;

//         &:hover {
//             text-decoration: underline;
//         }
//     }
// `;

// const Content = styled.div`
//     max-width: 1200px;
//     margin: 0 auto;
//     padding: 20px;

//     h1 {
//         font-size: 32px;
//         margin-bottom: 20px;
//         color: #2c3e50;
//     }
// `;

// const LevelsGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
// `;

// const LevelCard = styled.div`
//     background-color: ${(props) => (props.isOpen ? 'white' : '#f0f0f0')};
//     border-radius: 10px;
//     padding: 20px;
//     box-shadow: ${(props) => (props.isOpen ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none')};
//     transition: transform 0.3s ease;
//     opacity: ${(props) => (props.isOpen ? '1' : '0.6')};

//     &:hover {
//         transform: ${(props) => (props.isOpen ? 'translateY(-10px)' : 'none')};
//     }

//     h2 {
//         font-size: 20px;
//         margin-bottom: 10px;
//         color: ${(props) => (props.isOpen ? '#2c3e50' : '#7f8c8d')};
//     }

//     p {
//         font-size: 14px;
//         color: #7f8c8d;
//         margin-bottom: 10px;
//     }
// `;

// const Actions = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 10px;

//     button {
//         background-color: #2980b9;
//         color: white;
//         border: none;
//         padding: 10px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1f618d;
//         }

//         &:disabled {
//             background-color: #bdc3c7;
//             cursor: not-allowed;
//         }
//     }
// `;

// const FeedbackSection = styled.div`
//     margin-top: 30px;

//     h2 {
//         margin-bottom: 20px;
//         font-size: 24px;
//         color: #2c3e50;
//     }

//     input,
//     textarea {
//         display: block;
//         width: 100%;
//         margin-bottom: 10px;
//         padding: 10px;
//         border: 1px solid #ccc;
//         border-radius: 5px;
//     }

//     button {
//         background-color: #27ae60;
//         color: white;
//         border: none;
//         padding: 10px 20px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1e8449;
//         }
//     }
// `;
// const RatingContainer = styled.div`
//     display: flex;
//     gap: 10px;
//     margin-bottom: 10px;
// `;



// const DropdownMenu = styled.div`
//     position: absolute;
//     top: 50px;
//     right: 0;
//     background-color: white;
//     border: 1px solid #ccc;
//     border-radius: 5px;
//     box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//     width: 200px;
//     z-index: 10;
//     display: flex;
//     flex-direction: column;

//     a {
//         padding: 10px 15px;
//         color: black;
//         text-decoration: none;

//         &:hover {
//             background-color: #f0f0f0;
//         }
//     }
// `;




// const ProfileWrapper = styled.div`
//     position: relative;
// `;

// const ProfilePicture = styled.img`
//     width: 40px;
//     height: 40px;
//     border-radius: 50%;
// `;

// const NavIcons = styled.div`
//     display: flex;
//     gap: 15px;
// `;




// const spin = keyframes`
//     0% { transform: rotate(0deg); }
//     100% { transform: rotate(360deg); }
// `;


// const LoadingSpinner = styled.div`
//     border: 6px solid #f3f3f3; /* Light grey border */
//     border-top: 6px solid #3498db; /* Blue color for the top border */
//     border-radius: 50%; /* Makes it circular */
//     width: 50px; /* Width of the spinner */
//     height: 50px; /* Height of the spinner */
//     animation: ${spin} 1s linear infinite; /* Applies the spinning animation */
// `;
// const LoadingContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     min-height: 300px; /* Ensures enough space for the spinner */
// `;










// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import { useDispatch, useSelector } from 'react-redux';
// import { setExamId, setLevelId, setLevelNumber, setWorldName } from '../../../reducers/reducers';
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import { FaStar, FaLock } from 'react-icons/fa';
  
// function Levels() {
//     const [levels, setLevels] = useState([]);
//     const [rate, setRate] = useState('');
//     const [comment, setComment] = useState('');
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const worldId = useSelector((state) => state.allow.worldId);
//     const worldName = useSelector((state) => state.allow.worldName);
//     const [isLevelCompeleted, setIsLevelCompleted] =useState(false)//get it from the back
//     const examId = uuidv4();
//     const handleChooseAdvisor = () => {
//         navigate('/choose-advisor') // he will get all the advisors and he can select to choose
//     }
//     const handleMessageAdvisor = () => {
//         navigate('/message-advisor')
//     }

//  useEffect(() => {

//      const setLevelCompleted = async () => { 

//          const response = await axios.patch('https://sell-skill.com/api/endpoints/setLevelCompleted', {
//            worldId
//          });

//            setIsLevelCompleted(response.data);
//            console.log('isLevelCompleted coming from back ============--------------=========--------->> >>  > ', response.data);
        

//      };
  
//      setLevelCompleted();
  

//    }, []);


//     useEffect(() => {
//         const fetchLevels = async () => {
//             try {
//                 const response = await axios.get(`https://sell-skill.com/api/endpoints/getLevels`, {
//                     params: { worldId },
//                 });
//                 setLevels(response.data);
//             } catch (error) {
//                 console.error('Error fetching levels:', error);
//             }
//         };

//         fetchLevels();
//     }, [worldId]);

//     const handleShowLevel = (levelNumber, id) => {
//         dispatch(setLevelId(id));
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/lessons');
//     };

//     const handleLevelShowDescription = (levelNumber) => {
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/level-description');
//     };

//     const handleRateWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
//         } catch (error) {
//             console.error('Error rating world:', error);
//         }
//     };

//     const addCommentToTheWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
//                 commentId: uuidv4(),
//                 comment,
//                 worldId,
//             });
//         } catch (error) {
//             console.error('Error adding comment:', error);
//         }
//     };


//       const handleStartExam = async(levelId) => {
//    dispatch(setExamId(examId))
//     await axios.post('https://sell-skill.com/api/endpoints/startExam', {
//          levelId,
//          worldId,//useSelector
//          examId //uuidv4
//      })
//  }
//     return (
//         <PageContainer>
//             <Navbar>
//                 <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                 <NavLinks>
//                     <a href="/my-learning">message advisor</a>
//                     <a href="/my-earning">My students</a>
//                     <a href="/my-cart">posts</a>
//                     <a href="/certifications">Certifications</a>
//                     <a href="/exams">Exams</a>
//                 </NavLinks>
//             </Navbar>
//             <Content>
//                 <LevelsGrid>
//                     {levels.map((level, index) => (
//                         <LevelCard key={index} isOpen={level.isOpen}>
//                             <h2>{level.levelTitle}</h2>
//                             <p>{level.description}</p>
//                             <p>Advisor Level: {level.toBeAnAdvisorLevel ? 'Yes' : 'No'}</p>
//                             {!level.isOpen && (
//                                 <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//                                     <FaLock size={20} color="#e74c3c" />
//                                     <span>Locked</span>
//                                 </div>
//                             )}
//                             <Actions>
//                                 <button
//                                     onClick={() => handleShowLevel(level.levelNumber, level._id)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     Get In
//                                 </button>
//                                 <button
//                                     onClick={() => handleLevelShowDescription(level.levelNumber)}
//                                     disabled={!level.isOpen}
//                                 >
//                                     View Description
//                                 </button>
//                                 {
//                                     level.myCurrentAdvisorId ?                                  
//                                     <button onClick={handleMessageAdvisor}> 
//                                     message advisor
//                                    </button> :                          
//                                     <button onClick={handleChooseAdvisor}>
//                                   add advisor
//                                  </button> 
//                                 }


//                                 <button
//                                     onClick={() => handleStartExam( level._id)}
//                                     disabled={!level.isLevelCompleted }
//                                 >
//                                     Take Exam
//                                 </button>
//                             </Actions>
//                         </LevelCard>
//                     ))}
//                 </LevelsGrid>
//                 <FeedbackSection>
//                     <h2>Rate and Comment on the World</h2>
//                     <input
//                         type="number"
//                         placeholder="Enter your rating (1-5)"
//                         onChange={(e) => setRate(e.target.value)}
//                     />
//                     <button onClick={handleRateWorld}>Submit Rating</button>
//                     <textarea
//                         placeholder="Enter your comment"
//                         onChange={(e) => setComment(e.target.value)}
//                     ></textarea>
//                     <button onClick={addCommentToTheWorld}>Add Comment</button>
//                 </FeedbackSection>
//             </Content>
//         </PageContainer>
//     );
// }

// export default Levels;

// // Styled Components
// const PageContainer = styled.div`
//     background-color: #f8f9fa;
//     min-height: 100vh;
//     padding: 20px;
// `;

// const Navbar = styled.nav`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px 20px;
//     background-color: #2c3e50;
//     color: white;
// `;

// const Logo = styled.div`
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
// `;

// const NavLinks = styled.div`
//     display: flex;
//     gap: 15px;

//     a {
//         color: white;
//         text-decoration: none;

//         &:hover {
//             text-decoration: underline;
//         }
//     }
// `;

// const Content = styled.div`
//     max-width: 1200px;
//     margin: 0 auto;
//     padding: 20px;

//     h1 {
//         font-size: 32px;
//         margin-bottom: 20px;
//         color: #2c3e50;
//     }
// `;

// const LevelsGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
// `;

// const LevelCard = styled.div`
//     background-color: ${(props) => (props.isOpen ? 'white' : '#f0f0f0')};
//     border-radius: 10px;
//     padding: 20px;
//     box-shadow: ${(props) => (props.isOpen ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none')};
//     transition: transform 0.3s ease;
//     opacity: ${(props) => (props.isOpen ? '1' : '0.6')};

//     &:hover {
//         transform: ${(props) => (props.isOpen ? 'translateY(-10px)' : 'none')};
//     }

//     h2 {
//         font-size: 20px;
//         margin-bottom: 10px;
//         color: ${(props) => (props.isOpen ? '#2c3e50' : '#7f8c8d')};
//     }

//     p {
//         font-size: 14px;
//         color: #7f8c8d;
//         margin-bottom: 10px;
//     }
// `;

// const Actions = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 10px;

//     button {
//         background-color: #2980b9;
//         color: white;
//         border: none;
//         padding: 10px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1f618d;
//         }

//         &:disabled {
//             background-color: #bdc3c7;
//             cursor: not-allowed;
//         }
//     }
// `;

// const FeedbackSection = styled.div`
//     margin-top: 30px;

//     h2 {
//         margin-bottom: 20px;
//         font-size: 24px;
//         color: #2c3e50;
//     }

//     input,
//     textarea {
//         display: block;
//         width: 100%;
//         margin-bottom: 10px;
//         padding: 10px;
//         border: 1px solid #ccc;
//         border-radius: 5px;
//     }

//     button {
//         background-color: #27ae60;
//         color: white;
//         border: none;
//         padding: 10px 20px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1e8449;
//         }
//     }
// `;












// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import styled from 'styled-components';
// import { useDispatch, useSelector } from 'react-redux';
// import { setExamId, setLevelId, setLevelNumber, setWorldName } from '../../../reducers/reducers';
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import { FaStar } from 'react-icons/fa';

// function Levels() {
//     const [levels, setLevels] = useState([]);
//     const [rate, setRate] = useState('');
//     const [comment, setComment] = useState('');
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const worldId = useSelector((state) => state.allow.worldId);
//     const worldName = useSelector((state) => state.allow.worldName);

//     const examId = uuidv4();

//     useEffect(() => {
//         const fetchLevels = async () => {
//             try {
//                 const response = await axios.get(`https://sell-skill.com/api/endpoints/getLevels`, {
//                     params: { worldId },
//                 });
//                 setLevels(response.data);
//             } catch (error) {
//                 console.error('Error fetching levels:', error);
//             }
//         };

//         fetchLevels();
//     }, [worldId]);

//     const handleShowLevel = (levelNumber, id) => {
//         dispatch(setLevelId(id));
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/lessons');
//     };

//     const handleLevelShowDescription = (levelNumber) => {
//         dispatch(setLevelNumber(levelNumber));
//         navigate('/level-description');
//     };

//     const handleRateWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/rateWorld', { rateId: uuidv4(), rate, worldId });
//         } catch (error) {
//             console.error('Error rating world:', error);
//         }
//     };

//     const addCommentToTheWorld = async () => {
//         try {
//             await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {
//                 commentId: uuidv4(),
//                 comment,
//                 worldId,
//             });
//         } catch (error) {
//             console.error('Error adding comment:', error);
//         }
//     };

//     return (
//         <PageContainer>
//             <Navbar>
//                 <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
//                 <NavLinks>
//                     <a href="/my-learning">message advisor</a>
//                     <a href="/my-earning">My students</a>
//                     <a href="/my-cart">posts</a>
//                     <a href="/certifications">Certifications</a>
//                     <a href="/exams">Exams</a>
//                 </NavLinks>
//             </Navbar>
//             <Content>

//                 <LevelsGrid>
//                     {levels.map((level, index) => (
//                         <LevelCard key={index}>
//                             <h2>{level.levelTitle}</h2>
//                             <p>{level.description}</p>
//                             <p>Advisor Level: {level.toBeAnAdvisorLevel ? 'Yes' : 'No'}</p>
//                             <Actions>
//                                 <button onClick={() => handleShowLevel(level.levelNumber, level._id)}>Get In</button>
//                                 <button onClick={() => handleLevelShowDescription(level.levelNumber)}>View Description</button>

//                                 <button
//                                     onClick={() => dispatch(setExamId(examId)) && navigate('/exam')}
//                                     disabled={!level.isLevelCompleted}
//                                 >
//                                     Take Exam
//                                 </button>
//                             </Actions>
//                         </LevelCard>
//                     ))}
//                 </LevelsGrid>
//                 <FeedbackSection>
//                     <h2>Rate and Comment on the World</h2>
//                     <input
//                         type="number"
//                         placeholder="Enter your rating (1-5)"
//                         onChange={(e) => setRate(e.target.value)}
//                     />
//                     <button onClick={handleRateWorld}>Submit Rating</button>
//                     <textarea
//                         placeholder="Enter your comment"
//                         onChange={(e) => setComment(e.target.value)}
//                     ></textarea>
//                     <button onClick={addCommentToTheWorld}>Add Comment</button>
//                 </FeedbackSection>
//             </Content>
//         </PageContainer>
//     );
// }

// export default Levels;

// // Styled Components
// const PageContainer = styled.div`
//     background-color: #f8f9fa;
//     min-height: 100vh;
//     padding: 20px;
// `;

// const Navbar = styled.nav`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     padding: 10px 20px;
//     background-color: #2c3e50;
//     color: white;
// `;

// const Logo = styled.div`
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
// `;

// const NavLinks = styled.div`
//     display: flex;
//     gap: 15px;

//     a {
//         color: white;
//         text-decoration: none;

//         &:hover {
//             text-decoration: underline;
//         }
//     }
// `;

// const Content = styled.div`
//     max-width: 1200px;
//     margin: 0 auto;
//     padding: 20px;

//     h1 {
//         font-size: 32px;
//         margin-bottom: 20px;
//         color: #2c3e50;
//     }
// `;

// const LevelsGrid = styled.div`
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//     gap: 20px;
// `;

// const LevelCard = styled.div`
//     background-color: white;
//     border-radius: 10px;
//     padding: 20px;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//     transition: transform 0.3s ease;

//     &:hover {
//         transform: translateY(-10px);
//     }

//     h2 {
//         font-size: 20px;
//         margin-bottom: 10px;
//         color: #2c3e50;
//     }

//     p {
//         font-size: 14px;
//         color: #7f8c8d;
//         margin-bottom: 10px;
//     }
// `;

// const Actions = styled.div`
//     display: flex;
//     flex-direction: column;
//     gap: 10px;

//     button {
//         background-color: #2980b9;
//         color: white;
//         border: none;
//         padding: 10px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1f618d;
//         }

//         &:disabled {
//             background-color: #bdc3c7;
//             cursor: not-allowed;
//         }
//     }
// `;

// const FeedbackSection = styled.div`
//     margin-top: 30px;

//     h2 {
//         margin-bottom: 20px;
//         font-size: 24px;
//         color: #2c3e50;
//     }

//     input,
//     textarea {
//         display: block;
//         width: 100%;
//         margin-bottom: 10px;
//         padding: 10px;
//         border: 1px solid #ccc;
//         border-radius: 5px;
//     }

//     button {
//         background-color: #27ae60;
//         color: white;
//         border: none;
//         padding: 10px 20px;
//         border-radius: 5px;
//         cursor: pointer;

//         &:hover {
//             background-color: #1e8449;
//         }
//     }
// `;































// //To show levels Thumbnails number and title


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';
// import { setExamId, setLevelId, setLevelNumber, setWorldName } from '../../../reducers/reducers';
// import { useNavigate } from 'react-router-dom';
// import { Card } from 'react-bootstrap';
// import { v4 as uuidv4 } from 'uuid';
  
// function Levels() { // Pass skillerId as a prop
//   const [levels, setLevels] = useState([]);
//   const [levellId, setLevellId] = useState('')
//   const [isPaidStatus, setIsPaidStatus] = useState(false)
//   const [worldPrice, setWorldPrice] = useState('')
//   const [advisorId, setAdvisorId] =useState('')//get advisor id from backend
//   const [rate, setRate] = useState('')
//   const [comment, setComment] = useState('')
//   const [isLevelCompeleted, setIsLevelCompleted] =useState(false)//get it from the back
//   const worldId = useSelector((state) => state.allow.worldId)
//   console.log('worldId from frontend ============----------------->>> ',worldId)
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const worldName = useSelector((state) => state.allow.worldName)

//   const examId = uuidv4();
//   const rateId = uuidv4();
//   const commentId = uuidv4();
//   console.log('world name from levels =====> ',worldName)

//   const handleAddLevel = () => {
//     dispatch(setWorldName(worldName))
//     navigate('/add-level')
//   }

//   const logIsLevelCompleted = () => {
//     console.log('isLevelCompleted ==========------------------------>>> > >>> ',isLevelCompeleted)
//   }
  
//   const handleStartExam = async(levelId) => {
//     dispatch(setExamId(examId))
//     await axios.post('https://sell-skill.com/api/endpoints/startExam', {
//         levelId,
//         worldId,//useSelector
//         examId //uuidv4
//     })
// }

//   // useEffect(() => {
//   //   const getWorldPrice = async () => {
//   //     const response = await axios.get('https://sell-skill.com/api/endpoints/getPrice', worldId)
//   //     setWorldPrice(response.data)
//   //   }
//   //   getWorldPrice()
//   // }, [])
//   useEffect(() => {
//     const fetchLevels = async () => {
//       try {
//         const response = await axios.get(`https://sell-skill.com/api/endpoints/getLevels`, {
//           params: { worldId } // Pass worldName as a query parameter
//         });
//         setLevels(response.data);
//       } catch (error) {
//         console.error("Error fetching worlds:", error);
//       }
//     };
  

//       fetchLevels();
    
//   }, []);
  
//   const handleShowLevel=  (levelNumber, id) => {
//     console.log('levelId ======================> >> > > > >> >> ',id)
//     dispatch(setLevelId(id))
//     setLevelId(id)
//     dispatch(setLevelNumber(levelNumber))
//     navigate ('/lessons')
//   }
//   const handleLevelShowDescription = (levelNumber) => {
//     dispatch(setLevelNumber(levelNumber))
//     // use useSelector to get this name in the edit world route => send the name to back, then send info to front
//     navigate('/level-description')
    
//   }
//   useEffect(() => {

//     const setLevelCompleted = async () => { 

//         const response = await axios.patch('https://sell-skill.com/api/endpoints/setLevelCompleted', {
//           worldId
//         });

//           setIsLevelCompleted(response.data);
//           console.log('isLevelCompleted coming from back ============--------------=========--------->> >>  > ', response.data);
        

//     };
  
//     setLevelCompleted();
  

//   }, []);
  

//   const handleRateWorld = async() => {
//     await axios.post('https://sell-skill.com/api/endpoints/rateWorld', {rateId, rate, worldId})
//   }
//   const addCommentToTheWorld = async() => {
//     await axios.post('https://sell-skill.com/api/endpoints/addCommentToWorld', {commentId, comment, worldId})
//   }
//   // useEffect(() => {
//   //   // Load PayPal Script
//   //   const addPayPalScript = () => {
//   //     if (window.paypal) {
//   //       renderPayPalButton();
//   //     } else {
//   //       const script = document.createElement('script');
        
//   //       // PayPal SDK script to include both PayPal and card payments (no funding sources are disabled)
//   //       script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons,funding-eligibility&enable-funding=card`;


//   //       script.type = 'text/javascript';
//   //       script.async = true;
//   //       script.onload = renderPayPalButton;
//   //       document.body.appendChild(script);
//   //     }
//   //   };

//   //   const renderPayPalButton = () => {
//   //     window.paypal.Buttons({
//   //       // No funding source restriction, so both PayPal and card will be available
//   //       createOrder: (data, actions) => {
//   //         return actions.order.create({
//   //           purchase_units: [
//   //             {
//   //               amount: {
//   //                 value: '9.00', // Replace with your actual amount
//   //               },
//   //             },
//   //           ],
//   //         });
//   //       },
//   //       onApprove: async (data, actions) => {
//   //         return actions.order.capture().then(async (details) => {
//   //           alert(`Transaction completed by ${details.payer.name.given_name}`);
//   //           try {
//   //             await axios.patch('https://sell-skill.com/api/endpoints/paid', {
//   //               params: {worldId}
//   //             });
//   //             setIsPaidStatus(true);

//   //           } catch (error) {
//   //             console.error('Error updating payment status: ', error);
//   //           }
//   //           window.location.reload();
//   //         });
//   //       },        
//   //       onError: (err) => {
//   //         console.error('PayPal Checkout Error: ', err);
//   //         alert('An error occurred during the transaction. Please try again.');
//   //       },
//   //     }).render('#paypal-button-container');
//   //   };

//   //   addPayPalScript();
//   // }, []);

//   // useEffect(() => {
//   //   const checkIsPaid = async() => {
//   //     const response = await axios.get('https://sell-skill.com/api/endpoints/isPaid', {
//   //       params: {worldId}
//   //     });
//   //     setIsPaidStatus(response.data.isPaid);
//   //   }
//   //   checkIsPaid();

//   //   if (window.fbq) {
//   //     window.fbq('track', 'Purchase', {
//   //       content_name: 'Thank You Page Lead',
//   //       value: 0,
//   //       currency: 'USD'
//   //     });
//   //   }
//   // }, []);

//   return (
//     <div>
//       <button onClick={logIsLevelCompleted}>log is level completed</button>
//      <><h1>My Levels</h1>
//      <button onClick={() => navigate('/choose-advisor')}>chat</button>
//      <button>nav bar exams</button>
//      <button>public feeds </button>
//      <button>nav bar certifications</button>
//      <button>nav bar message advisor</button>
//      <button>navbar my earnings</button>
//      <button>nav bar my students</button>
//       <div className="worlds-container">

//         {levels.map((level, index) => (
//           <div key={index} className="world-card">
//             <>{console.log('level from inside ================ > ---------> ',level)}</>
//             <h2>{level.name}</h2>
//             <h2>{level.levelTitle}</h2>
//             <p>{level.description}</p>
//             <h2>toBeAnAdvisorLevel {level.toBeAnAdvisorLevel}</h2>
//             <button onClick={()=>handleShowLevel(level.name, level._id)}>Get in</button>
//             <button onClick={()=>handleLevelShowDescription(level.name)}>view description</button>
//             <button onClick = {() => handleStartExam(level._id) }
//              disabled={!level.isLevelCompleted}
//             >take the exam</button>
//           </div>
//         ))}
//       </div></>


//       <input 
//       placeholder='enter rate'
//       onChange={(e)=> setRate(e.target.value)}
//       />
//       <button onClick ={handleRateWorld}>rate the world</button>
//       <input 
//       placeholder = 'enter comment'
//       onChange={ (e) => setComment(e.target.value)}
//       />
//       <button onClick={addCommentToTheWorld}>add comment about the world</button>
//     </div>
//   );
// }

// export default Levels;


