import axios from "axios";
import { useDebugValue, useEffect, useRef, useState } from "react";

import { FaBell, FaChevronLeft, FaChevronRight, FaComments } from "react-icons/fa";
import { Form, Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import styled from "styled-components";
import { Button, Container, ProgressBar } from "react-bootstrap";
import { getStorage, ref, getDownloadURL,uploadBytesResumable, uploadBytes } from 'firebase/storage';
import { setAcheivementId } from "../../reducers/reducers";
import { useDispatch } from "react-redux";
function MProfile() {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [backendLoading, setBackendLoading] = useState(false);
  const [showEditLetterModal, setShowEditLetterModal] = useState(false);
  const [showDeleteLetterModal, setShowDeleteLetterModal] = useState(false);
  const [newLetter, setNewLetter] = useState("");
  const [title, setTitle] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [elements, setElements] = useState([]);
  

  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [selectedSection, setSelectedSection] = useState("overview"); // Default section
  const [profilePictureSrc, setProfilePictureSrc] = useState("");
  const dropdownRef = useRef(null); 
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [ picturee, setPicturee] = useState('')
  const [profile, setProfile] = useState(null);


  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

const [firstName, setFirstName] = useState(profile?.name || "");
const [lastName, setLastName] = useState(profile?.surname || "");
const [surnamee, setSurnamee] = useState('')
const [correctPastPassword, setCorrectPastPassword] = useState(false)
const [showPhotoModal, setShowPhotoModal] = useState(false);
const [showVideoModal, setShowVideoModal] = useState(false);
const [newPhoto, setNewPhoto] = useState(null);
const [newVideoUrl, setNewVideoUrl] = useState("");
const [showDeleteModal, setShowDeleteModal] = useState(false);
const [showDeleteVideoModal, setShowDeleteVideoModal] = useState(false);


  const [selectedResult, setSelectedResult] = useState(null);
  const [showResultModal, setShowResultModal] = useState(false);
  const [newResult, setNewResult] = useState({ title: "", thumbnail: "", elements: [] });
  
  const {
    name = "",
    surname = "",
    picture = "",
    coverPicture = "",
    letter = "",
    cash = 0,
    rate = 0,
    followers = [],
    worlds = [],
    certifications = [],
    instructorDescription = "",
    joinedAt = "",
    realWorldResults = [],
    definingVideo = "",
  } = profile || {};
  
  

console.log('profile from MProfile ========================================> >> > > >  > > >>  > > > ',profile)

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleGoToAcheivement = (acheivementId) => {
    dispatch(setAcheivementId(acheivementId))
    console.log('acheivementId from MProfile =================================================================> > > > > >   ',acheivementId)
    navigate('/acheivment')
    
  }
  const handleFileUpload = async (file) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `real-world-results/${uuidv4()}_${file.name}`);
      await uploadBytes(fileRef, file);
      return await getDownloadURL(fileRef);
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };


  const handleEditLetter = async () => {
    try {
      await axios.post("https://sell-skill.com/api/endpoints/insertSkillerLetter", { letter: newLetter });
      alert("Letter updated successfully.");
      setShowEditLetterModal(false);
      window.location.reload(); // Refresh the page to reflect the changes
    } catch (error) {
      console.error("Error updating letter:", error);
      alert("Failed to update the letter.");
    }
  };
  
  const handleDeleteLetter = async () => {
    try {
      await axios.delete("https://sell-skill.com/api/endpoints/deletetSkillerLetter");
      alert("Letter deleted successfully.");
      setShowDeleteLetterModal(false);
      window.location.reload(); // Refresh the page to reflect the changes
    } catch (error) {
      console.error("Error deleting letter:", error);
      alert("Failed to delete the letter.");
    }
  };
  
  const handleVideoUpload = async (file) => {
    try {
      setIsLoading(true);
      const storage = getStorage();
      const storageRef = ref(storage, `videos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading video:", error);
          setIsLoading(false);
        },
        async () => {
          const videoURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploadSuccess(true);
          setIsLoading(false);

          // Proceed with backend upload
          setBackendLoading(true);
          try {
            await axios.post("https://sell-skill.com/api/endpoints/uploadVideo", { videoURL });
            setBackendLoading(false);
            alert('definition video changed successfully')
          } catch (error) {
            console.error("Error sending video URL to backend:", error);
            setBackendLoading(false);
          }
        }
      );
    } catch (error) {
      console.error("Error uploading video:", error);
      setIsLoading(false);
    }
  };
  
  const addElement = (type) => {
    setElements((prev) => [
      ...prev,
      { id: uuidv4(), type, content: '', order: prev.length + 1 },
    ]);
  };

  const updateElementContent = async (index, fileOrContent) => {
    const updatedElements = [...elements];
    if (updatedElements[index].type === 'text') {
      updatedElements[index].content = fileOrContent;
    } else {
      const url = await handleFileUpload(fileOrContent);
      updatedElements[index].content = url;
    }
    setElements(updatedElements);
  };

  const removeElement = (index) => {
    setElements((prev) =>
      prev.filter((_, i) => i !== index).map((el, i) => ({ ...el, order: i + 1 }))
    );
  };

  const handleThumbnailUpload = async (file) => {
    const url = await handleFileUpload(file);
    setThumbnail(url);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://sell-skill.com/api/endpoints/realWorldResults', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          _id: uuidv4(),
          title,
          thumbnail,
          elements,
        }),
      });
      if (response.ok) {
        console.log('Real World Results uploaded successfully');
      } else {
        console.error('Error uploading results:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting results:', error);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(
          "https://sell-skill.com/api/endpoints/getProfile"
        );
        setProfile(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchProfilePicture = async () => {
      try {
        const response = await axios.get(
          "https://sell-skill.com/api/endpoints/getSkillerProfilePicture",
          { responseType: "blob" }
        );
        const url = URL.createObjectURL(response.data);
        setProfilePictureSrc(url);
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    fetchProfile();
    fetchProfilePicture();
  }, []);


    if (loading) {
      return <div className="loading-spinner">Loading...</div>;
    }
  


    const handleDeleteVideo = async () => {
      try {
          await axios.delete("https://sell-skill.com/api/endpoints/deleteVideo");
          alert("Definition video deleted successfully.");
          setShowDeleteVideoModal(false);
          window.location.reload();
      } catch (err) {
          console.error("Error deleting definition video:", err);
          alert("Failed to delete definition video.");
      }
  };
  
    const handleEditPhoto = async() => {

    }
    const handleDeletePhoto = async () => {
      try {
          await axios.delete("https://sell-skill.com/api/endpoints/deleteProfilePicture");
          alert("Profile photo deleted successfully.");
          setShowDeleteModal(false);
          window.location.reload();
      } catch (err) {
          console.error("Error deleting profile photo:", err);

      }
  };
  
    const handleChangePassword = async() => {

    }

    const checkPastPass = async () => {
      try {
        const response = await axios.get("https://sell-skill.com/api/endpoints/checkPastPass", {
          params: { currentPassword },
        });
        setCorrectPastPassword(response.data);
      } catch (error) {
        console.error("Error checking current password:", error);
        alert("Failed to validate the current password. Please try again.");
      }
    };
    const updatePassword = async () => {
      try {
        await axios.patch("https://sell-skill.com/api/endpoints/updatePassword", { newPassword });
        alert("Password updated successfully.");
      } catch (error) {
        console.error("Error updating password:", error);
        alert("Failed to update password. Please try again.");
      }
    };
    const handleChangeNameSurname = async() => {
      await axios.patch('https://sell-skill.com/api/endpoints/changeNameSurname', {firstName, surnamee})
    }


  

  
    const handleProfileClick = () => {
      setShowDropdown((prev) => !prev);
    };
  
    const handleSectionClick = (section) => {
      setSelectedSection(section); // Update the selected section
    };




    const handlePhotoModalSubmit = async () => {
      if (newPhoto) {
          const formData = new FormData();
          formData.append("picture", newPhoto);
          try {
              await axios.patch("https://sell-skill.com/api/endpoints/updateProfilePicture", formData);
              alert("Profile picture updated successfully.");
              setShowPhotoModal(false);
              window.location.reload();
          } catch (err) {
              console.error("Error updating profile picture:", err);
              alert("Failed to update profile picture.");
          }
      }
  };

  const handleVideoModalSubmit = async () => {
      try {
          await axios.patch("https://sell-skill.com/api/endpoints/updateDefinitionVideo", { definitionVideo: newVideoUrl });
          alert("Definition video updated successfully.");
          setShowVideoModal(false);
          window.location.reload();
      } catch (err) {
          console.error("Error updating definition video:", err);
          alert("Failed to update definition video.");
      }
  };
  const confirmDeleteVideo = () => {
    setShowDeleteVideoModal(true);
  };






  const handleResultClick = (result) => {
    setSelectedResult(result);
    setShowResultModal(true);
  };



  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;


  
    const renderSectionContent = () => {
      switch (selectedSection) {
        case "accountSettings":
          return (
            <div>
              <h2>Account Settings</h2>
              {/* Change Photo Section */}
              <div>
    <h3>Profile Photo</h3>
    {
      profile.picture  ? (
<>
<img
        src={profilePictureSrc}
        alt="Profile"
        style={{ width: "100px", height: "100px", borderRadius: "50%" }}
    />
    <div>
        <button onClick={() => setShowPhotoModal(true)}>Change Photo</button>
        <button onClick={() => setShowDeleteModal(true)}>Delete Photo</button>
    </div>
</>
      ): (
        <input 
        type="file"
        placeholder="enter a photo"
        />
      )
    }

</div>

{showDeleteModal && (
    <Modal>
        <ModalContent>
            <h2>Confirm Deletion</h2>
            <p>Are you sure you want to delete your profile photo?</p>
            <button onClick={handleDeletePhoto}>Yes, Delete</button>
            <button onClick={() => setShowDeleteModal(false)}>Cancel</button>
        </ModalContent>
    </Modal>
)}

              {/* Change Password Section */}
              <div>
  <h3>Change Password</h3>
  <label>
    Current Password:
    <input
      type="password"
      value={currentPassword}
      onChange={(e) => setCurrentPassword(e.target.value)}
      required
    />
  </label>
  <button onClick={checkPastPass}>
    Check Current Password
  </button>
  {correctPastPassword !== null && (
    <p>{correctPastPassword ? "Current password is correct." : "Incorrect current password."}</p>
  )}
  <label>
    New Password:
    <input
      type="password"
      value={newPassword}
      onChange={(e) => setNewPassword(e.target.value)}
      required
      disabled={!correctPastPassword} // Dynamically enable or disable
    />
  </label>
  <button
    onClick={updatePassword}
    disabled={!correctPastPassword || !newPassword}
  >
    <i className="fas fa-save"></i> Update Password
  </button>
</div>

        
              {/* Change Name Section */}
              <div>
                <h3>Update Name</h3>
    
                  <label>
                    Name:
                    <input
                      type="text"
                      placeholder={profile.name}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                                        surname:
                    <input
                      type="text"
                      placeholder={profile.surname}
                      value={surnamee}
                      onChange={(e) => setSurnamee(e.target.value)}
                      required
                    />

                  </label>

                  <button type="submit">
                    <i className="fas fa-save" onClick={() => handleChangeNameSurname}></i> Update Name
                  </button>
            
              </div>
            </div>
          );
        
          case "certifications":
            return (
              <div>
                <h2>Certifications</h2>
                {profile.certifications?.length > 0 ? (
  <Grid>
    {profile.certifications.map((cert) => (
      <Card key={cert._id}>
        <Thumbnail
          src={`data:${cert.contentType};base64,${cert.data.toString('base64')}`}
          alt={cert.name || 'Certification'}
        />
        <h4>{cert.name || 'Unnamed Certificate'}</h4>
      </Card>
    ))}
  </Grid>
) : (
  <p>No certifications available.</p>
)}

              </div>
            );


           case "letter":
             return (
 <div>
   <h3>Letter</h3>
   <p>{letter || "No letter available."}</p>

   {/* Button to edit the letter */}
   <button onClick={() => setShowEditLetterModal(true)}>Edit Letter</button>

   {/* Button to delete the letter */}
   <button onClick={() => setShowDeleteLetterModal(true)}>Delete Letter</button>

   {/* Modal for editing the letter */}
   {showEditLetterModal && (
     <Modal>
       <ModalContent>
         <h2>Edit Letter</h2>
         <textarea
           rows="5"
           placeholder="Enter your new letter"
           value={newLetter}
           onChange={(e) => setNewLetter(e.target.value)}
         ></textarea>
         <button onClick={handleEditLetter}>Save</button>
         <button onClick={() => setShowEditLetterModal(false)}>Cancel</button>
       </ModalContent>
     </Modal>
   )}

   {/* Modal for deleting the letter */}
   {showDeleteLetterModal && (
     <Modal>
       <ModalContent>
         <h2>Confirm Deletion</h2>
         <p>Are you sure you want to delete your letter? This action cannot be undone.</p>
         <button onClick={handleDeleteLetter}>Yes, Delete</button>
         <button onClick={() => setShowDeleteLetterModal(false)}>Cancel</button>
       </ModalContent>
     </Modal>
   )}
 </div>

             );

             case "definitionVideo": 
             return (
               <StyledContainer>
               <MainCard>
                 <Title>Upload Your Video</Title>
                 <p className="text-center">Choose a video to upload, or skip this step.</p>
                 <ButtonGroup>
                   <UploadButton as="label" htmlFor="file-upload">
                     Change defintion video
                     <Input
                       type="file"
                       id="file-upload"
                       accept="video/*"
                       onChange={(e) => handleVideoUpload(e.target.files[0])}
                     />
                   </UploadButton>
                   {isLoading && (
                     <ProgressBar
                       now={uploadProgress}
                       label={`${Math.round(uploadProgress)}%`}
                       animated
                       striped
                     />
                   )}
                   {uploadSuccess && !backendLoading && <Label>Video uploaded successfully!</Label>}
                  {backendLoading && <Label>Finalizing upload, please wait...</Label>}

                 </ButtonGroup>
                 <Button onClick={confirmDeleteVideo}>Delete Video</Button>



 
 {showDeleteVideoModal && (
   <Modal>
     <ModalContent>
       <h2>Confirm Deletion</h2>
       <p>Are you sure you want to delete this video? This action cannot be undone.</p>
       <button onClick={handleDeleteVideo}>Yes, Delete</button>
       <button onClick={() => setShowDeleteVideoModal(false)}>Cancel</button>
     </ModalContent>
   </Modal>
 )}

{definingVideo && (
  <Section>
    <SectionTitle>Defining Video</SectionTitle>
    <video controls style={{ width: '100%', borderRadius: '10px' }}>
      <source src={definingVideo} type="video/mp4" />
    </video>
  </Section>
)}


               </MainCard>
             </StyledContainer>
             );


          case "realWorldResults":
            return (

<>   
<Container>
              <Header>Real World Results</Header>
              <Content>
                <Input
                  type="text"
                  placeholder="Enter title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <FileInputContainer>
                  <FileLabel htmlFor="thumbnail-upload">Upload Thumbnail</FileLabel>
                  <FileInput
                    id="thumbnail-upload"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleThumbnailUpload(e.target.files[0])}
                  />
                  {thumbnail && <Thumbnail src={thumbnail} alt="Thumbnail preview" />}
                </FileInputContainer>
                <ElementsContainer>
        
                {elements.map((el, index) => (
          <Element key={el.id}>
            {el.type === 'text' ? (
              <TextArea
                placeholder="Enter text"
                value={el.content}
                onChange={(e) => updateElementContent(index, e.target.value)}
              />
            ) : (
              <>
                <FileLabel htmlFor={`file-upload-${index}`}>Upload {el.type}</FileLabel>
                <FileInput
                  id={`file-upload-${index}`}
                  type="file"
                  accept={
                    el.type === 'photo'
                      ? 'image/*'
                      : el.type === 'video'
                      ? 'video/*'
                      : '.pdf'
                  }
                  onChange={(e) => updateElementContent(index, e.target.files[0])}
                />
                {/* Preview the uploaded file */}
                {el.type === 'photo' && el.content && (
                  <PreviewImage src={el.content} alt="Uploaded" />
                )}
                {el.type === 'video' && el.content && (
                  <VideoPreview controls>
                    <source src={el.content} type="video/mp4" />
                    Your browser does not support video.
                  </VideoPreview>
                )}
                {el.type === 'pdf' && el.content && (
                  <PdfPreview>
                    <a href={el.content} target="_blank" rel="noopener noreferrer">
                      View PDF
                    </a>
                  </PdfPreview>
                )}
              </>
            )}
            <RemoveButton onClick={() => removeElement(index)}>Remove</RemoveButton>
          </Element>
        ))}
        
        
                </ElementsContainer>
                <ButtonContainer>
                  <Button onClick={() => addElement('text')}>Add Text</Button>
                  <Button onClick={() => addElement('photo')}>Add Photo</Button>
                  <Button onClick={() => addElement('video')}>Add Video</Button>
                  <Button onClick={() => addElement('pdf')}>Add PDF</Button>
                </ButtonContainer>
                <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
              </Content>
            </Container>















            <Section>
  <SectionTitle>Real-World Achievements</SectionTitle>
  <Grid>
    {realWorldResults?.map((result, index) => (
      <Card key={index}>
        {result.thumbnail && (
          <Thumbnail src={result.thumbnail} alt={result.title || 'Achievement Thumbnail'} />
        )}
        {result.title && <h4>{result.title}</h4>}
        {result.texts && <p>{result.texts.map((text) => text.text).join(' ')}</p>}
        <Button
          onClick={() => handleGoToAcheivement(result._id)}
          style={{ marginTop: '10px', background: '#27ae60', color: 'white' }}
        >
          View Achievement
        </Button>
      </Card>
    ))}
  </Grid>
</Section>
</>







              );

              case "meAsAnAdvisorStatus":
                return (
                  <div>
                    <h2> i am advior at : </h2>
   
                   
                  </div>
                );
                case "myPosts":
                  return (
                    <div>
                      <h2> my posts</h2>

                     
                    </div>
                  );

                  case "savedPosts":
                    return (
                      <div>
                        <h2> saved posts</h2>
  
                       
                      </div>
                    );

                    case "myMates":
                      return (
                        <div>
                          <h2> my mates</h2>
    
                         
                        </div>
                      );
        default:
          return (
            <ProfileContainer>
            <CoverPhoto coverImage={profile.coverPicture} />
      
            <ProfileHeader>
              <ProfilePicture
                src={profile.picture}
                alt={`${name}'s profile`}
              />
              <ProfileDetails>
                <h1>{name} {surname}</h1>
                <p>Rate: {rate} / 5</p>
                <Stats>
                  <p>Mates: {followers?.length || 0}</p>
                  <p>Cash: ${cash}</p>
                </Stats>
                <ActionButtons>
                  <button className="add-mate-btn">Add Mate</button>
                  <button className="message-btn">Message</button>
                </ActionButtons>
              </ProfileDetails>
            </ProfileHeader>
      
            {definingVideo && (
              <Section>
                <SectionTitle>Defining Video</SectionTitle>
                <video controls style={{ width: '100%', borderRadius: '10px' }}>
                  <source src={definingVideo} type="video/mp4" />
                </video>
              </Section>
            )}
      
            <Section>
              <SectionTitle>Worlds</SectionTitle>
              <Grid>
                {worlds?.map((world) => (
                  <Card key={world._id}>
                    <Thumbnail src={world.worldThumbnail} alt={world.worldName} />
                    <h4>{world.worldName}</h4>
                    <p>{world.worldDescription}</p>
                  </Card>
                ))}
              </Grid>
            </Section>
      
            <Section>
              <SectionTitle>Certifications</SectionTitle>
              <Grid>
                {certifications?.map((cert) => (
                  <Card key={cert._id}>
                    <Thumbnail src={`data:${cert.thumbnail?.contentType};base64,${cert.thumbnail?.data}`} alt={cert.name} />
                    {cert.name}
                  </Card>
                ))}
              </Grid>
            </Section>
            <Section>
  <SectionTitle>Real-World Achievements</SectionTitle>
  <Grid>
  {realWorldResults?.map((result) => (
  <ResultCard key={result._id} onClick={() => handleResultClick(result)}>
    <img src={result.thumbnail || '/default-thumbnail.jpg'} alt={result.title || 'No Title'} />
    <h4>{result.title || 'Untitled Result'}</h4>
  </ResultCard>
))}

  </Grid>
</Section>
      
            <div style={{ textAlign: 'center', padding: '20px 0' }}>
              <p>Joined on: {new Date(joinedAt).toLocaleDateString()}</p>
            </div>
          </ProfileContainer>
          );
      }
    };
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    if (!profile) return <p>No profile data found.</p>;
  
    return (
      <PageContainer>
        {/* Top Navbar */}
        <Navbar>
          <LeftNav>
            <Logo onClick={() => navigate("/worlds")}>Sell-Skill</Logo>
            <NavLinks>
              <a href="/my-learning">My Profile</a>
              <a href="/my-earning">My Uploads</a>
              <a>Withdraw Cash</a>
              <a href="/my-cart">My Cart</a>
              <a href="/certifications">Certifications</a>
              <a href="/exams">Exams</a>
            </NavLinks>
          </LeftNav>

          <NavIcons>
            <FaBell onClick={() => navigate("/my-notifications")} title="Notifications" />
            <FaComments onClick={() => navigate("/my-chats")} title="Messages" />
            <div ref={dropdownRef}>
              <ProfileWrapper onClick={handleProfileClick}>
                <ProfilePicture
                  src={profilePictureSrc || "/images/default-profile.jpg"}
                  alt="Profile"
                />
              </ProfileWrapper>
              {showDropdown && (
                <DropdownMenu>
                  <a href="/my-profile">My Profile</a>
                  <a href="/logout">Log Out</a>
                </DropdownMenu>
              )}
            </div>
          </NavIcons>
        </Navbar>
  
        {/* Sidebar and Main Content */}
        <ContentContainer>
          {sidebarVisible && (
            <Sidebar>
              <SectionHeader onClick={() => handleSectionClick("overview")}>
                Profile Overview
              </SectionHeader>
              <SectionHeader onClick={() => handleSectionClick("accountSettings")}>
                Account Settings
              </SectionHeader>
              <SectionHeader onClick={() => handleSectionClick("certifications")}>
                Certifications
              </SectionHeader>
              <SectionHeader onClick={() => handleSectionClick("realWorldResults")}>
                Real World Results
              </SectionHeader>

              <SectionHeader onClick={() => handleSectionClick("letter")}>
                Letter
              </SectionHeader>

              <SectionHeader onClick={() => handleSectionClick("definitionVideo")}>
                Definition video
              </SectionHeader>

              <SectionHeader onClick={() => handleSectionClick("meAsAnAdvisorStatus")}>
                Me As An Advisor Status
              </SectionHeader>

              <SectionHeader onClick={() => handleSectionClick("myPosts")}>
                My Posts
              </SectionHeader>

              <SectionHeader onClick={() => handleSectionClick("savedPosts")}>
                Saved Posts
              </SectionHeader>

              <SectionHeader onClick={() => handleSectionClick("myMates")}>
                My Mates
              </SectionHeader>
              {/* Add more SectionHeaders here */}
            </Sidebar>
          )}
          <ToggleSidebarButton onClick={() => setSidebarVisible(!sidebarVisible)}>
            {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
          </ToggleSidebarButton>
  
          <MainContent>{renderSectionContent()}</MainContent>
        </ContentContainer>


        {showPhotoModal && (
                <Modal>
                    <ModalContent>
                        <h2>Change Profile Photo</h2>
                        <input type="file" onChange={(e) => setNewPhoto(e.target.files[0])} />
                        <button onClick={handlePhotoModalSubmit}>Submit</button>
                        <button onClick={() => setShowPhotoModal(false)}>Cancel</button>
                    </ModalContent>
                </Modal>
            )}

            {showVideoModal && (
                <Modal>
                    <ModalContent>
                        <h2>Change Definition Video</h2>
                        <input
                            type="text"
                            placeholder="Enter video URL"
                            value={newVideoUrl}
                            onChange={(e) => setNewVideoUrl(e.target.value)}
                        />
                        <button onClick={handleVideoModalSubmit}>Submit</button>
                        <button onClick={() => setShowVideoModal(false)}>Cancel</button>
                    </ModalContent>
                </Modal>
            )}


{showDeleteVideoModal && (
    <Modal>
        <ModalContent>
            <h2>Confirm Deletion</h2>
            <p>Are you sure you want to delete your definition video?</p>
            <button onClick={handleDeleteVideo}>Yes, Delete</button>
            <button onClick={() => setShowDeleteVideoModal(false)}>Cancel</button>
        </ModalContent>
    </Modal>
)}
      </PageContainer>
    );
  }
  
  export default MProfile;


const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #2c3e50;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
`;

const LeftNav = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.div`
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
`;

const NavLinks = styled.div`
  margin-left: 20px;
  display: flex;
  gap: 15px;

  a {
    color: white;
    text-decoration: none;
  }
`;

const NavIcons = styled.div`
  display: flex;
  gap: 15px;
`;

const ProfileWrapper = styled.div`
  position: relative;
`;



const DefaultProfile = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #bdc3c7;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  display: flex;
  flex-direction: column;

  a {
    padding: 10px 15px;
    color: black;
    text-decoration: none;

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;


const ToggleSidebarButton = styled.div`
    position: fixed; /* Ensures the button is always visible */
    top: 70px; /* Adjust as needed for placement */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamically positions based on visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    background-color: #ddd;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1100; /* Keep it above the sidebar */
    transition: left 0.3s ease; /* Smooth movement */
`;

const Header = styled.div`
  margin-bottom: 20px;

  h1 {
    font-size: 24px;
  }

  p {
    color: #7f8c8d;
  }
`;


  


















const PageContainer = styled.div`
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 60px 20px 20px;
`;

const ContentContainer = styled.div`
    display: flex;
    margin-left: 300px; /* Adjusts for the fixed sidebar */
    flex-direction: column;
    padding: 20px;
`;

const Sidebar = styled.div`
    width: 300px;
    position: fixed; /* Keeps the sidebar fixed to the left */
    top: 60px; /* Align with the navbar */
    left: 0;
    bottom: 0; /* Stretches it to the bottom */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Makes it scrollable if content overflows */
    z-index: 1000; /* Ensures it stays above the main content */
`;

const SectionHeader = styled.h2`
    font-size: 18px;
    color: #2c3e50;
    cursor: pointer;
`;

const MainContent = styled.div`
    flex: 1;
    padding: 20px;
`;





























const ProfileContainer = styled.div`
  font-family: 'Arial', sans-serif;
  color: #2c3e50;
  max-width: 1200px;
  margin: auto;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
`;

const CoverPhoto = styled.div`
  background-image: url(${props => props.coverImage});
  background-size: cover;
  background-position: center;
  height: 300px;
  position: relative;
  border-radius: 10px;
`;

const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  background: #fff;
  position: relative;
  border-radius: 10px;
`;

const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 150px;
  height: 150px;
  border: 5px solid #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: -75px;
  left: 20px;
`;

const ProfileDetails = styled.div`
  margin-left: 200px;
  flex: 1;
`;

const Stats = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 10px;
  color: #7f8c8d;
`;

const ActionButtons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;

  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }

  .add-mate-btn {
    background: #27ae60;
    color: white;
  }

  .message-btn {
    background: #2c3e50;
    color: white;
  }
`;

const Section = styled.div`
  margin: 20px 0;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h3`
  border-bottom: 2px solid #27ae60;
  display: inline-block;
  padding-bottom: 5px;
  margin-bottom: 20px;
`;


const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;




const Card = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.05); /* Slightly enlarge the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s ease;

  ${Card}:hover & {
    transform: scale(1.1); /* Slightly enlarge the image */
  }
`;




const ResultsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
`;

const ResultCard = styled.div`
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  img {
    max-width: 100%;
    border-radius: 4px;
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
`;


const Content = styled.div`
  width: 100%;
  max-width: 800px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const FileInputContainer = styled.div`
  margin-bottom: 20px;
`;


const FileInput = styled.input`
  display: none;
`;







const TextArea = styled.textarea`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
`;

const RemoveButton = styled.button`
  padding: 10px;
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;


const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const PreviewImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
`;

const VideoPreview = styled.video`
  width: 200px;
  height: auto;
  margin-top: 10px;
`;

const PdfPreview = styled.div`
  margin-top: 10px;
  a {
    color: #3498db;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const Element = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Space between elements */
  padding: 10px;
  background-color: #f9f9f9; /* Light background for clarity */
  border: 1px solid #ddd; /* Add borders for visibility */
  border-radius: 4px;
  flex-wrap: wrap; /* Allow wrapping for large content */
`;
const FileLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background: #27ae60;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-right: 10px; /* Space between the label and other content */
  margin-bottom: 10px;
  &:hover {
    background: #218c53; /* Darker green on hover */
  }
`;
const ElementsContainer = styled.div`
  margin-bottom: 20px;
  max-height: 400px; /* Set a max height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fefefe;
`;
const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  background-color: #f9f9f9;
`;

const MainCard = styled(Card)`
  background-color: white;
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 40px;
`;

const Title = styled.h2`
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: bold;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
`;

const StyledButton = styled(Button)`
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  border: none;

  &:hover {
    color: white;
  }
`;

const UploadButton = styled(StyledButton)`
  background-color: #27ae60;

  &:hover {
    background-color: #1e8449;
  }
`;

const SkipButton = styled(StyledButton)`
  background-color: #2c3e50;

  &:hover {
    background-color: #1a242f;
  }
`;

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  display: block;
  text-align: center;
  color: #2c3e50;
  cursor: pointer;
`;


// import axios from "axios";
// import { useEffect, useRef, useState } from "react";
// import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import styled from "styled-components";

// function MProfile() {
//     const [profile, setProfile] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [sidebarVisible, setSidebarVisible] = useState(true);
//     const [expandedSection, setExpandedSection] = useState(null);
//     const [selectedSection, setSelectedSection] = useState("overview"); // Default section
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const dropdownRef = useRef(null);
//     const [showDropdown, setShowDropdown] = useState(false);
  
//     useEffect(() => {
//       const fetchProfile = async () => {
//         try {
//           const response = await axios.get(
//             "https://sell-skill.com/api/endpoints/getProfile"
//           );
//           setProfile(response.data);
//         } catch (err) {
//           setError(err.message);
//         } finally {
//           setLoading(false);
//         }
//       };
//       fetchProfile();
//     }, []);
  
//     const toggleSidebar = () => {
//       setSidebarVisible(!sidebarVisible);
//     };
  
//     const handleSectionClick = (section) => {
//       setSelectedSection(section); // Update the selected section
//     };
  
//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error: {error}</p>;
//     if (!profile) return <p>No profile data found.</p>;
  
//     const renderSectionContent = () => {
//       switch (selectedSection) {
//         case "accountSettings":
//           return (
//             <div>
//               <h2>Account Settings</h2>
//               <ul>
//                 <li>Change Photo</li>
//                 <li>Change Password</li>
//                 <li>Change Name</li>
//                 {/* Add more options here */}
//               </ul>
//             </div>
//           );
//         case "certifications":
//           return (
//             <div>
//               <h2>Certifications</h2>
//               <ul>
//                 {profile.certifications.map((cert) => (
//                   <li key={cert._id}>{cert.name}</li>
//                 ))}
//               </ul>
//             </div>
//           );
//         case "realWorldResults":
//           return (
//             <div>
//               <h2>Real World Results</h2>
//               <ul>
//                 {profile.realWorldResults.map((result) => (
//                   <li key={result._id}>{result.description}</li>
//                 ))}
//               </ul>
//             </div>
//           );
//         // Add more cases for other sections
//         default:
//           return (
//             <div>
//               <h2>Overview</h2>
//               <p>Welcome to your profile overview!</p>
//             </div>
//           );
//       }
//     };
  
//     return (
//       <PageContainer>
//         <Navbar>
//           {/* Navbar content */}
//         </Navbar>
//         <ContentContainer>
//           {sidebarVisible && (
//             <Sidebar>
//               <SectionHeader onClick={() => handleSectionClick("overview")}>
//                 Profile Overview
//               </SectionHeader>
//               <SectionHeader onClick={() => handleSectionClick("accountSettings")}>
//                 Account Settings
//               </SectionHeader>
//               <SectionHeader onClick={() => handleSectionClick("certifications")}>
//                 Certifications
//               </SectionHeader>
//               <SectionHeader onClick={() => handleSectionClick("realWorldResults")}>
//                 Real World Results
//               </SectionHeader>
//               {/* Add more SectionHeaders here */}
//             </Sidebar>
//           )}
//           <ToggleSidebarButton onClick={toggleSidebar}>
//             {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
//           </ToggleSidebarButton>
//           <MainContent>{renderSectionContent()}</MainContent>
//         </ContentContainer>
//       </PageContainer>
//     );
//   }
  
//   export default MProfile;
// // Styled Components
// const PageContainer = styled.div`
//   background-color: #f8f9fa;
//   min-height: 100vh;
//   padding: 60px 20px 20px;
// `;

// const Navbar = styled.nav`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 10px 20px;
//   background-color: #2c3e50;
//   color: white;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: 1000;
// `;

// const LeftNav = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const Logo = styled.div`
//   font-size: 24px;
//   font-weight: bold;
//   cursor: pointer;
// `;

// const NavLinks = styled.div`
//   margin-left: 20px;
//   display: flex;
//   gap: 15px;

//   a {
//     color: white;
//     text-decoration: none;
//   }
// `;

// const NavIcons = styled.div`
//   display: flex;
//   gap: 15px;
// `;

// const ProfileWrapper = styled.div`
//   position: relative;
// `;

// const ProfilePicture = styled.img`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
// `;

// const DefaultProfile = styled.div`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   background-color: #bdc3c7;
// `;

// const DropdownMenu = styled.div`
//   position: absolute;
//   top: 50px;
//   right: 0;
//   background-color: white;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//   width: 200px;
//   display: flex;
//   flex-direction: column;

//   a {
//     padding: 10px 15px;
//     color: black;
//     text-decoration: none;

//     &:hover {
//       background-color: #f0f0f0;
//     }
//   }
// `;

// const ContentContainer = styled.div`
//   display: flex;
//   position: relative;
//   width: 100%;
// `;

// const Sidebar = styled.div`
//   width: 300px;
//   background-color: #f4f4f4;
//   padding: 20px;
//   border-right: 1px solid #ddd;
// `;

// const SectionHeader = styled.h2`
//   font-size: 18px;
//   color: #2c3e50;
//   cursor: pointer;
// `;

// const ToggleSidebarButton = styled.div`
//   position: absolute;
//   top: 8px;
//   left: ${props => (props.sidebarVisible ? "300px" : "0")};
//   cursor: pointer;

//   svg {
//     font-size: 20px;
//   }
// `;

// const MainContent = styled.div`
//   flex: 1;
//   padding: 20px;
// `;

// const Header = styled.div`
//   margin-bottom: 20px;

//   h1 {
//     font-size: 24px;
//   }

//   p {
//     color: #7f8c8d;
//   }
// `;

// const ProfileDetails = styled.div`
//   h3 {
//     margin-top: 20px;
//   }

//   ul {
//     list-style: none;
//     padding: 0;

//     li {
//       margin: 5px 0;
//     }
//   }
// `;
