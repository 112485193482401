import React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './FullWidthSlider.css'

const FullWidthSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
    };

    return (
        <div className="slider-container">
            <Slider {...settings}>
                <div className="slide">
                    <img src="Headers/slider1.png" alt="Slide 1" className="slider-image" />
                    <div className="text-overlay">
                        <motion.p
                            className="animated-text"
                            animate={{ color: ["#ecf0f1", "#3498db", "#e74c3c", "#2ecc71"] }}
                            transition={{
                                duration: 4,
                                repeat: Infinity,
                                repeatType: "reverse",
                            }}
                        >
                            IT's
                        </motion.p>
                        <motion.p
                            className="animated-text"
                            animate={{ color: ["#ecf0f1", "#9b59b6", "#e67e22", "#1abc9c"] }}
                            transition={{
                                duration: 4,
                                repeat: Infinity,
                                repeatType: "reverse",
                            }}
                        >
                            KINDA
                        </motion.p>
                        <motion.p
                            className="animated-text"
                            animate={{ color: ["#ecf0f1", "#f39c12", "#e84118", "#8e44ad"] }}
                            transition={{
                                duration: 4,
                                repeat: Infinity,
                                repeatType: "reverse",
                            }}
                        >
                            GAME
                        </motion.p>
                    </div>
                </div>
                <div className="slide">
                    <img src="Headers/slider2.png" alt="Slide 2" className="slider-image" />
                    <div className="text-overlay">
                        <motion.p
                            className="animated-text"
                            animate={{ color: ["#ecf0f1", "#3498db", "#e74c3c", "#2ecc71"] }}
                            transition={{
                                duration: 4,
                                repeat: Infinity,
                                repeatType: "reverse",
                            }}
                        >
                            GET
                        </motion.p>
                        <motion.p
                            className="animated-text"
                            animate={{ color: ["#ecf0f1", "#9b59b6", "#e67e22", "#1abc9c"] }}
                            transition={{
                                duration: 4,
                                repeat: Infinity,
                                repeatType: "reverse",
                            }}
                        >
                            KNOWLEDGE
                        </motion.p>

                    </div>
                </div>
                <div className="slide">
                    <img src="Headers/slider3.png" alt="Slide 3" className="slider-image" />
                    <div className="text-overlay">
                        <motion.p
                            className="animated-text"
                            animate={{ color: ["#ecf0f1", "#3498db", "#e74c3c", "#2ecc71"] }}
                            transition={{
                                duration: 4,
                                repeat: Infinity,
                                repeatType: "reverse",
                            }}
                        >
                            SHARE 
                        </motion.p>
                        <motion.p
                            className="animated-text"
                            animate={{ color: ["#ecf0f1", "#9b59b6", "#e67e22", "#1abc9c"] }}
                            transition={{
                                duration: 4,
                                repeat: Infinity,
                                repeatType: "reverse",
                            }}
                        >
                           KNOWLEDGE
                        </motion.p>

                    </div>
                </div>


                <div className="slide">
                    <img src="Headers/slider4.png" alt="Slide 3" className="slider-image" />
                    <div className="text-overlay">
                        <motion.p
                            className="animated-text"
                            animate={{ color: ["#ecf0f1", "#3498db", "#e74c3c", "#2ecc71"] }}
                            transition={{
                                duration: 4,
                                repeat: Infinity,
                                repeatType: "reverse",
                            }}
                        >
                            GET
                        </motion.p>
                        <motion.p
                            className="animated-text"
                            animate={{ color: ["#ecf0f1", "#9b59b6", "#e67e22", "#1abc9c"] }}
                            transition={{
                                duration: 4,
                                repeat: Infinity,
                                repeatType: "reverse",
                            }}
                        >
                           PAID
                        </motion.p>

                    </div>
                </div>
            </Slider>
        </div>
    );
};

export default FullWidthSlider;
