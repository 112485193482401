import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function Notifications2() {
  const [notification, setNotification] = useState(null); // For storing the fetched notification
  const notificationId = useSelector((state) => state.allow.notificationId); // Notification ID from Redux
  console.log('notificationId ================================>>> > > > > > > >  ',notificationId)
  useEffect(() => {
    if (notificationId) {
      // Fetch notification details when notificationId is available
      const getNotification = async () => {
        try {
          const response = await axios.get('https://sell-skill.com/api/endpoints/getNotification', {
            params: { notificationId }
          });
          
          setNotification(response.data);
          console.log('response data ===========------------>> --------------->>> ',response.data)
        } catch (error) {
          console.error('Error fetching notification:', error);
        }
      };
      getNotification();
    }
  }, [notificationId]); // Refetch when notificationId changes

  if (!notification) {
    return <div>Loading notification...</div>; // Show loading state
  }

  return (
    <div>
      <h1>Notification Details</h1>
      <h2>Title: {notification.title}</h2>
      <p>Description: {notification.content}</p>
    </div>
  );
}

export default Notifications2;
