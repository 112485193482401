import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './posts.css';

function GetPosts() {
  const worldId = useSelector((state) => state.allow.worldId);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getPosts', { params: { worldId } });
      setPosts(response.data);
    };

    getPosts();
  }, [worldId]);

  const getPictureUrl = (picture) => {
    if (picture?.picture?.data) {
      const base64String = btoa(
        new Uint8Array(picture.picture.data.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );
      return `data:${picture.picture.contentType};base64,${base64String}`;
    }
    return '/default-avatar.png'; // Default image if no picture exists
  };

  const handleLikesClick = (postId) => {
    console.log(`Fetching likes for post: ${postId}`);
  };

  const handleCommentsClick = (postId) => {
    console.log(`Fetching comments for post: ${postId}`);
  };

  return (
    <div className="posts-container">
      {posts.map((post) => (
        <div key={post._id} className="post-card">
          <div className="post-header">
            <img
              src={getPictureUrl(post.poster.picture)}
              alt={post.poster.name}
              className="poster-picture"
            />
            <h3 className="poster-name">{post.poster.name}</h3>
          </div>
          {post.attachments[0]?.thumbnail && (
            <img
              src={post.attachments[0].thumbnail}
              alt="Post Thumbnail"
              className="post-thumbnail"
            />
          )}
          <div className="post-footer">
            <button className="likes-button" onClick={() => handleLikesClick(post._id)}>
              {post.likes.length} Likes
            </button>
            <button className="comments-button" onClick={() => handleCommentsClick(post._id)}>
              {post.comments.length} Comments
            </button>
            <button
              className="view-attachments-button"
              onClick={() => window.location.href = `/view-attachments?postId=${post._id}`}
            >
              View Attachments
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default GetPosts;
