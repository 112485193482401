// firebase.js

// Import necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
   apiKey: "AIzaSyBFH7SrOXpxr5o3O25lE-tBtXKDTpUKOzQ",
   authDomain: "sell-skill-79ed7.firebaseapp.com",
   projectId: "sell-skill-79ed7",
   storageBucket: "sell-skill-79ed7.appspot.com",
   messagingSenderId: "454122967288",
   appId: "1:454122967288:web:1150ef291cef6f98766b0d",
   measurementId: "G-ZC5R2NKGQZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(app);

// Initialize Firebase Messaging
const messaging = getMessaging(app);


// Function to request notification permission and get the FCM token
const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      console.log("Notification permission granted.");
      // Replace 'YOUR_PUBLIC_VAPID_KEY' with your actual public VAPID key
      const token = await getToken(messaging, { vapidKey: "BGHhsw80IA0WGmDiUgWUffZUAdFWzS3ppxo99VStxw6udJHgG0PGkHplCdNqaPmsvtXni5bugr4JNpa3OcOiVb8",

       });
      if (token) {
        console.log("FCM token:", token);
        return token; // You can save this token to your backend
      } else {
        console.log("No FCM token available. Request permission to generate one.");
      }
    } else {
      console.log("Unable to get notification permission.");
    }
  } catch (error) {
    console.error("An error occurred while retrieving token:", error);
  }
};

const onMessageListener = () =>
  new Promise((resolve) => {
      onMessage(messaging, (payload) => {
          resolve(payload);
      });
  });

export { app, storage, messaging, getToken,requestNotificationPermission, onMessageListener };
