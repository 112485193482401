// src/components/Upload.js
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, getDownloadURL , uploadBytes} from 'firebase/storage';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ElementSelector = ({ onSelect }) => {

  const elements = [
    { type: 'video', label: 'Video' },
    { type: 'photo', label: 'Photo' },
    { type: 'text', label: 'Text' },
    { type: 'pdf', label: 'PDF' },
    { type: 'quiz', label: 'Quiz' }
  ];

  return (
    <div style={{ width: '20%', padding: '1rem', borderRight: '1px solid #ccc' }}>
      <h3 style={{ color: '#ffffff' }}>Elements</h3>
      {elements.map((element) => (
        <div
          key={element.type}
          style={{
            cursor: 'pointer',
            padding: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            color: '#ffffff',
            transition: 'transform 0.2s, color 0.2s',
          }}
          onClick={() => onSelect(element.type)}
          onMouseOver={(e) => { e.target.style.color = '#ADD8E6'; e.target.style.transform = 'scale(1.1)'; }}
          onMouseOut={(e) => { e.target.style.color = '#ffffff'; e.target.style.transform = 'scale(1)'; }}
        >
          {element.label} <span style={{ marginLeft: '0.5rem', fontSize: '1.2em' }}>+</span>
        </div>
      ))}
    </div>
  );
};

const UploadWorldTrailer = () => {
  const [elements, setElements] = useState([]);
  const [lessonTitle, setLessonTitle] = useState(''); // New state for lessonTitle
  const [lessonDescription, setLessonDescription] = useState(''); // New state for lessonDescription
  const navigate = useNavigate()

  const addElementToPage = (type) => {
    setElements([...elements, { id: uuidv4(), type, content: '', choices: [], correctAnswer: '', order: elements.length+1 }]);
  };

  const removeElement = (index) => {
    const updatedElements = elements.filter((_, i) => i !== index).map((el, i) => ({ ...el, order: i + 1 }));
    setElements(updatedElements);
  };
  

  const updateElementContent = (index, content) => {
    const updatedElements = [...elements];
    updatedElements[index].content = content;
    setElements(updatedElements);
  };

  const updateQuizChoice = (elementIndex, choiceIndex, value) => {
    const updatedElements = [...elements];
    updatedElements[elementIndex].choices[choiceIndex] = value;
    setElements(updatedElements);
  };

  const setCorrectAnswer = (elementIndex, correctAnswer) => {
    const updatedElements = [...elements];
    updatedElements[elementIndex].correctAnswer = correctAnswer;
    setElements(updatedElements);
  };

  /////upload to firebase 
  const handleFileUpload = async (index, file) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `instructor_content/${file.name}_${uuidv4()}`);
      await uploadBytes(fileRef, file);
      const fileUrl = await getDownloadURL(fileRef);
      updateElementContent(index, fileUrl); // store URL in element content
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  
  const renderElement = (element, index) => {
    const commonInputProps = {
      value: element.content,
      onChange: (e) => updateElementContent(index, e.target.value),
      style: { marginBottom: '0.5rem', padding: '0.5rem', width: '100%' }
    };

    switch (element.type) {
      case 'video':
      case 'photo':
      case 'pdf':
        return (
          <input
            key={element.id}
            type="file"
            accept={element.type === 'video' ? "video/*" : element.type === 'photo' ? "image/*" : ".pdf"}
            onChange={(e) => handleFileUpload(index, e.target.files[0])}
            style={{ ...commonInputProps.style }}
          />
        );
      case 'text':
        return (
          <textarea
            key={element.id}
            placeholder="Enter text content"
            {...commonInputProps}
          />
        );
      case 'quiz':
        return (
          <div key={element.id} style={{ marginBottom: '1rem' }}>
            <input
              type="text"
              placeholder="Enter quiz question"
              {...commonInputProps}
            />
            {Array.from({ length: 4 }).map((_, i) => (
              <input
                key={i}
                type="text"
                placeholder={`Choice ${i + 1}`}
                value={element.choices[i] || ''}
                onChange={(e) => updateQuizChoice(index, i, e.target.value)}
                style={{ ...commonInputProps.style }}
              />
            ))}
            <div style={{ marginTop: '0.5rem' }}>
              Correct Answer:
              <select
                value={element.correctAnswer}
                onChange={(e) => setCorrectAnswer(index, e.target.value)}
                style={{ marginLeft: '0.5rem' }}
              >
                {element.choices.map((choice, i) => (
                  <option key={i} value={choice}>
                    {choice}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  
/////send to mongo db
const worldName = useSelector((state) => state.allow.worldName);
const levelNumber = useSelector((state) => state.allow.levelNumber);
const lessonNumber = useSelector((state) => state.allow.lessonNumber);

const saveCourseToFirebase = async () => {

  try {
    const modifiedElements = elements.map(el => ({
      type: el.type,
      content: el.content,
      choices: el.choices || [],
      correctAnswer: el.correctAnswer || "",
      order: el.order
    }));
    
    const response = await fetch('https://sell-skill.com/api/endpoints/uploadWorldTrailer', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        _id: uuidv4(),
        elements: modifiedElements,
        worldName,
        levelNumber,
        lessonNumber,
        lessonTitle, // Include lessonTitle
        lessonDescription, // Include lessonDescription
      }),
    });
    const result = await response.json();
    console.log('Course saved:', result);
    navigate ('/add-level')
  } catch (error) {
    console.error('Error saving course:', error);
  }
};


  return (
    <div style={{
      display: 'flex',
      height: '100vh',
      background: 'linear-gradient(to bottom right, #000000, #000080)',
      color: '#ffffff'
    }}>
      <ElementSelector onSelect={addElementToPage} />
      <div style={{ flex: 1, padding: '1rem', minHeight: '300px' }}>
        <h3>Lesson Workspace</h3>
        <input
          type="text"
          placeholder="Enter lesson title"
          value={lessonTitle}
          onChange={(e) => setLessonTitle(e.target.value)}
          style={{ width: '100%', marginBottom: '1rem', padding: '0.5rem' }}
        />
        <textarea
          placeholder="Enter lesson description"
          value={lessonDescription}
          onChange={(e) => setLessonDescription(e.target.value)}
          style={{ width: '100%', marginBottom: '1rem', padding: '0.5rem' }}
        />
        {elements.length === 0 && <p>Select elements to add to your lesson.</p>}
        {elements.map((element, index) => (
          <div
            key={element.id}
            style={{
              marginBottom: '1rem',
              padding: '0.5rem',
              border: '1px dashed #aaa',
              position: 'relative',
            }}
          >
            {renderElement(element, index)}
            <button
              onClick={() => removeElement(index)}
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                color: '#ffffff',
                background: '#ff0000',
                border: 'none',
                cursor: 'pointer',
                padding: '0.3rem 0.5rem'
              }}
            >
              Remove
            </button>
          </div>
        ))}
        <button onClick={saveCourseToFirebase} style={{
          padding: '0.5rem 1rem',
          backgroundColor: '#000080',
          color: '#ffffff',
          border: 'none',
          cursor: 'pointer'
        }}>Save Lesson</button>
      </div>
    </div>
  );
};

export default UploadWorldTrailer;
