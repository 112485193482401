import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setWithId } from '../../../reducers/reducers';
import { useNavigate } from 'react-router-dom'

function SetWithIdForStudentsChat() {
    const [withId, setWithIdd] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSetWithId = () => {

        dispatch(setWithId(withId))
        navigate('/student-chat')
    }
  return (
    <div>
      <input 
      placeholder='enter with id'
      onChange={(e) => setWithIdd(e.target.value)}
      />
      <button onClick = {handleSetWithId}>dispatch withId</button>
    </div>
  )
}

export default SetWithIdForStudentsChat
