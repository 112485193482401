import React, { useState } from 'react'
import { setWorldId } from '../../../reducers/reducers'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

function SetWorldId() {
    const navigate = useNavigate()
    const dispatch= useDispatch()
    const [worldId, setWorldIdd] = useState('')
    const DNTMYAdvisor = () => {
        dispatch(setWorldId(worldId))
        navigate('/my-advisor-chat')
    }
    const DNTMyStudents1 = () => {
        dispatch(setWorldId(worldId))
        navigate('/my-students-chats')
    }
    const DNTWorldMessages = () => {
        dispatch(setWorldId(worldId))
        navigate('/my-world-chats')
    }
    const DNTWorldPosts = () => {
      dispatch(setWorldId(worldId))
      navigate('/get-world-posts')
    }
  return (
    <div>
        <input
        placeholder='enter worldId'
        onChange={(e) => setWorldIdd(e.target.value)}
        />
      <button onClick={DNTMYAdvisor}>set worldId and navigate to myAdvisor messages</button>
      <button onClick={DNTMyStudents1}>set worldId and navigate to myStudents messages</button>
      <button onClick={DNTWorldMessages}>set worldId and navigate to worldMessages</button>
      <button onClick={DNTWorldPosts}>set worldId and navigate to worldPosts</button>
    </div>
  )
}

export default SetWorldId
