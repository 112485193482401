import React, { useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OuterCard = styled(Card)`
  background-color: white; /* White for the card background */
  color: #2c3e50; /* Dark text */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
`;

const InnerCard = styled(Card)`
  background-color: #f8f9fa; /* Light gray for inner card */
  color: #2c3e50; /* Dark text */
  border-radius: 10px;
  padding: 20px;
`;

const Title = styled(Card.Title)`
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
  color: #27ae60; /* Green for the title */
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: 150px;
  margin: 5px;
  background-color: ${(props) => (props.selected ? "#27ae60" : "#2c3e50")}; /* Green for selected, Dark otherwise */
  border-color: ${(props) => (props.selected ? "#27ae60" : "#2c3e50")}; /* Border matches background */
  color: ${(props) => (props.selected ? "white" : "white")}; /* White text */
  border-radius: 5px;

  &:hover {
    background-color: #27ae60; /* Green on hover */
    color: white; /* White text on hover */
  }
`;

const Input = styled(Form.Control)`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #bdc3c7; /* Light gray for input borders */
  background-color: white; /* White background for input */
  color: #2c3e50; /* Dark text for input */
`;

const AddOtherButton = styled(Button)`
  width: 100%;
  background-color: #2c3e50; /* Dark background */
  border-color: #2c3e50; /* Dark border */
  color: white; /* White text */
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;

  &:hover {
    background-color: #27ae60; /* Green on hover */
    color: white; /* White text on hover */
  }
`;

const NextButton = styled(Button)`
  display: block;
  width: 100%;
  background-color: #27ae60; /* Green for the button */
  border-color: #27ae60; /* Green for border */
  color: white; /* White text */
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background-color: #2c3e50; /* Dark on hover */
    color: white; /* White text on hover */
  }
`;

function Categories() {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [others, setOthers] = useState("");

  const handleNextClick = async () => {
    try {
      await axios.post("https://sell-skill.com/api/endpoints/category", category);
      navigate("/certifications");
    } catch (error) {
      console.error("Error saving categories:", error);
    }
  };

  const handleCategoryClick = (cat) => {
    setCategory((prevCategories) => {
      if (prevCategories.includes(cat)) {
        return prevCategories.filter((c) => c !== cat);
      } else {
        return [...prevCategories, cat];
      }
    });
  };

  const handleAddOther = () => {
    if (others.trim() && !category.includes(others)) {
      setCategory((prevCategories) => [...prevCategories, others]);
      setOthers("");
    }
  };

  return (
    <StyledContainer>
      <OuterCard>
        <InnerCard>
          <Title>
            <b>Which category are you interested in?</b>
          </Title>
          <ButtonsContainer>
            {[
              "Affiliate marketing",
              "Digital marketing",
              "Coding",
              "AI",
              "Medicine",
              "Health & fitness",
              "Legal & financial advice",
              "Languages",
              "Hobbies & skills",
              "Cryptocurrency",
              "Home improvement & DIY",
              "Creative services",
              "Education & tutoring",
              "Entertainment & Leisure",
              "Dropshipping",
              "Pet services",
            ].map((cat) => (
              <StyledButton
                key={cat}
                selected={category.includes(cat)}
                onClick={() => handleCategoryClick(cat)}
              >
                {cat}
              </StyledButton>
            ))}
          </ButtonsContainer>
          <Input
            type="text"
            value={others}
            onChange={(e) => setOthers(e.target.value)}
            placeholder="Enter other field ..."
          />
          <AddOtherButton onClick={handleAddOther}>Add Other</AddOtherButton>
          <NextButton onClick={handleNextClick}>Next</NextButton>
        </InnerCard>
      </OuterCard>
    </StyledContainer>
  );
}

export default Categories;





















// import React, { useState } from 'react';
// import { Button, Card, Container, Form } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import styled, { createGlobalStyle } from 'styled-components';

// const GlobalStyle = createGlobalStyle`
//   body {
//     margin: 0;
//     padding: 0;
//     background-color: #000000; /* Black background */
//     color: #FFFFFF; /* White text */
//     font-family: Arial, Helvetica, sans-serif;
//   }
// `;

// const StyledContainer = styled(Container)`
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

// const OuterCard = styled(Card)`
//   background-color: #1A1A1A; /* Dark gray for the card background */
//   color: #FFFFFF; /* White text */
//   border-radius: 20px;
//   padding: 20px;
//   box-shadow: 0 4px 20px rgba(255, 255, 255, 0.1);
//   width: 90%;
//   max-width: 800px;
// `;

// const InnerCard = styled(Card)`
//   background-color: #0048BA; /* Deep blue for inner card */
//   color: #FFFFFF; /* White text */
//   border-radius: 20px;
//   padding: 20px;
// `;

// const Title = styled(Card.Title)`
//   font-size: 30px;
//   text-align: center;
//   margin-bottom: 20px;
//   color: #00FFFF; /* Cyan for the title */
// `;

// const ButtonsContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   margin-bottom: 20px;
// `;

// const StyledButton = styled(Button)`
//   width: 150px;
//   margin: 5px;
//   background-color: ${(props) => (props.selected ? '#00FFFF' : '#0048BA')}; /* Cyan for selected, Deep Blue otherwise */
//   border-color: #0048BA; /* Deep Blue for border */
//   color: ${(props) => (props.selected ? '#0048BA' : '#FFFFFF')}; /* Deep Blue text for selected, White otherwise */
//   border-radius: 8px;

//   &:hover {
//     background-color: #00FFFF; /* Cyan on hover */
//     color: #0048BA; /* Deep Blue text on hover */
//   }
// `;

// const Input = styled(Form.Control)`
//   margin-top: 20px;
//   margin-bottom: 20px;
//   padding: 10px;
//   border-radius: 8px;
//   border: 1px solid #FFFFFF; /* White for input borders */
//   background-color: #1A1A1A; /* Dark gray background for input */
//   color: #FFFFFF; /* White text for input */
// `;

// const NextButton = styled(Button)`
//   display: block;
//   width: 100%;
//   background-color: #00FFFF; /* Cyan for the button */
//   border-color: #00FFFF; /* Cyan for border */
//   color: #0048BA; /* Deep Blue text */
//   border-radius: 8px;
//   font-size: 16px;
//   font-weight: bold;

//   &:hover {
//     background-color: #0048BA; /* Deep Blue on hover */
//     color: #FFFFFF; /* White text on hover */
//   }
// `;

// function Categories() {
//   const navigate = useNavigate();
//   const [category, setCategory] = useState([]);
//   const [others, setOthers] = useState('');

//   const handleNextClick = async () => {
//     navigate("/certifications");
//     console.log('categories ===> ' + category);
//     await axios.post('https://sell-skill.com/api/endpoints/category', category);
//   };

//   const handleCategoryClick = (cat) => {
//     setCategory((prevCategories) => {
//       if (prevCategories.includes(cat)) {
//         return prevCategories.filter((c) => c !== cat);
//       } else {
//         return [...prevCategories, cat];
//       }
//     });
//   };

//   const handleAddOther = () => {
//     if (others.trim() && !category.includes(others)) {
//       setCategory((prevCategories) => [...prevCategories, others]);
//       setOthers('');
//     }
//   };

//   return (
//     <>
//       <GlobalStyle />
//       <StyledContainer>
//         <OuterCard>
//           <InnerCard>
//             <Title>
//               <b>Which category are you interested in?</b>
//             </Title>
//             <ButtonsContainer>
//               {['Affiliate marketing', 'Digital marketing', 'Coding', 'AI', 'Medicine', 'Health & fitness', 'Legal & financial advice', 'Languages', 'Hobbies & skills', 'Cryptocurrency', 'Home improvement & DIY', 'Creative services', 'Education & tutoring', 'Entertainment & Leisure', 'Dropshipping', 'Pet services'].map((cat) => (
//                 <StyledButton
//                   key={cat}
//                   selected={category.includes(cat)}
//                   onClick={() => handleCategoryClick(cat)}
//                 >
//                   {cat}
//                 </StyledButton>
//               ))}
//             </ButtonsContainer>
//             <Input
//               type="text"
//               value={others}
//               onChange={(e) => setOthers(e.target.value)}
//               placeholder="Enter other field ..."
//             />
//             <StyledButton onClick={handleAddOther}>Add Other</StyledButton>
//             <NextButton onClick={handleNextClick}>Next</NextButton>
//           </InnerCard>
//         </OuterCard>
//       </StyledContainer>
//     </>
//   );
// }

// export default Categories;
