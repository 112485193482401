import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLessonId } from '../../../reducers/reducers';
import styled from 'styled-components';
import { FaChevronUp, FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Button } from 'react-bootstrap';

function Lessons() {
  const [lessons, setLessons] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const worldName = useSelector((state) => state.allow.worldName);
  const levelNumber = useSelector((state) => state.allow.levelNumber);
  const worldId = useSelector((state) => state.allow.worldId);
  const levelId = useSelector((state) => state.allow.levelId);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
};

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getLessons`, {
          params: { worldId, levelId },
        });
        setLessons(response.data);
        console.log('lessons ===========> > > >  > ', response.data);
      } catch (error) {
        console.error('Error fetching lessons:', error);
      }
    };

    fetchLessons();
  }, [worldId, levelId]);

  const handleShowLesson = (id) => {
    console.log('lesson id ================--------------------------->>>> >>>> > >>   ', id);
    dispatch(setLessonId(id));
    navigate('/resources');
  };

  return (
    <PageContainer>
      <Navbar>
        <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
        <NavLinks>
          <a href="/my-learning">My Learning</a>
          <a href="/my-earning">My Earning</a>
          <a href="/my-cart">My Cart</a>
          <a href="/certifications">Certifications</a>
          <a href="/exams">Exams</a>
        </NavLinks>
      </Navbar>
      <ContentContainer>
        <Sidebar>
          <SectionHeader onClick={() => navigate('/worlds')}>Back to Worlds</SectionHeader>
          <SectionHeader onClick={() => navigate('/levels')}>Back to Levels</SectionHeader>
        </Sidebar>
        <ToggleSidebarButton onClick={toggleSidebar}>
         {sidebarVisible ? <FaChevronLeft /> : <FaChevronRight />}
     </ToggleSidebarButton>
        <MainContent>
          <h1>Lessons in {worldName} - Level {levelNumber}</h1>
          <LessonsGrid>
            {lessons.map((lesson, index) => (
              <LessonCard key={index}>
                <h2>{lesson.name}</h2>
                <p>{lesson.description}</p>
                <Actions>
                  <Button onClick={() => handleShowLesson(lesson._id)}>Get in</Button>
                </Actions>
              </LessonCard>
            ))}
          </LessonsGrid>
        </MainContent>
      </ContentContainer>
    </PageContainer>
  );
}

export default Lessons;



const LessonsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
`;

const LessonCard = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #2c3e50;
  }

  p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #7f8c8d;
  }
`;
const PageContainer = styled.div`
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 60px 20px 20px; /* Add top padding to account for the navbar height */
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const ContentContainer = styled.div`
    display: flex;
    position: relative; /* Allows absolute positioning of the toggle button */
    width: 100%;
    min-height: calc(100vh - 60px); /* Adjust to ensure it spans the full viewport height minus the navbar */
`;

const Sidebar = styled.div`
    width: 300px;
    position: fixed; /* Fixes it to the left side */
    top: 60px; /* Matches the height of the navbar */
    left: 0;
    bottom: 0; /* Ensures it spans the full height */
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
    overflow-y: auto; /* Ensures content is scrollable */
`;


const Navbar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2c3e50;
    color: white;
    position: fixed; /* Keep the navbar fixed at the top */
    top: 0;
    left: 0;
    width: 100%; /* Make it span the entire width */
    z-index: 1000; /* Ensure it stays above other elements */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
`;


const LeftNav = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.div`
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
`;

const NavLinks = styled.div`
    margin-left: 20px;
    display: flex;
    gap: 15px;

    a {
        color: white;
        text-decoration: none;
    }
`;

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
    }
`;

const NavIcons = styled.div`
    display: flex;
    gap: 15px;
`;

const ProfileWrapper = styled.div`
    position: relative;
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;

const SectionHeader = styled.h2`
    font-size: 18px;
    color: #2c3e50;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
        font-size: 20px;
        color: #2980b9;
    }
`;

const UploadButton = styled(Button)`
    background-color: #27ae60;
    color: white;
    border: none;
`;

const WorldsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
`;

const WorldCard = styled.div`
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-10px);
    }
`;

const Publisher = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    p {
        font-size: 14px;
        color: #2c3e50;
        margin: 0;
    }
`;

const Thumbnail = styled.img`
    width: 100%;
    height: 150px;
    object-fit: cover;
`;

const Info = styled.div`
    padding: 15px;

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
        color: #2c3e50;
    }

    p {
        font-size: 14px;
        color: #7f8c8d;
        margin-bottom: 10px;
    }
`;

const Rating = styled.div`
    display: flex;
`;
const Actions = styled.div`
    display: flex;
    flex-direction: column; /* Stack ActionRow elements vertically */
    gap: 10px; /* Add spacing between rows */
`;




const MainContent = styled.div`
    margin-left: 300px; /* Ensures content starts after the fixed sidebar */
    flex-grow: 1; /* Ensures MainContent takes remaining space */
    padding: 20px;
`;



const GreenButton = styled.button`
    background-color: #27ae60 !important; /* Green color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #219150 !important; /* Darker green on hover */
    }
`;

const NavColorButton = styled.button`
    background-color: #2c3e50 !important; /* Navbar color */
    color: white !important;
    border: none;
    width: 48%; /* Ensures two buttons fit in one row */
    margin: 2px; /* Adds spacing between buttons */
    padding: 5px 10px;
    border-radius: 5px;

    &:hover {
        background-color: #1a252f !important; /* Darker navbar color on hover */
    }
`;

const ActionRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between rows */
`;
const ToggleSidebarButton = styled.div`
    position: fixed; /* Ensures it's positioned relative to the viewport */
    top: 100px; /* Adjust to set vertical placement */
    left: ${({ sidebarVisible }) => (sidebarVisible ? '300px' : '0')}; /* Dynamic placement based on sidebar visibility */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    background-color: #ddd;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000; /* Ensures it stays above other elements */
    transition: left 0.3s ease; /* Smooth transition when toggling */
`;











// //To show inside lessons thumbnails and level description



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux';

// import { useNavigate } from 'react-router-dom';
// import { setLessonId } from '../../../reducers/reducers';

// function Lessons() { // Pass skillerId as a prop
//   const [lessons, setLessons] = useState([]);
//   const [isLessonCompleted, setIsLessonCompleted ] = useState(false) // send to back
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   //check if the world name is selected
//   const worldName = useSelector((state) => state.allow.worldName)
//   const levelNumber = useSelector((state) => state.allow.levelNumber)
//   const worldId = useSelector((state) => state.allow.worldId);
//   const levelId = useSelector((state) => state.allow.levelId);

//   useEffect(() => {
//     const fetchLevels = async () => {
//       try {
//         //to get lessons from the selected level
//         const response = await axios.get(`https://sell-skill.com/api/endpoints/getLessons`, {
//           params: {worldId, levelId}
//         });
//         setLessons(response.data);
//         console.log('lessons ===========> > > >  > ',response.data)

//       } catch (error) {
//         console.error("Error fetching worlds:", error);
//       }
//     };


//       fetchLevels();
    
//   },[]);
//   const handleShowLesson=  (id) => {
//     console.log('lesson id ================--------------------------->>>> >>>> > >>   ',id)
//     dispatch(setLessonId(id))
//     navigate ('/resources')
//   }




//   return (
//     <div>
//       <h1>My Lessons</h1>
//       <button>choose || chat</button>
//       <div className="worlds-container">
//         {lessons.map((lesson, index) => (
//           <div key={index} className="world-card">
//             <h2>{lesson.name}</h2>
//             <h2>{lesson.title}</h2>
//             <p>{lesson.description}</p>
            
//             <button onClick={() => handleShowLesson(lesson._id)}>Get in</button>

//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Lessons;
