import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function Certifications2() {
    const certificationId = useSelector((state) => state.allow.certificationId);
    console.log('certificationId =====================------------->> >  >>  ',certificationId)
    const [certification, setCertification] = useState('');

    useEffect(() => {
        const getCertificate = async () => {
            try {
                const response = await axios.get('https://sell-skill.com/api/endpoints/getCertificate', {
                    params: { certificationId },
                    responseType: 'blob', // Ensure we receive the binary data as a Blob
                });
                // Convert the binary data into a URL that can be used in the <img> tag
                const imageUrl = URL.createObjectURL(response.data);
                setCertification(imageUrl);
            } catch (error) {
                console.error('Error fetching the certification:', error);
            }
        };

        if (certificationId) {
            getCertificate();
        }
    }, [certificationId]);

    return (
        <div>
            {certification ? (
                <img src={certification} alt="Certification" style={{ maxWidth: '100%', height: 'auto' }} />
            ) : (
                <p>Loading certification...</p>
            )}
        </div>
    );
}

export default Certifications2;
