import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNotificatioinId } from '../reducers/reducers';
import { useNavigate } from 'react-router-dom';

function Notifications1() {
  const [allNotifications, setAllNotifications] = useState([]);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    // Fetch all notifications from the backend
    const getAllNotifications = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getAllNotifications');
        setAllNotifications(response.data);
        console.log('response data ============ ---------> =----------------=-------->> ',response.data)
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };
    getAllNotifications();
  }, []);

  // Dispatch the ID of the clicked notification
  const handleNotificationClick = (id) => {
    dispatch(setNotificatioinId(id))
    navigate('/notification')
    console.log('Notification ID:', id); // Replace this with a dispatch or your desired action
  };

  return (
    <div>
      <h1>Notifications</h1>
      <ul>
        {allNotifications.map((notification) => (
          <li
            key={notification._id}
            onClick={() => handleNotificationClick(notification._id)}
            style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
          >
            {notification.title}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Notifications1;
