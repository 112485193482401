import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { Button, Card, Col, Container, Form, ListGroup, Modal, Row } from "react-bootstrap";
import styled from "styled-components";

function EditPost() {
  const worldId = useSelector((state) => state.allow.worldId);
  const postId = useSelector((state) => state.allow.postId);
  
  const [resources, setResources] = useState([]);
  const [certainPost, setCertainPost] = useState({});
  const [postTitle, setPostTitle] = useState("");
  const [postDescription, setPostDescription] = useState("");
  const [postThumbnail, setPostThumbnail] = useState(null);

  useEffect(() => {
    const getCertainPost = async () => {
      const response = await axios.get("https://sell-skill.com/api/endpoints/getCertainPost", {
        params: { worldId, postId },
      });
      const post = response.data;
      setCertainPost(post);
      setPostTitle(post.title);
      setPostDescription(post.description);
           setResources([
             ...(post.photos || []).map((item) => ({ type: "photo", content: item.contentUrl })),
             ...(post.videos || []).map((item) => ({ type: "video", content: item.contentUrl })),
             ...(post.pdfs || []).map((item) => ({ type: "pdf", content: item.contentUrl })),
             ...(post.texts || []).map((item) => ({ type: "text", content: item })),
             ...(post.quizs || []).map((item) => ({
               type: "quiz",
               content: item.content,
               choices: item.choices,
               correctAnswer: item.correctAnswer,
             })),
           ]);
      setPostThumbnail(post.thumbnail);
    };
    getCertainPost();
  }, [worldId, postId]);

  const handleFileUpload = async (file) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `posts/${uuidv4()}_${file.name}`);
      await uploadBytes(fileRef, file);
      return await getDownloadURL(fileRef);
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const handleAddResource = async (type) => {
    let content = null;

    if (["photo", "video", "pdf"].includes(type)) {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.accept = type === "photo" ? "image/*" : type === "video" ? "video/*" : ".pdf";
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const url = await handleFileUpload(file);
          if (url) {
            setResources([...resources, { type, content: url }]);
          }
        }
      };
      fileInput.click();
    } else {
      content = prompt(`Enter ${type} content:`);
      if (content) {
        if (type === "quiz") {
          const choices = prompt("Enter quiz choices, separated by commas").split(",");
          const correctAnswer = prompt("Enter the correct answer for the quiz");
          setResources([...resources, { type, content, choices, correctAnswer }]);
        } else {
          setResources([...resources, { type, content }]);
        }
      }
    }
  };

  const handleRemoveResource = (index) => {
    setResources(resources.filter((_, idx) => idx !== index));
  };

  const handleSubmit = async () => {
    let thumbnailUrl = certainPost.thumbnail;

    if (postThumbnail && typeof postThumbnail !== "string") {
      thumbnailUrl = await handleFileUpload(postThumbnail);
    }

    try {
      await axios.patch("https://sell-skill.com/api/endpoints/WeditPost", {
        _id: postId,
        worldId,
        postTitle,
        postDescription,
        postThumbnail: thumbnailUrl,
        resources,
      });
      alert("Post updated successfully!");
    } catch (error) {
      console.error("Error updating post:", error);
      alert("Failed to update post.");
    }
  };

  return (
    <Container fluid>
      <Row>
        {/* Sidebar */}
        <Sidebar>
      <h3>Sidebar</h3>
      <button
        className="btn btn-light"
        onClick={() => handleAddResource("photo")}
      >
        Add Photo
      </button>
      <button
        className="btn btn-light"
        onClick={() => handleAddResource("video")}
      >
        Add Video
      </button>
      <button
        className="btn btn-light"
        onClick={() => handleAddResource("pdf")}
      >
        Add PDF
      </button>
      <button
        className="btn btn-light"
        onClick={() => handleAddResource("text")}
      >
        Add Text
      </button>
      <button
        className="btn btn-light"
        onClick={() => handleAddResource("quiz")}
      >
        Add Quiz
      </button>
    </Sidebar>

        {/* Main Content */}
        <Col md={9}>
          <Card>
            <Card.Body>
              <Card.Title>Edit Post</Card.Title>
              <Form.Group controlId="postTitle">
                <Form.Label>Post Title</Form.Label>
                <Form.Control
                  type="text"
                  value={postTitle}
                  onChange={(e) => setPostTitle(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="postDescription" className="mt-3">
                <Form.Label>Post Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={postDescription}
                  onChange={(e) => setPostDescription(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="postThumbnail" className="mt-3">
                <Form.Label>Post Thumbnail</Form.Label>
                <Form.Control
                  type="file"
                  onChange={(e) => setPostThumbnail(e.target.files[0])}
                />
              </Form.Group>
              {postThumbnail && (
                <img
                  src={
                    typeof postThumbnail === "string" ? postThumbnail : URL.createObjectURL(postThumbnail)
                  }
                  alt="Thumbnail"
                  className="img-fluid mt-3"
                  style={{ maxWidth: "200px" }}
                />
              )}
              <Card className="mt-3">
                <Card.Header>Resources</Card.Header>
                <ListGroup variant="flush">
                  {resources.map((res, idx) => (
                    <ListGroup.Item key={idx}>
                      {res.type}:{" "}
                      {res.type === "photo" && <img src={res.content} alt="Photo" style={{ maxWidth: "100px" }} />}
                      {res.type === "video" && (
                        <video controls style={{ maxWidth: "200px" }}>
                          <source src={res.content} type="video/mp4" />
                        </video>
                      )}
                      {res.type === "pdf" && (
                        <a href={res.content} target="_blank" rel="noopener noreferrer">
                          View PDF
                        </a>
                      )}
                      {res.type === "text" && <p>{res.content}</p>}
                      {res.type === "quiz" && (
                        <>
                          <p>{res.content}</p>
                          <ul>
                            {res.choices?.map((choice, i) => (
                              <li key={i}>{choice}</li>
                            ))}
                          </ul>
                          <p>Correct Answer: {res.correctAnswer}</p>
                        </>
                      )}
                      <AiOutlineDelete
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        onClick={() => handleRemoveResource(idx)}
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card>
              <Button variant="success" className="mt-3" onClick={handleSubmit}>
                Save Changes
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default EditPost;


const Sidebar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100vh;
  background-color: #2c3e50;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
`;