import axios from 'axios'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';
function SetSubscribedInButton() {

    const navigate = useNavigate();
    const purchaseId = uuidv4()
    const [worldId, setWorldIdd] = useState('')
    const setToSubscibedIn = async() => {
      const response =  await axios.patch('https://sell-skill.com/api/endpoints/paidWorld',          {  worldId, purchaseId})
      navigate('/levels')
    }
  return (
    <div>
      <input 
      placeholdar="enter worldId"
      onChange={(e) => setWorldIdd(e.target.value)}
      />
      <button onClick={setToSubscibedIn}>set to subscribedIn</button>
    </div>
  )
}

export default SetSubscribedInButton
