import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "react-bootstrap";
import { v4 as uuidv4 } from 'uuid';

function GExams4Advisor() {
  const certificationId = uuidv4();
  const purchaseId = uuidv4();
  const [exams, setExams] = useState([]);
  const [worldId, setWorldId] = useState('')
  const [levelId, setLevelId] = useState('')
  // State for individual exam inputs
  const [examInputs, setExamInputs] = useState({});

  const handleInputChange = (examId, field, value) => {
    setExamInputs((prev) => ({
      ...prev,
      [examId]: {
        ...prev[examId],
        [field]: value,
      },
    }));
  };

  const handleInsertExam = async (examId, studentId) => {
    const inputs = examInputs[examId] || {};
    const { examTime, passingGrade, examWillBeAs } = inputs;
    try {
      await axios.post(
        "https://sell-skill.com/api/endpoints/setTimeLinkPassingGExamType",
        {

          examId,
          studentId,
          examTime,
          passingGrade,
          examWillBeAs,
          worldId
        }
      );
      alert("Exam details inserted successfully!");
    } catch (err) {
      console.error("Error inserting exam details:", err);
    }
  };

  const handleSendGrade = async (examId, studentId) => {
    const inputs = examInputs[examId] || {};
    const { grade, comment } = inputs;
    try {
      await axios.post(
        "https://sell-skill.com/api/endpoints/sendGradeComment",
        {           certificationId,examId,worldId, levelId, studentId, grade, comment, purchaseId }
      );
      alert("Grade and comment sent successfully!");
    } catch (err) {
      console.error("Error sending grade and comment:", err);
    }
  };

  useEffect(() => {
    const fetchExams = async () => {
      try {
        const { data } = await axios.get(
          "https://sell-skill.com/api/endpoints/getExams4Advisor"
        );
        setExams(data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchExams();
  }, []);

  return (
    <div className="container mt-4">
      <h1>Exams for Advisor</h1>
      {exams.map((exam) => {
        const inputs = examInputs[exam._id] || {};
        return (
          <div key={exam._id} className="border p-3 mb-3">
            <h3>{exam.title}</h3>
            <p>Student name: {exam.studentName}</p>
            <p>World: {exam.worldName}</p>
            <p>Level: {exam.levelNumber}</p>
            <p>Time: {exam.examTime || "Not Set"}</p>
            <p>Link: {exam.examLink || "Not Set"}</p>

            <div className="mb-3">
              <input
                type="datetime-local"
                className="form-control"
                value={inputs.examTime || ""}
                onChange={(e) =>
                  handleInputChange(exam._id, "examTime", e.target.value)
                }
                placeholder="Set exam time"
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Exam will be as"
                value={inputs.examWillBeAs || ""}
                onChange={(e) =>
                  handleInputChange(exam._id, "examWillBeAs", e.target.value)
                }
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Passing grade"
                value={inputs.passingGrade || ""}
                onChange={(e) =>
                  handleInputChange(exam._id, "passingGrade", e.target.value)
                }
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter grade"
                value={inputs.grade || ""}
                onChange={(e) =>
                  handleInputChange(exam._id, "grade", e.target.value)
                }
              />
            </div>

            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter comment"
                value={inputs.comment || ""}
                onChange={(e) =>
                  handleInputChange(exam._id, "comment", e.target.value)
                }
              />
            </div>


            <div className="d-flex gap-2">
              <Button
                onClick={() => handleInsertExam(exam._id, exam.studentId)}
                variant="primary"
              >
                Insert Exam
              </Button>
              <Button
                onClick={() => handleSendGrade(exam._id, exam.studentId)}
                variant="success"
              >
                Send Grade
              </Button>
              <input 
            placeholder="enter worldId"
            onChange={(e) => setWorldId(e.target.value)}
            />

            <input 
            
            placeholder="enter levelId"
            onChange={(e) => setLevelId(e.target.value)}
            />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default GExams4Advisor;
