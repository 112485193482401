import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setInstructorId, setWorldId, setWorldName,  } from '../../reducers/reducers';

import styled from 'styled-components';

function SubscribedIn() {
  const [worlds, setWorlds] = useState([]);
  const [profilePictureSrc, setProfilePictureSrc] = useState('');
  const [showDropdown, setShowDropdown] = useState(false); // State for dropdown visibility
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddWorld = () => {
    navigate('/add-world');
  };

  const handleLogout = async () => {
    localStorage.removeItem('clientToken');
    navigate('/');
  };

  useEffect(() => {
    const fetchWorlds = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getSubscribedIn');
        setWorlds(response.data);
        console.log('response.data from fetchworlds subscribedIn ============----------------=-=-=-=-=-=-==-= :::::::>>>>>> > >  ',response.data)
      } catch (error) {
        console.error('Error fetching worlds:', error);
      }
    };

    const fetchProfilePicture = async () => {
      try {
        const response = await axios.get(
          'https://sell-skill.com/api/endpoints/getSkillerProfilePicture',
          { responseType: 'blob' }
        );
        const url = URL.createObjectURL(response.data);
        setProfilePictureSrc(url);
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      }
    };

    fetchWorlds();
    fetchProfilePicture();
  }, []);

  const handleGetInWorld = (worldName, worldId, instructorId) => {
    dispatch(setWorldName(worldName));
    dispatch(setWorldId(worldId));
    dispatch(setInstructorId(instructorId));
    navigate('/set-subscirbed-in-to-true');
  };

  const handleshowDescription = (worldName, worldId) => {
    dispatch(setWorldName(worldName));
    dispatch(setWorldId(worldId));
    navigate('/show-description');
  };

  return (
    <>
      {/* Navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: '#1E90FF' }}>
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            Sell-Skill
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href="/my-learning">My Learning</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/my-earnings">My Earnings</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/chats">Chats</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/notifications">Notifications</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/exams">Exams</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/certifications">Certifications</a>
              </li>
            </ul>
            <ProfileContainer
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
            >
              {profilePictureSrc ? (
                <ProfileImage src={profilePictureSrc} alt="Profile" />
              ) : (
                <ProfileImage src="/images/NormalProfile.jpg" />
              )}
              {showDropdown && (
                <DropdownMenu>
                  <ul>
                    <li onClick={() => navigate('/my-profile')}>My Profile</li>
                    <li onClick={() => navigate('/my-learning')}>My Learning</li>
                    <li onClick={() => navigate('/notifications')}>Notifications</li>
                    <li onClick={() => navigate('/chats')}>Messages</li>
                    <li onClick={handleLogout}>Log Out</li>
                  </ul>
                </DropdownMenu>
              )}
            </ProfileContainer>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div
        className="container-fluid text-white"
        style={{
          backgroundColor: 'black',
          minHeight: '100vh',
          padding: '2rem',
          width: '100vw',
          overflowX: 'hidden',
        }}
      >
        <h1 className="text-center mb-4" style={{ color: '#1E90FF' }}>
          My Worlds
        </h1>
        <button
          className="btn mb-4"
          style={{ backgroundColor: '#1E90FF', color: 'white', border: 'none' }}
          onClick={handleAddWorld}
        >
          Add World
        </button>
        <div className="row">
          {worlds.map((world, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div
                className="card h-100 text-dark world-card"
                style={{
                  backgroundColor: '#1E90FF',
                  color: 'white',
                  border: '2px solid #1E90FF',
                  borderRadius: '10px',
                  transition: 'transform 0.3s',
                }}
              >
                <div className="card-body">
                  <h2 className="card-title text-center">{world.worldName}</h2>
                  <p className="card-text text-center">{world.worldDescription}</p>
                  {world.worldThumbnail && (
                    <img
                      src={world.worldThumbnail}
                      alt={`${world.worldName} Thumbnail`}
                      className="card-img-top mb-3"
                      style={{
                        borderRadius: '10px',
                        maxHeight: '200px',
                        objectFit: 'cover',
                      }}
                    />
                  )}
                  <div className="d-flex justify-content-around">
                    <button
                      className="btn btn-success"
                      style={{ backgroundColor: '#32CD32', border: 'none' }}
                      onClick={() => handleGetInWorld(world.worldName, world._id, world.instructorId)}
                    >
                      Get In
                    </button>

                    <button
                      className="btn btn-success"
                      style={{ backgroundColor: '#32CD32', border: 'none' }}
                      onClick={() => handleGetInWorld(world.worldName, world._id, world.instructorId)}
                    >
                      be an advisor if he passed the toBeAnAdvisorLevel exam it will be as available ,  not available
                    </button>
                    <button
                      className="btn btn-warning"
                      style={{ backgroundColor: '#FFA500', border: 'none' }}
                      onClick={() => handleshowDescription(world.worldName, world._id)}
                    >
                      Show Description
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default SubscribedIn;

// Styled Components
const ProfileContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #333;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 9999;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px;
      cursor: pointer;
      color: white;

      &:hover {
        background-color: #555;
      }
    }
  }
`;













// import axios from 'axios'
// import React, { useEffect, useState } from 'react'
// import { setInstructorId, setWorldId, setWorldName } from '../../reducers/reducers'
// import { useDispatch } from 'react-redux'
// import { useNavigate } from 'react-router-dom'

// function SubscribedIn() {
//     const [subscribedIn, setSubscribedIn ] = useState([])
//     const dispatch = useDispatch()
//     const navigate = useNavigate()
//     useEffect(() => {
//         const getSubscribedIn = async() => {
//             const response = await axios.get('https://sell-skill.com/api/endpoints/getSubscribedIn')
//             setSubscribedIn(response.data)
//         }
//         getSubscribedIn()
//     }, [])

//     const handleGetInWorld = (worldName, worldId, instructorId) => {
//         dispatch(setWorldName(worldName));//should be set world id
//         dispatch(setWorldId(worldId));
//         dispatch(setInstructorId(instructorId))
//         navigate('/levels'); //it should be closed unless he pay
//       };
    
//       const handleshowDescription = (worldName, worldId) => {
//         dispatch(setWorldName(worldName));
//         dispatch(setWorldId(worldId))
//         navigate('/show-description');
//       };
//   return (
//     <div>
//          {subscribedIn.map((world, index) => (
//           <div key={index} className="col-md-4 mb-4">
//             <div
//               className="card h-100 text-dark world-card"
//               style={{
//                 border: '2px solid #1E90FF',
//                 borderRadius: '10px',
//                 transition: 'transform 0.3s',
//               }}
//             >
//               <div className="card-body">
//                 <h2 className="card-title text-center" style={{ color: '#1E90FF' }}>{world.name}</h2>
//                 <p className="card-text text-center">{world.description}</p>
//                 {world.worldThumbnail && (
//                   <img
//                     src={world.worldThumbnail}
//                     alt={`${world.name} Thumbnail`}
//                     className="card-img-top mb-3"
//                     style={{ borderRadius: '10px', maxHeight: '200px', objectFit: 'cover' }}
//                   />
//                 )}
//                 <div className="d-flex justify-content-around">
//                   <button
//                     className="btn btn-success"
//                     style={{ backgroundColor: '#32CD32', border: 'none' }}
//                     onClick={() => handleGetInWorld(world.name, world._id, world.instructorId)}
//                   >
//                     go to world
//                   </button>
//                   <button
//                     className="btn btn-warning"
//                     style={{ backgroundColor: '#FFA500', border: 'none' }}
//                     onClick={() => handleshowDescription(world.name, world._id, world.instructorId)}
//                   >
//                     show description
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//     </div>
//   )
// }

// export default SubscribedIn
