import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const RealWorldResults = () => {
  const [title, setTitle] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [elements, setElements] = useState([]);
  const navigate = useNavigate();

  const handleFileUpload = async (file) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `real-world-results/${uuidv4()}_${file.name}`);
      await uploadBytes(fileRef, file);
      return await getDownloadURL(fileRef);
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };

  const addElement = (type) => {
    setElements((prev) => [
      ...prev,
      { id: uuidv4(), type, content: '', order: prev.length + 1 },
    ]);
  };

  const updateElementContent = async (index, fileOrContent) => {
    const updatedElements = [...elements];
    if (updatedElements[index].type === 'text') {
      updatedElements[index].content = fileOrContent;
    } else {
      const url = await handleFileUpload(fileOrContent);
      updatedElements[index].content = url;
    }
    setElements(updatedElements);
  };

  const removeElement = (index) => {
    setElements((prev) =>
      prev.filter((_, i) => i !== index).map((el, i) => ({ ...el, order: i + 1 }))
    );
  };

  const handleThumbnailUpload = async (file) => {
    const url = await handleFileUpload(file);
    setThumbnail(url);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://sell-skill.com/api/endpoints/realWorldResults', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          _id: uuidv4(),
          title,
          thumbnail,
          elements,
        }),
      });
      if (response.ok) {
        console.log('Real World Results uploaded successfully');
        navigate('/picture');
      } else {
        console.error('Error uploading results:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting results:', error);
    }
  };

  return (
    <Container>
      <Header>Real World Results</Header>
      <Content>
        <Input
          type="text"
          placeholder="Enter title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <FileInputContainer>
          <FileLabel htmlFor="thumbnail-upload">Upload Thumbnail</FileLabel>
          <FileInput
            id="thumbnail-upload"
            type="file"
            accept="image/*"
            onChange={(e) => handleThumbnailUpload(e.target.files[0])}
          />
          {thumbnail && <Thumbnail src={thumbnail} alt="Thumbnail preview" />}
        </FileInputContainer>
        <ElementsContainer>

        {elements.map((el, index) => (
  <Element key={el.id}>
    {el.type === 'text' ? (
      <TextArea
        placeholder="Enter text"
        value={el.content}
        onChange={(e) => updateElementContent(index, e.target.value)}
      />
    ) : (
      <>
        <FileLabel htmlFor={`file-upload-${index}`}>Upload {el.type}</FileLabel>
        <FileInput
          id={`file-upload-${index}`}
          type="file"
          accept={
            el.type === 'photo'
              ? 'image/*'
              : el.type === 'video'
              ? 'video/*'
              : '.pdf'
          }
          onChange={(e) => updateElementContent(index, e.target.files[0])}
        />
        {/* Preview the uploaded file */}
        {el.type === 'photo' && el.content && (
          <PreviewImage src={el.content} alt="Uploaded" />
        )}
        {el.type === 'video' && el.content && (
          <VideoPreview controls>
            <source src={el.content} type="video/mp4" />
            Your browser does not support video.
          </VideoPreview>
        )}
        {el.type === 'pdf' && el.content && (
          <PdfPreview>
            <a href={el.content} target="_blank" rel="noopener noreferrer">
              View PDF
            </a>
          </PdfPreview>
        )}
      </>
    )}
    <RemoveButton onClick={() => removeElement(index)}>Remove</RemoveButton>
  </Element>
))}


        </ElementsContainer>
        <ButtonContainer>
          <Button onClick={() => addElement('text')}>Add Text</Button>
          <Button onClick={() => addElement('photo')}>Add Photo</Button>
          <Button onClick={() => addElement('video')}>Add Video</Button>
          <Button onClick={() => addElement('pdf')}>Add PDF</Button>
        </ButtonContainer>
        <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
      </Content>
    </Container>
  );
};

export default RealWorldResults;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f0f4f8;
  height: 100vh;
`;

const Header = styled.h1`
  color: #2c3e50;
  margin-bottom: 20px;
`;

const Content = styled.div`
  width: 100%;
  max-width: 800px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const FileInputContainer = styled.div`
  margin-bottom: 20px;
`;


const FileInput = styled.input`
  display: none;
`;

const Thumbnail = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
`;





const TextArea = styled.textarea`
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
`;

const RemoveButton = styled.button`
  padding: 10px;
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Button = styled.button`
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 10px;
  background: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const PreviewImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
`;

const VideoPreview = styled.video`
  width: 200px;
  height: auto;
  margin-top: 10px;
`;

const PdfPreview = styled.div`
  margin-top: 10px;
  a {
    color: #3498db;
    text-decoration: underline;
    cursor: pointer;
  }
`;
const Element = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Space between elements */
  padding: 10px;
  background-color: #f9f9f9; /* Light background for clarity */
  border: 1px solid #ddd; /* Add borders for visibility */
  border-radius: 4px;
  flex-wrap: wrap; /* Allow wrapping for large content */
`;
const FileLabel = styled.label`
  display: inline-block;
  padding: 10px 20px;
  background: #27ae60;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  margin-right: 10px; /* Space between the label and other content */
  margin-bottom: 10px;
  &:hover {
    background: #218c53; /* Darker green on hover */
  }
`;
const ElementsContainer = styled.div`
  margin-bottom: 20px;
  max-height: 400px; /* Set a max height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fefefe;
`;
