import React from 'react'

function Exam1() {
  return (
    <div>
            map through all the exams, show the name which is the worldName + levelNumber
    </div>
  )
}

export default Exam1
