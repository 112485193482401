import React, { useRef, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setWorldId, setWorldName } from "../../reducers/reducers";
import styled from "styled-components";
import { FaBell, FaCheckCircle, FaComments, FaSearch } from "react-icons/fa";






const FormElement = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  input,
  textarea,
  button {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
  }

  button[type="button"] {
    width: auto;
    background-color: #27ae60;
    color: white;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #1e8449;
    }
  }
`;

function AddWorld() {
  const worldId = uuidv4();
  const [worldName, setWorldNameI] = useState("");
  const [worldDescription, setWorldDescription] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [isWorldAllowingAdvisors, setIsWorldAllowingAdvisors] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [price, setPrice] = useState("");
  const [advisorAmount, setAdvisorAmount] = useState("");
  const [profilePictureSrc, setProfilePictureSrc] = useState('');
  const [category, setCategory] = useState([]);
  const [others, setOthers] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stepsCompleted, setStepsCompleted] = useState({
    worldName: false,
    worldDescription: false,
    thumbnail: false,
    price: false,
    advisorAmount: false,
    categories: false,
  });

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) {
      alert("File size must be less than 5MB.");
      return;
    }
    setThumbnail(file);
    setStepsCompleted({ ...stepsCompleted, thumbnail: true });
  };

  const handleCategoryClick = (cat) => {
    setCategory((prevCategories) =>
      prevCategories.includes(cat)
        ? prevCategories.filter((c) => c !== cat)
        : [...prevCategories, cat]
    );
    setStepsCompleted({ ...stepsCompleted, categories: true });
  };

  const handleAddOther = () => {
    if (others.trim() && !category.includes(others.trim())) {
      setCategory((prevCategories) => [...prevCategories, others.trim()]);
      setOthers("");
      setStepsCompleted({ ...stepsCompleted, categories: true });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("worldId", worldId);
    formData.append("worldName", worldName);
    formData.append("price", price);
    formData.append("advisorAmount", advisorAmount);
    formData.append("worldDescription", worldDescription);
    formData.append("thumbnail", thumbnail);
    formData.append("isWorldAllowingAdvisors", isWorldAllowingAdvisors);
    formData.append("category", JSON.stringify(category));

    try {
      const response = await axios.post(
        "https://sell-skill.com/api/endpoints/addWorld",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (response.status === 200) {
        dispatch(setWorldId(worldId));
        dispatch(setWorldName(worldName));
        navigate("/add-level");
      } else {
        alert("Failed to add the world. Please try again.");
      }
    } catch (error) {
      console.error("Error adding world:", error);
      alert("An error occurred. Please check your inputs and try again.");
    }
  };

  const handleLogout = () => {

  }

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
};

const handleProfileClick = () => {
  setShowDropdown((prev) => !prev);
};
  return (
    <PageContainer>


<Header>
                <Navbar>
                    <LeftNav>
                        <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
                        <NavLinks>
                            <a href="/my-learning">My profile</a>
                            <a href="/my-earning">My uploads</a>
                            <a>withdraw cash</a>
                            <a href="/my-cart">My Cart</a>
                            <a href="/certifications">Certifications</a>
                            <a href="/exams">Exams</a>
                        </NavLinks>
                    </LeftNav>
                    <SearchBar>
                        <FaSearch />
                        <input
                            type="text"
                            placeholder="Search Worlds"
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                    </SearchBar>
                    <NavIcons>
                        <FaBell onClick={() => navigate('/my-notifications')} title="Notifications" />
                        <FaComments onClick={() => navigate('/my-chats')} title="Messages" />
                        <div ref={dropdownRef}>
            <ProfileWrapper onClick={handleProfileClick}>
                <ProfilePicture
                    src={profilePictureSrc || '/images/NormalProfile.jpg'}
                    alt="Profile"
                />
            </ProfileWrapper>
            {showDropdown && (
                <DropdownMenu>
                    <a href="/my-profile">My Profile</a>
                    <a href="/manage-uploads">uploads management</a>
                    <a href="/manage-financial"> financial management</a>
                    <a href = "/skiller-signIn" onClick={()=>handleLogout}>Log Out</a>
                </DropdownMenu>
            )}
        </div>
                    </NavIcons>
                </Navbar>
            </Header>
      


            <Sidebar>
  <h3>Steps</h3>
  {[
    { name: "World Name", key: "worldName" },
    { name: "World Description", key: "worldDescription" },
    { name: "Thumbnail", key: "thumbnail" },
    { name: "Price", key: "price" },
    { name: "Advisor Amount", key: "advisorAmount" },
    { name: "Categories", key: "categories" },
  ].map((step) => (
    <SidebarItem key={step.key}>
      <span>{step.name}</span>
      {stepsCompleted[step.key] && <CompletedMark />}
    </SidebarItem>
  ))}
</Sidebar>

      <MainContent>
        <form onSubmit={handleSubmit}>
          <FormElement>
            <label>World Name:</label>
            <input
              type="text"
              value={worldName}
              onChange={(e) => {
                setWorldNameI(e.target.value);
                setStepsCompleted({ ...stepsCompleted, worldName: e.target.value.length > 0 });
              }}
              required
            />
          </FormElement>
          <FormElement>
            <label>World Description:</label>
            <textarea
              value={worldDescription}
              onChange={(e) => {
                setWorldDescription(e.target.value);
                setStepsCompleted({ ...stepsCompleted, worldDescription: e.target.value.length > 0 });
              }}
              required
            ></textarea>
          </FormElement>
          <FormElement>
  <label>Thumbnail:</label>
  <FileInputContainer>
    <input
      type="file"
      id="thumbnail"
      onChange={handleThumbnailChange}
      accept="image/*"
    />
    <label htmlFor="thumbnail">Upload Thumbnail</label>
  </FileInputContainer>
</FormElement>

<FormElement>
  <label>Categories:</label>
  <div>
    {["Affiliate Marketing", "Digital Marketing", "E-commerce", "Stock Trading"].map(
      (cat) => (
        <CategoryButton
            type="button"
          key={cat}
          selected={category.includes(cat)}
          onClick={() => handleCategoryClick(cat)}
        >
          {cat}
        </CategoryButton>
      )
    )}
  </div>
  <input
    type="text"
    value={others}
    onChange={(e) => setOthers(e.target.value)}
    placeholder="Add other category"
  />
  <button type="button" onClick={handleAddOther}>
    Add Other
  </button>
</FormElement>

<AddWorldButton type="submit">Add World</AddWorldButton>

        </form>
      </MainContent>
    </PageContainer>
  );
}

export default AddWorld;






const PageContainer = styled.div`
    background-color: #f8f9fa;
    min-height: 100vh;
    padding: 60px 20px 20px; /* Add top padding to account for the navbar height */
`;

const Header = styled.div`
    display: flex;       
    justify-content: space-between;
    align-items: center;
`;



const LeftNav = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled.div`
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
`;

const NavLinks = styled.div`
    margin-left: 20px;
    display: flex;
    gap: 15px;

    a {
        color: white;
        text-decoration: none;
    }
`;

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    background-color: #ecf0f1;
    padding: 5px 10px;
    border-radius: 20px;

    input {
        border: none;
        outline: none;
        background: transparent;
        margin-left: 10px;
    }
`;

const NavIcons = styled.div`
    display: flex;
    gap: 15px;
`;

const ProfileWrapper = styled.div`
    position: relative;
`;

const ProfilePicture = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px;
    display: flex;
    flex-direction: column;

    a {
        padding: 10px 15px;
        color: black;
        text-decoration: none;

        &:hover {
            background-color: #f0f0f0;
        }
    }
`;
const Sidebar = styled.div`
  width: 300px;
  position: fixed;
  top: 60px; /* Aligns directly below the navbar */
  left: 0;
  bottom: 0; /* Full height */
  background-color: #f4f4f4;
  padding: 20px;
  border-right: 1px solid #ddd;
  overflow-y: auto; /* Make content scrollable */
  z-index: 900; /* Below navbar */
`;


const MainContent = styled.div`
  margin-left: 300px; /* Space for sidebar */
  margin-top: 0; /* No additional margin */
  flex-grow: 1;
  padding: 20px;
  width: calc(100% - 300px); /* Full width minus sidebar */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Ensure children stretch horizontally */
`;


const Navbar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #2c3e50;
    color: white;
    position: fixed; /* Fixed at the top */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    z-index: 1000; /* Above all other elements */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
`;












const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 1.1em;
  cursor: default; /* No pointer effect */
  padding: 10px;
  border-radius: 5px;
  background-color: transparent; /* No color change */
  color: inherit; /* Maintain default color */
`;

const CompletedMark = styled(FaCheckCircle)`
  margin-left: 10px;
  font-size: 1.2em;
  color: #27ae60; /* Green for the check mark */
`;
const FileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;

  input {
    display: none;
  }

  label {
    background-color: #27ae60;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #1e8449;
    }
  }
`;

const CategoryButton = styled.button`
  margin: 5px;
  padding: 10px 15px;
  background-color: ${(props) => (props.selected ? "#27ae60" : "#ecf0f1")};
  color: ${(props) => (props.selected ? "white" : "#2c3e50")};
  border: 1px solid ${(props) => (props.selected ? "#27ae60" : "#bdc3c7")};
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.selected ? "#1e8449" : "#d5d8dc")};
    color: ${(props) => (props.selected ? "white" : "#2c3e50")};
  }
`;

const AddWorldButton = styled.button`
  width: 100%;
  padding: 15px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1a242f;
  }
`;

