import React from 'react'

function Exam2() {
  return (
    <div>
           useSelctor get the exam id and show all related info
    </div>
  )
}

export default Exam2
