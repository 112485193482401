import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const WUploadPost = () => {
  const [title, setTitle] = useState('');
  const worldId = useSelector((state) => state.allow.worldId);
  const [thumbnail, setThumbnail] = useState('');
  const [attachments, setAttachments] = useState({
    photos: [],
    videos: [],
    pdfs: [],
    texts: [],
    quizs: [],
  });
  const navigate = useNavigate();

  const handleFileUpload = async (file, type) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, `uploads/${uuidv4()}_${file.name}`);
      await uploadBytes(fileRef, file);
      const url = await getDownloadURL(fileRef);
      return url;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };

  const addAttachment = async (type, fileOrContent, additionalData = {}) => {
    let url = fileOrContent;
        
    if (type !== 'texts' && type !== 'quizs') {
      url = await handleFileUpload(fileOrContent, type);
      if (!url) return;
    }

    setAttachments((prev) => ({
      ...prev,
      [type]: [
        ...prev[type],
        {
          ...additionalData,
          ...(type === 'texts' || type === 'quizs'
            ? { content: fileOrContent }
            : { contentUrl: url }),
          order: prev[type].length + 1,
        },
      ],
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://sell-skill.com/api/endpoints/WaddPost', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          _id: uuidv4(),
          worldId,
          title,
          thumbnail,
          attachments,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Post uploaded successfully:', result);
        navigate('/levels');
      } else {
        console.error('Error uploading post:', response.statusText);
      }
    } catch (error) {
      console.error('Error submitting post:', error);
    }
  };

  return (
    <PageContainer>
      <Header>
        <Logo onClick={() => navigate('/worlds')}>Sell-Skill</Logo>
        <h2>Upload Post</h2>
      </Header>
      <ContentContainer>
        <FormContainer>
          <Input
            type="text"
            placeholder="Enter post title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Input
            type="file"
            accept="image/*"
            onChange={async (e) => {
              const url = await handleFileUpload(e.target.files[0], 'thumbnail');
              setThumbnail(url);
            }}
          />
          <AttachmentSection>
            <h3>Add Attachments</h3>
            <Input
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => {
                Array.from(e.target.files).forEach((file) => addAttachment('photos', file));
              }}
            />
            <Input
              type="file"
              accept="video/*"
              multiple
              onChange={(e) => {
                Array.from(e.target.files).forEach((file) => addAttachment('videos', file));
              }}
            />
            <Input
              type="file"
              accept="application/pdf"
              multiple
              onChange={(e) => {
                Array.from(e.target.files).forEach((file) => addAttachment('pdfs', file));
              }}
            />
            <Textarea
              placeholder="Add text content"
              onBlur={(e) => addAttachment('texts', e.target.value)}
            />
            <QuizContainer>
              <input
                type="text"
                placeholder="Quiz question"
                onBlur={(e) => addAttachment('quizs', e.target.value, { question: e.target.value })}
              />
            </QuizContainer>
          </AttachmentSection>
          <Button onClick={handleSubmit}>Submit Post</Button>
        </FormContainer>
      </ContentContainer>
    </PageContainer>
  );
};

export default WUploadPost;

// Styled Components
const PageContainer = styled.div`
  padding: 20px;
  background: #f8f9fa;
  min-height: 100vh;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Logo = styled.h1`
  color: #2c3e50;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  max-width: 800px;
  margin: 20px auto;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Textarea = styled.textarea`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
`;

const AttachmentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;